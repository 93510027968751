export const sortByOptions = [
  { value: 'Best Match', label: 'Best Match' },
  { value: 'Best Reviews', label: 'Best Reviews' },
  { value: 'Distance', label: 'Distance' },
  { value: 'Alphabetically A-Z', label: 'Alphabetically A-Z' },
  { value: 'Alphabetically Z-A', label: 'Alphabetically Z-A' }
];

export const sortByOptionsLocation = [
  { value: 'Proximity', label: 'Proximity to you' },
  { value: 'Alphabetically A-Z', label: 'Alphabetically A-Z' },
  { value: 'Alphabetically Z-A', label: 'Alphabetically Z-A' }
];

export const insuranceCarrierOptions = [
  {
    value: 'ASR Physicians Care',
    label: 'ASR Physicians Care'
  },
  {
    value: 'Aetna Medicare',
    label: 'Aetna Medicare'
  },
  {
    value: 'Aetna Medicare Advantage',
    label: 'Aetna Medicare Advantage'
  },
  {
    value: 'Aetna/Cofinity',
    label: 'Aetna/Cofinity'
  },
  {
    value: 'Apostrophe',
    label: 'Apostrophe'
  },
  {
    value: 'Beacon Health Options',
    label: 'Beacon Health Options'
  },
  {
    value: 'Blue Care Network',
    label: 'Blue Care Network'
  },
  {
    value: 'Blue Care Network Medicare Advantage',
    label: 'Blue Care Network Medicare Advantage'
  },
  {
    value: 'Blue Cross Blue Shield Medicare Advantage Plus Blue',
    label: 'Blue Cross Blue Shield Medicare Advantage Plus Blue'
  },
  {
    value: 'Blue Cross Blue Shield Mental Health and Substance Abuse Management',
    label: 'Blue Cross Blue Shield Mental Health and Substance Abuse Management'
  },
  {
    value: 'Blue Cross Blue Shield PPO',
    label: 'Blue Cross Blue Shield PPO'
  },
  {
    value: 'Blue Cross Blue Shield Traditional',
    label: 'Blue Cross Blue Shield Traditional'
  },
  {
    value: 'Blue Cross Blue Shield Vision and Hearing',
    label: 'Blue Cross Blue Shield Vision and Hearing'
  },
  {
    value: 'Cigna',
    label: 'Cigna'
  },
  {
    value: 'Cigna Great West',
    label: 'Cigna Great West'
  },
  {
    value: 'Comprehensive Health Services',
    label: 'Comprehensive Health Services'
  },
  {
    value: 'Computer Health Services',
    label: 'Computer Health Services'
  },
  {
    value: 'Coventry First Health',
    label: 'Coventry First Health'
  },
  {
    value: 'Delta Dental of Michigan',
    label: 'Delta Dental of Michigan'
  },
  {
    value: 'Department of Labor',
    label: 'Department of Labor'
  },
  {
    value: 'EyeMed',
    label: 'EyeMed'
  },
  {
    value: 'Humana Medicare',
    label: 'Humana Medicare'
  },
  {
    value: 'Magellan',
    label: 'Magellan'
  },
  {
    value: 'McLaren Commercial',
    label: 'McLaren Commercial'
  },
  {
    value: 'McLaren Medicaid',
    label: 'McLaren Medicaid'
  },
  {
    value: 'McLaren Medicare',
    label: 'McLaren Medicare'
  },
  {
    value: 'McLaren Medicare Advantage',
    label: 'McLaren Medicare Advantage'
  },
  {
    value: 'Medicaid',
    label: 'Medicaid'
  },
  {
    value: 'Medicare',
    label: 'Medicare'
  },
  {
    value: 'Meridian AmBetter',
    label: 'Meridian AmBetter'
  },
  {
    value: 'Meridian Choice Commercial',
    label: 'Meridian Choice Commercial'
  },
  {
    value: 'Meridian Complete (MI Health LINK) Dual Eligible',
    label: 'Meridian Complete (MI Health LINK) Dual Eligible'
  },
  {
    value: 'Meridian Medicaid',
    label: 'Meridian Medicaid'
  },
  {
    value: 'Molina Medicaid/MiChild (Health Plus)',
    label: 'Molina Medicaid/MiChild (Health Plus)'
  },
  {
    value: 'Molina Medicaid/MiChild/Marketplace (Health Plus)',
    label: 'Molina Medicaid/MiChild/Marketplace (Health Plus)'
  },
  {
    value: 'Molina Medicare',
    label: 'Molina Medicare'
  },
  {
    value: 'Montana Medicaid',
    label: 'Montana Medicaid'
  },
  {
    value: 'MultiPlan',
    label: 'MultiPlan'
  },
  {
    value: 'PNOA/HMA',
    label: 'PNOA/HMA'
  },
  {
    value: 'Priority Health HMO/PPO',
    label: 'Priority Health HMO/PPO'
  },
  {
    value: 'Priority Health Medicaid/MiChild',
    label: 'Priority Health Medicaid/MiChild'
  },
  {
    value: 'Priority Health Medicare',
    label: 'Priority Health Medicare'
  },
  {
    value: 'Professional Medical Corporation (PMC)',
    label: 'Professional Medical Corporation (PMC)'
  },
  {
    value: 'QTC',
    label: 'QTC'
  },
  {
    value: 'Railroad Medicare',
    label: 'Railroad Medicare'
  },
  {
    value: 'Regency Employee Benefits',
    label: 'Regency Employee Benefits'
  },
  {
    value: 'Sisco (Community Network Advantage)',
    label: 'Sisco (Community Network Advantage)'
  },
  {
    value: 'TriWest Veteran Affairs Community Care Program',
    label: 'TriWest Veteran Affairs Community Care Program'
  },
  {
    value: 'Tricare Prime',
    label: 'Tricare Prime'
  },
  {
    value: 'Tricare Select',
    label: 'Tricare Select'
  },
  {
    value: 'Tricare Select Certified Only',
    label: 'Tricare Select Certified Only'
  },
  {
    value: 'Tricare Standard',
    label: 'Tricare Standard'
  },
  {
    value: 'UHC/Optum (VA Community Care Network)',
    label: 'UHC/Optum (VA Community Care Network)'
  },
  {
    value: 'United Health Care Commercial',
    label: 'United Health Care Commercial'
  },
  {
    value: 'United Health Care Commercial (Golden Rule)',
    label: 'United Health Care Commercial (Golden Rule)'
  },
  {
    value: 'United Health Care Community Plan (Medicaid)',
    label: 'United Health Care Community Plan (Medicaid)'
  },
  {
    value: 'United HealthCare Dual Complete',
    label: 'United HealthCare Dual Complete'
  },
  {
    value: 'VSP',
    label: 'VSP'
  },
  {
    value: 'Veteran Affairs Community Care Program',
    label: 'Veteran Affairs Community Care Program'
  },
  {
    value: "Veteran's Choice",
    label: "Veteran's Choice"
  },
  {
    value: 'Wellnet',
    label: 'Wellnet'
  }
];

export const genderOptions = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' }
];

export const languageOptions = [
  { value: 'Akan', label: 'Akan' },
  { value: 'Amharic', label: 'Amharic' },
  { value: 'Arabi - North Levantine Spoken', label: 'Arabi - North Levantine Spoken' },
  { value: 'Arabic - Mesopotamian Spoken', label: 'Arabic - Mesopotamian Spoken' },
  { value: 'Arabic Algerian Spoken', label: 'Arabic Algerian Spoken' },
  { value: 'Arabic Egyptian Spoken', label: 'Arabic Egyptian Spoken' },
  { value: 'Arabic Moroccan Spoken', label: 'Arabic Moroccan Spoken' },
  { value: 'Arabic Najdi Spoken', label: 'Arabic Najdi Spoken' },
  { value: 'Arabic Saidi Spoken', label: 'Arabic Saidi Spoken' },
  { value: 'Arabic Sanaani Spoken', label: 'Arabic Sanaani Spoken' },
  { value: 'Arabic Sudanese Spoken', label: 'Arabic Sudanese Spoken' },
  { value: 'Arabic Tunisian Spoken', label: 'Arabic Tunisian Spoken' },
  { value: 'Assamese', label: 'Assamese' },
  { value: 'Awadhi', label: 'Awadhi' },
  { value: 'Azerbaijani North', label: 'Azerbaijani North' },
  { value: 'Azerbaijani, South', label: 'Azerbaijani, South' },
  { value: 'Belarusan', label: 'Belarusan' },
  { value: 'Bengali', label: 'Bengali' },
  { value: 'Bhojpuri', label: 'Bhojpuri' },
  { value: 'Bulgarian', label: 'Bulgarian' },
  { value: 'Burmese', label: 'Burmese' },
  { value: 'Cebuano', label: 'Cebuano' },
  { value: 'Chhattisgarhi', label: 'Chhattisgarhi' },
  { value: 'Chinese - Gan', label: 'Chinese - Gan' },
  { value: 'Chinese - Mandarin', label: 'Chinese - Mandarin' },
  { value: 'Chinese - Min Bei', label: 'Chinese - Min Bei' },
  { value: 'Chinese - Wu', label: 'Chinese - Wu' },
  { value: 'Chinese - Yue', label: 'Chinese - Yue' },
  { value: 'Chinese Hakka', label: 'Chinese Hakka' },
  { value: 'Chinese Jinyu', label: 'Chinese Jinyu' },
  { value: 'Chinese Min Nan', label: 'Chinese Min Nan' },
  { value: 'Chinese Xiang', label: 'Chinese Xiang' },
  { value: 'Chittagonian', label: 'Chittagonian' },
  { value: 'Czech', label: 'Czech' },
  { value: 'Deccan', label: 'Deccan' },
  { value: 'Dutch', label: 'Dutch' },
  { value: 'English', label: 'English' },
  { value: 'Farsi Eastern', label: 'Farsi Eastern' },
  { value: 'Farsi Western', label: 'Farsi Western' },
  { value: 'French', label: 'French' },
  { value: 'Fulfulde Nigerian', label: 'Fulfulde Nigerian' },
  { value: 'German', label: 'German' },
  { value: 'Greek', label: 'Greek' },
  { value: 'Gujarati', label: 'Gujarati' },
  { value: 'Haitian Creole French', label: 'Haitian Creole French' },
  { value: 'Haryanvi', label: 'Haryanvi' },
  { value: 'Hausa', label: 'Hausa' },
  { value: 'Hebrew', label: 'Hebrew' },
  { value: 'Hiligaynon', label: 'Hiligaynon' },
  { value: 'Hindi', label: 'Hindi' },
  { value: 'Hungarian', label: 'Hungarian' },
  { value: 'Igbo', label: 'Igbo' },
  { value: 'Ilocano', label: 'Ilocano' },
  { value: 'Indonesian', label: 'Indonesian' },
  { value: 'Italian', label: 'Italian' },
  { value: 'Japanese', label: 'Japanese' },
  { value: 'Javanese', label: 'Javanese' },
  { value: 'Kannada', label: 'Kannada' },
  { value: 'Kazakh', label: 'Kazakh' },
  { value: 'Khmer Central', label: 'Khmer Central' },
  { value: 'Korean', label: 'Korean' },
  { value: 'Kurmanji', label: 'Kurmanji' },
  { value: 'Lombard', label: 'Lombard' },
  { value: 'Madura', label: 'Madura' },
  { value: 'Magahi', label: 'Magahi' },
  { value: 'Maithili', label: 'Maithili' },
  { value: 'Malagasy', label: 'Malagasy' },
  { value: 'Malay', label: 'Malay' },
  { value: 'Malayalam', label: 'Malayalam' },
  { value: 'Marathi', label: 'Marathi' },
  { value: 'Marwari', label: 'Marwari' },
  { value: 'Napoletano Calabrese', label: 'Napoletano Calabrese' },
  { value: 'Nepali', label: 'Nepali' },
  { value: 'Oriya', label: 'Oriya' },
  { value: 'Oromo West Central', label: 'Oromo West Central' },
  { value: 'Panjabi Eastern', label: 'Panjabi Eastern' },
  { value: 'Panjabi Western', label: 'Panjabi Western' },
  { value: 'Pashto Northern', label: 'Pashto Northern' },
  { value: 'Pashto Southern', label: 'Pashto Southern' },
  { value: 'Polish', label: 'Polish' },
  { value: 'Portuguese', label: 'Portuguese' },
  { value: 'Romanian', label: 'Romanian' },
  { value: 'Russian', label: 'Russian' },
  { value: 'Rwanda', label: 'Rwanda' },
  { value: 'Saraiki', label: 'Saraiki' },
  { value: 'Serbo Croatian', label: 'Serbo Croatian' },
  { value: 'Shona', label: 'Shona' },
  { value: 'Sindhi', label: 'Sindhi' },
  { value: 'Sinhala', label: 'Sinhala' },
  { value: 'Somali', label: 'Somali' },
  { value: 'Spanish', label: 'Spanish' },
  { value: 'Sunda', label: 'Sunda' },
  { value: 'Swedish', label: 'Swedish' },
  { value: 'Tagalog', label: 'Tagalog' },
  { value: 'Tamil', label: 'Tamil' },
  { value: 'Tatar', label: 'Tatar' },
  { value: 'Telugu', label: 'Telugu' },
  { value: 'Thai', label: 'Thai' },
  { value: 'Thai Northeastern', label: 'Thai Northeastern' },
  { value: 'Turkish', label: 'Turkish' },
  { value: 'Ukrainian', label: 'Ukrainian' },
  { value: 'Urdu', label: 'Urdu' },
  { value: 'Uyghur', label: 'Uyghur' },
  { value: 'Uzbek Northern', label: 'Uzbek Northern' },
  { value: 'Vietnamese', label: 'Vietnamese' },
  { value: 'Yoruba', label: 'Yoruba' },
  { value: 'Zhuang Northern', label: 'Zhuang Northern' },
  { value: 'Zulu', label: 'Zulu' }
];
