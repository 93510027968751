import React, { useState } from "react";

import { ButtonContent, ContentCardGridContent, IconPack } from "../../models/app-data-model";
import ButtonWrapper from "../button-wrapper/button-wrapper";
import CardGridContainerMobile from "../card-grid-container-mobile/card-grid-container-mobile";
import ContentCard from "../content-card/content-card";

export type ContentCardGridMobileProps = {
  content: ContentCardGridContent;
  tertiaryButton?: ButtonContent;
  hideTertiaryButton?: boolean;
  corewellStyleOverrides?: boolean;
  iconPack: IconPack[];
};

const ContentCardGridMobile: React.FC<ContentCardGridMobileProps> = ({
  content,
  corewellStyleOverrides,
  iconPack
}: ContentCardGridMobileProps) => {
  const [isShowAllToggled, toggleShowAll] = useState(false);
  const fullCardList = content.cards;
  const reducedCardList = content.cards.slice(0, 3);

  const reducedCardListRenderings = reducedCardList.map((card, index) => (
    <ContentCard
      includeImagePadding={content?.include_image_padding}
      cardWidth={1}
      key={index}
      fadedStyles={index === 3}
      content={card}
      cardImage={card.content_card.image_landscape}
      corewellStyleOverrides={corewellStyleOverrides}
      iconPack={iconPack}
    />
  ));

  const fullCardListRenderings = fullCardList.map((card, index) => (
    <ContentCard
      includeImagePadding={content?.include_image_padding}
      cardWidth={1}
      key={index}
      content={card}
      cardImage={card.content_card.image_landscape}
      corewellStyleOverrides={corewellStyleOverrides}
      iconPack={iconPack}
    />
  ));

  return (
    <CardGridContainerMobile iconPack={iconPack} tertiaryButton={content?.tertiary_button} showTertiaryButton={isShowAllToggled}>
      {!isShowAllToggled && fullCardList.length >= 3 ? reducedCardListRenderings : fullCardListRenderings}
      {fullCardList.length > 3 && !isShowAllToggled && (
        <span className="grid-bottom-button">
          <ButtonWrapper
            type="tertiary"
            label={"Show All"}
            styleOverride="transparent-bg"
            isShowMore={true}
            onClick={() => toggleShowAll(true)}
            iconPack={iconPack}
          ></ButtonWrapper>
        </span>
      )}
    </CardGridContainerMobile>
  );
};

export default ContentCardGridMobile;
