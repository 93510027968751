import { useState, useEffect, SetStateAction, Dispatch } from 'react';

import { PaginationDetails } from '../../models/app-data-model';

function usePagination<T>(data: Array<T>, limitPerPage = 10): [Array<T>, PaginationDetails, Dispatch<SetStateAction<number>>] {
  const [currentPageData, setCurrentPageData] = useState(Array<T>());
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(limitPerPage);

  useEffect(() => {
    const allData = [...data];
    const totalResult = allData.length;

    setTotalPages(Math.ceil(totalResult / pageSize));

    const startIndex = currentPage * pageSize - pageSize;
    const itemsPerPage = currentPage === totalPages ? totalResult - (currentPage - 1) * pageSize : pageSize;
    const endIndex = startIndex + itemsPerPage;

    setCurrentPageData(allData.slice(startIndex, endIndex));
  }, [data, currentPage, totalPages, pageSize]);

  // After pressing the next or previous button, focuses the dom on the first result card
  if (typeof document !== 'undefined' && document.activeElement?.innerHTML.includes('tertiary')) {
    setTimeout(() => {
      const firstResultCard = document.getElementById('results-card-0');
      firstResultCard?.focus();
      firstResultCard?.blur(); // keeps the tab order intact, but doesnt change the visual display of the card
    }, 0);
  }

  return [currentPageData, { totalPages: totalPages, currentPage: currentPage }, setCurrentPage];
}

export default usePagination;
