import React from 'react';

import { AppDataModel, SubsiteContent, EmbedScriptBlock, RegionalLinksModalContent } from '../../../shared/models/app-data-model';

export type GlobalAppData = {
  appData: AppDataModel;
  setAppData?: (appData: AppDataModel) => void;
  subsiteData?: SubsiteContent;
  setSubsiteData?: (subsiteData?: SubsiteContent) => void;
  embeddedScripts: EmbedScriptBlock[];
  setEmbeddedScripts: (embeddedScripts: EmbedScriptBlock[]) => void;
  regionalLinksModalData: RegionalLinksModalContent;
  setRegionalLinksModalData: (modalData: RegionalLinksModalContent) => void;
};

// instead of just using our AppDataModel as our full data context...
// let's wrap it in an object so it's flexible if we need to add things outside of our AppDataModel for application state
// eslint-disable-next-line max-len
const appData = {} as AppDataModel;
const AppDataContext = React.createContext({ appData: appData } as GlobalAppData);

export { AppDataContext };
