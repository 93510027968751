import { Link } from "gatsby";
import React, { FocusEventHandler, MouseEventHandler, ReactNode, useContext, useEffect, useState } from "react";

import RegionalLinksModal from "../../../regional-links-modal/regional-links-modal";
import { Modal, ModalContent, ModalTrigger } from "../../components/modal/modal";
import { AppDataContext } from "../../contexts/app-data/AppDataContext";
import { UiStateContext } from "../../contexts/ui-state/UiStateContext";
import useReferenceUrl, { SimpleReference, RegionalLinkReference } from "../../hooks/reference-url/useReferenceUrl";
import useFeatureToggle from "../../hooks/use-feature-toggle/useFeatureToggle";
import { FeatureToggle, IconPack, ReferenceLinkContent } from "../../models/app-data-model";
import "./sh-link.scss";

export type ShLinkProps = {
  children?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onMouseOver?: MouseEventHandler<HTMLElement>;
  onMouseOut?: MouseEventHandler<HTMLElement>;
  onFocus?: FocusEventHandler<HTMLAnchorElement>;
  onBlur?: FocusEventHandler<HTMLAnchorElement>;
  reference?: ReferenceLinkContent[];
  title?: string;
  className?: string;
  dataTestId?: string;
  iconPack?: IconPack[];
  featureToggles: FeatureToggle[];
  regionalLinkAsButton?: boolean;
  closeMenus?: boolean;
  nonFocusable?: boolean;
};

/**
 * The ShLink component, which handles whether to render a <a> tag or a Gatsbyjs <Link>
 * @param param0 the ShLinkProps
 * @returns A component which renders either a <a> tag, or <Link>, depending on if the link is internal or external;
 *  if the type can't be determined, it will render nothing.
 */
const ShLink: React.FC<ShLinkProps> = ({
  title,
  children,
  className,
  reference,
  onClick,
  onMouseOver,
  onMouseOut,
  onFocus,
  nonFocusable,
  onBlur,
  dataTestId,
  closeMenus,
  iconPack,
  featureToggles,
  regionalLinkAsButton
}: ShLinkProps) => {
  const uiStateContext = useContext(UiStateContext);
  const appDataContext = useContext(AppDataContext);
  const regionalLinksEnabled = useFeatureToggle("regional-link-modal", featureToggles);

  if (!regionalLinksEnabled) {
    reference = reference?.find((x) => x.default_link?.length)?.default_link ?? reference;
  }

  const ref = useReferenceUrl(reference);

  const [allowHosts, setAllowHosts] = useState<string>();

  const onClickLink = (
    // if we have an onClick, we dont' need an href/to, and can just use a button element
    <button title={title} onClick={onClick} tabIndex={nonFocusable ? -1 : 0} className={`unstyled-btn ${className}`} data-testid={dataTestId}>
      {children}
      {title}
    </button>
  );

  const closeMenusOnClick = () => {
    uiStateContext.setMegaMenuOpen?.(false);
    uiStateContext.setMenuOpen?.(false);

    setTimeout(() => {
      window.scrollTo({
        top: uiStateContext.scrollPosition,
        behavior: "smooth"
      });
    }, 1);
  };

  // remove tel:, periods, then add whitespace between numbers
  const parsePhoneForAriaLabel = (url: string): string => {
    let newurl = url.replace("tel:", "");
    newurl = newurl.replace(".", "");
    return newurl.split("").join(" ");
  };

  useEffect(() => {
    setAllowHosts(uiStateContext.allowHosts?.join(","));
  }, [uiStateContext.allowHosts]);

  const isRegionalLinkDataPresent =
    ref?.type === "sh_regional_link_component" &&
    ref.west_region_link?.length &&
    ref.southeast_region_link?.length &&
    ref.southwest_region_link?.length;

  const internalLink = ref?.type === "sh_pages" && (
    <Link
      title={title}
      className={className}
      to={(ref as SimpleReference)?.href || ""}
      aria-label={(ref as SimpleReference)?.href.includes("tel:") ? parsePhoneForAriaLabel((ref as SimpleReference)?.href) : undefined}
      activeClassName="active"
      data-allowhosts={allowHosts || undefined}
      data-testid={dataTestId}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onFocus={onFocus}
      onBlur={onBlur}
      tabIndex={nonFocusable ? -1 : 0}
      onClick={closeMenus ? () => closeMenusOnClick() : undefined}
    >
      {children}
      {title}
    </Link>
  );

  const externalLink = ref?.type === "sh_external_link" && (
    <a
      title={title}
      className={className}
      href={(ref as SimpleReference)?.href || ""}
      aria-label={(ref as SimpleReference)?.href.includes("tel:") ? parsePhoneForAriaLabel((ref as SimpleReference)?.href) : undefined}
      target={(ref as SimpleReference)?.href.includes("https:") ? "_blank" : undefined}
      rel="noopener noreferrer"
      data-allowhosts={allowHosts || undefined}
      data-testid={dataTestId}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onFocus={onFocus}
      tabIndex={nonFocusable ? -1 : 0}
      onBlur={onBlur}
    >
      {children}
      {title}
    </a>
  );

  const regionalLink = regionalLinksEnabled && isRegionalLinkDataPresent && (
    <Modal>
      <ModalTrigger asChild={true}>
        {regionalLinkAsButton ? (
          <button tabIndex={nonFocusable ? -1 : 0} className={`unstyled-btn ${className}`} title={title}>
            {children}
            {title}
          </button>
        ) : (
          <a tabIndex={nonFocusable ? -1 : 0} href="#regions-modal" className={className} title={title}>
            {children}
            {title}
          </a>
        )}
      </ModalTrigger>
      <ModalContent iconPack={iconPack as IconPack[]} headerText={appDataContext.regionalLinksModalData?.instruction_text?.mobile}>
        <RegionalLinksModal
          iconPack={iconPack as IconPack[]}
          content={appDataContext.regionalLinksModalData}
          regionalLinks={ref as RegionalLinkReference}
        />
      </ModalContent>
    </Modal>
  );

  // render onClick override if onClick is provided (always), else use internal link or external link based on ref.type
  return (
    <>
      {onClick && onClickLink}
      {!onClick && ref?.type === "sh_pages" && internalLink}
      {!onClick && ref?.type === "sh_external_link" && externalLink}
      {!onClick && ref?.type === "sh_regional_link_component" && regionalLink}
    </>
  );
};
export default ShLink;
