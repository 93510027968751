/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react";

import AppDataContextProvider from "../design-system/shared/contexts/app-data/AppDataContextProvider";
import UiStateContextProvider from "../design-system/shared/contexts/ui-state/UiStateContextProvider";
import { MediaContextProvider } from "../design-system/shared/contexts/media/MediaContext";

require("css.escape");

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
  }
};

export const wrapRootElement = ({ element }) => (
  <UiStateContextProvider>
    <AppDataContextProvider>
      <MediaContextProvider>
        {element}
      </MediaContextProvider>
    </AppDataContextProvider>
  </UiStateContextProvider>
);
