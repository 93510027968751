import { useState, useEffect } from 'react';

import useWindowSize from '../window-size/useWindowSize';

/**
 * Hook that will tell you easily if you are likely on a mobile device or not
 * Does not factor in things like OS, merely checks the window width.
 */

// TODO: Refactor this hook to be useBreakpoint instead of useMobile. Return an enumerator which could equal
// Mobile | Tablet | Desktop | etc... based of of the devices width.

function useIsMobile(): boolean {
  const windowSize = useWindowSize();

  const [isMobile, setIsMobile] = useState(true);
  useEffect(() => {
    if (windowSize?.width) {
      setIsMobile(windowSize?.width <= 640);
    }
  }, [windowSize]);

  return isMobile;
}

export default useIsMobile;
