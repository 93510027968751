import React, { useContext } from 'react';

import { UiStateContext } from '../../shared/contexts/ui-state/UiStateContext';
import './suggested-search.scss';

export type SuggestedSearchProps = {
  searchSuggestions?: Array<string>;
};

const SuggestedSearch: React.FC<SuggestedSearchProps> = ({ searchSuggestions }: SuggestedSearchProps) => {
  const uiStateContext = useContext(UiStateContext);

  const performSearch = (searchTerm: string): void => {
    /* istanbul ignore if */
    if (uiStateContext && uiStateContext.setSearchQuery) {
      uiStateContext.setSearchQuery(searchTerm);
    }
  };

  return (
    <div className="suggested-search">
      <span className="suggested-search-title">Suggested Searches</span>
      {searchSuggestions?.map((item, index) => {
        return (
          <div className="search-pill" key={index}>
            <span onClick={() => performSearch(item)} className="search-term">
              {item}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default SuggestedSearch;
