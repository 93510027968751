import React from 'react';
import { Flex } from 'rebass/styled-components';

import ShIcon from '../shared/components/sh-icon/sh-icon';
import ShLink from '../shared/components/sh-link/sh-link';
import { Media } from '../shared/contexts/media/MediaContext';
import { RegionalLinkReference } from '../shared/hooks/reference-url/useReferenceUrl';
import { IconPack, ReferenceLinkContent, RegionCtaLabel, RegionalLinksModalContent } from '../shared/models/app-data-model';
import { addTitleToInlineSvg } from '../shared/services/string-manipulation/string-manipulation-service';
import './regional-links-modal.scss';

export type RegionalLinksModalProps = {
  content: RegionalLinksModalContent;
  regionalLinks: RegionalLinkReference;
  iconPack: IconPack[];
};

const RegionalLinksModal: React.FC<RegionalLinksModalProps> = ({ content, regionalLinks, iconPack }: RegionalLinksModalProps) => {
  const svgData = addTitleToInlineSvg(content.modal_svg.svg_data, content.modal_svg.svg_alt_text);

  const svgImage = svgData ? (
    <div id="regional-links-map" data-testid="inline-svg-map" className="svg-image" dangerouslySetInnerHTML={{ __html: svgData }}></div>
  ) : (
    <div id="regional-links-map" className="svg-image" data-testid="img-svg-map">
      <img src={content.modal_svg.svg_image.url} alt={content.modal_svg.svg_alt_text} />
    </div>
  );

  const toggleMapOverlay = (action: 'show' | 'hide', elementIds: string[]) => {
    elementIds.forEach((el) => {
      const overlayElement = document?.getElementById(el);

      if (action === 'show') overlayElement?.classList.remove('hidden');
      else overlayElement?.classList.add('hidden');
    });
  };

  const renderLink = (ref: ReferenceLinkContent[], region: string, label: RegionCtaLabel, bubbleIds: string[]): JSX.Element => {
    return (
      <ShLink
        reference={ref}
        className={`regional-link ${region}`}
        dataTestId={`${region}-link`}
        onMouseOver={() => toggleMapOverlay('show', bubbleIds)}
        onMouseOut={() => toggleMapOverlay('hide', bubbleIds)}
        onFocus={() => toggleMapOverlay('show', bubbleIds)}
        onBlur={() => toggleMapOverlay('hide', bubbleIds)}
      >
        <div className="regional-link-text">
          <div>{label.first_line}</div>
          <div>{label.second_line}</div>
        </div>
        <ShIcon iconPack={iconPack} iconName={'arrow'} className="regional-link-icon" />
      </ShLink>
    );
  };

  return (
    <Flex className="regional-links-modal">
      <div className="left-container">
        <Media lessThan="tablet" className="action-text">
          <div>{content.action_text}</div>
          <div className="links-title">{regionalLinks.title}</div>
        </Media>
        {svgImage}
      </div>
      <div className="right-container">
        <Media greaterThanOrEqual="tablet">
          <div className="instruction-text">{content.instruction_text.desktop}</div>
          <div className="action-text">
            {content.action_text} <span className="links-title">{regionalLinks.title}</span>
          </div>
        </Media>
        <div className="regional-link-list">         
          {renderLink(regionalLinks.southeast_region_link, 'southeast', content.region_cta_labels.southeast, ['southeast-bubble'])}
          {renderLink(regionalLinks.southwest_region_link, 'southwest', content.region_cta_labels.southwest, ['southwest-bubble'])}
          {renderLink(regionalLinks.west_region_link, 'west', content.region_cta_labels.west, ['west-bubble-1', 'west-bubble-2'])}
        </div>
      </div>
    </Flex>
  );
};

export default RegionalLinksModal;
