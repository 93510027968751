import React, { useContext } from 'react';
import { Box } from 'rebass/styled-components';

import { inputBoxWidth } from '../../../constants/constants';
import Checkbox from '../../../shared/components/checkbox/checkbox';
import SelectInputContainer from '../../../shared/components/select-input-container/select-input-container';
import TextInputContainer from '../../../shared/components/text-input-container/text-input-container';
import { UiStateContext } from '../../../shared/contexts/ui-state/UiStateContext';
import { SearchLocationFilters, SearchLocationSort } from '../../../shared/hooks/search-locations-sort/useSortLocationResults';
import { DeviceType } from '../../../shared/hooks/use-breakpoint/device-type';
import useBreakpoint from '../../../shared/hooks/use-breakpoint/useBreakpoint';
import { ZipDistance } from '../../../shared/hooks/zip-distance/useZipDistance';
import { sortByOptionsLocation } from '../../constants/constants';
import './location-filter.scss';

export type LocationFilterProps = {
  onFilterSelectChange: (searchFilter?: SearchLocationFilters) => void;
  onSortSelectChange: (searchSort?: SearchLocationSort) => void;
  onZipSelectChange: (searchZip?: ZipDistance) => void;
};

const LocationFilter: React.FC<LocationFilterProps> = ({
  onFilterSelectChange,
  onSortSelectChange,
  onZipSelectChange
}: LocationFilterProps) => {
  const uiStateContext = useContext(UiStateContext);
  const locationSort = uiStateContext.locationSort;
  const filters = uiStateContext.locationFilters;
  const zip = uiStateContext.zipLocation;
  const deviceType = useBreakpoint();

  return (
    <>
      <Box width={deviceType === DeviceType.Desktop ? inputBoxWidth.desktop : inputBoxWidth.mobile} my={[1, 2]}>
        <SelectInputContainer
          options={sortByOptionsLocation}
          placeholder={'Sort results by'}
          onChangeFunction={(selectedOption) => {
            onSortSelectChange({ sortBy: selectedOption });
          }}
          selectedValue={locationSort?.sortBy}
        />
      </Box>
      <Box
        className="zip-code-box"
        width={deviceType === DeviceType.Desktop ? inputBoxWidth.desktop : inputBoxWidth.mobile}
        my={[1, 2]}
        marginBottom={[1, 4]}
        height="60px"
      >
        <TextInputContainer
          id="zip-code-input"
          label="ZIP Code"
          isRequired={false}
          type="number"
          errorMessage="Invalid MI ZIP Code"
          maxLength={5}
          formControl={zip?.zip || { value: '', valid: true }}
          onChangeFunction={(input) => {
            onZipSelectChange({
              radius: 200,
              zip: input.value ? { value: input.value, valid: !input.validity.patternMismatch } : undefined
            } as ZipDistance);
          }}
        />
      </Box>
      <Box width={deviceType === DeviceType.Desktop ? inputBoxWidth.desktop : inputBoxWidth.mobile}>
        <Checkbox
          uniqueId="is-emergency"
          label="Emergency"
          checked={filters?.isEmergency}
          onChangeFunction={(isSelected) => {
            onFilterSelectChange({ ...filters, isEmergency: isSelected?.target?.checked ? true : undefined } as SearchLocationFilters);
          }}
        />
      </Box>
      <Box width={deviceType === DeviceType.Desktop ? inputBoxWidth.desktop : inputBoxWidth.mobile}>
        <Checkbox
          uniqueId="is-walkin"
          label="Walk-ins"
          checked={filters?.isWalkin}
          onChangeFunction={(isSelected) => {
            onFilterSelectChange({ ...filters, isWalkin: isSelected?.target?.checked ? true : undefined } as SearchLocationFilters);
          }}
        />
      </Box>
      <Box width={deviceType === DeviceType.Desktop ? inputBoxWidth.desktop : inputBoxWidth.mobile}>
        <Checkbox
          uniqueId="is-urgent-care"
          label="Urgent Care"
          checked={filters?.isUrgentCare}
          onChangeFunction={(isSelected) => {
            onFilterSelectChange({ ...filters, isUrgentCare: isSelected?.target?.checked ? true : undefined } as SearchLocationFilters);
          }}
        />
      </Box>
    </>
  );
};

export default LocationFilter;
