import React, { useContext } from "react";
import { Flex, Box } from "rebass/styled-components";

import ButtonWrapper from "../../shared/components/button-wrapper/button-wrapper";
import ShIcon from "../../shared/components/sh-icon/sh-icon";
import { UiStateContext } from "../../shared/contexts/ui-state/UiStateContext";
import useFeatureToggle from "../../shared/hooks/use-feature-toggle/useFeatureToggle";
import { FeatureToggle, GlobalNavContent, GlobalSearchNoResultsContent, IconPack } from "../../shared/models/app-data-model";
import SuggestedSearch from "../suggested-search/suggested-search";
import NoResultsModularBlock from "./no-results-modular-block/no-results-modular-block";
import "./no-results.scss";

export type NoResultsProps = {
  searchSuggestions?: Array<string>;
  featureToggles: FeatureToggle[];
  content?: GlobalSearchNoResultsContent;
  iconPack: IconPack[];
  globalNavContent?: GlobalNavContent;
  searchTerm?: string;
  kyruusNoResults?: boolean;
};

const NoResults: React.FC<NoResultsProps> = ({
  content,
  searchSuggestions,
  featureToggles,
  iconPack,
  globalNavContent,
  searchTerm,
  kyruusNoResults
}: NoResultsProps) => {
  const showSuggestedSearch = useFeatureToggle("suggested-search-component", featureToggles);
  const shorgSearchInitialState = useFeatureToggle("shorg-search-initial-state", featureToggles);
  const uiStateContext = useContext(UiStateContext);
  const useQueryParamSearch = searchTerm ? true : false;

  const modularBlocks = content?.modular_blocks
    ? content?.modular_blocks.map((item, index) => (
      <NoResultsModularBlock
        blockContent={item}
        key={index}
        featureToggles={featureToggles}
        queryParamSearch={useQueryParamSearch}
        iconPack={iconPack}
      />
    ))
    : null;

  const queryTextGreaterThanTwoCharacters = uiStateContext?.searchQuery?.length > 2 || searchTerm?.length;
  // if searchTerm is defined, used the search term, otherwise default to the uiStateContext search query
  const noResultsFoundText = searchTerm ? `No results found for "${searchTerm}"` : `No results found for "${uiStateContext.searchQuery}"`;

  const subtextContainerClasses = shorgSearchInitialState ? "subtext-div-larger-padding" : "subtext-div-small-padding";

  // TODO: This is hardcoded for Shorg only, because Shorg does not use Corewell's SearchTab content model (which includes this content).
  // Because of that, this initialStateShorgContent can be deleted after spectrumhealth.org gets retired.
  const initialStateShorgContent = [
    {
      title: "Search for a Doctor",
      text: "Choose an expert care provider that fits your personal needs",
      linkText: "Find a Doctor",
      linkHref: "https://corewellhealth.org/find-a-doctor"
    },
    {
      title: "Search by Location",
      text: "Explore our connected network to find the care that’s right for you",
      linkText: "Find a Location",
      linkHref: "https://corewellhealth.org/search/?tab=locations"
    }
  ];

  const initialStateBlocks = initialStateShorgContent.map((block) => (
    <div className="search-rec-section">
      <span className="search-rec-title">{block.title}</span>
      <p className="search-rec-text">{block.text}</p>
      <a href={block.linkHref} target="_blank" className="search-rec-link">
        {block.linkText}
        <ShIcon iconPack={iconPack} iconName="button_arrow" className="icon button-arrow-icon" />
      </a>
    </div>
  ));

  const initialStateContent = shorgSearchInitialState ? (
    <div className="search-recs-container">{initialStateBlocks}</div>
  ) : (
    <Box py={1}>
      <span id="let-us-help" data-testid="help-button">
        <ButtonWrapper
          type="tertiary"
          label="Let us help"
          reference={globalNavContent?.help?.reference}
          noPadding={true}
          iconPack={iconPack}
        />
      </span>
    </Box>
  );

  const noResults = (
    <>
      <Box paddingBottom={1}>
        <span className="no-results-main-title">{queryTextGreaterThanTwoCharacters ? noResultsFoundText : "Start typing to search"}</span>
      </Box>

      {searchSuggestions && searchSuggestions.length > 0 && showSuggestedSearch && (
        <SuggestedSearch searchSuggestions={searchSuggestions} />
      )}

      {!queryTextGreaterThanTwoCharacters && (
        <>
          <Box className={subtextContainerClasses}>
            <span className="body-small">Can&apos;t find what you&apos;re looking for?</span>
          </Box>
          {initialStateContent}
        </>
      )}
      {queryTextGreaterThanTwoCharacters && <Box>{modularBlocks}</Box>}
    </>
  );

  return (
    <Flex flexDirection="column" className={`no-results-wrapper ${kyruusNoResults ? "ch-no-results-wrapper" : ""}`}>
      {noResults}
    </Flex>
  );
};

export default NoResults;
