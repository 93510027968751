import { NavTabs } from '../../../search/search-nav/search-nav';



import { KendraApiResponse } from '../../hooks/kendra-api/useKendraApi';
import { SearchLocationFilters } from '../../hooks/search-locations-sort/useSortLocationResults';
import { SearchProviderFilters } from '../../hooks/search-providers-sort/useSortProviderResults';
import httpClient from '../http-client/http-client';


export interface Provider {
  _category: string;
  _source_uri: string;
  insurance_carrier: string[];
  is_spectrum_employed: string;
  new_patients: string;
  open_scheduling: string;
  provider_id: string;
  provider_degree: string;
  provider_gender: string;
  provider_language?: string[] | null;
  provider_name: string;
  provider_firstname: string;
  provider_lastname: string;
  provider_pic: string;
  provider_rating: string;
  provider_reviews: string;
  provider_specialty: string;
  street_address: string;
  city: string;
  state: string;
  zip_code: string;
  city_state_zip: string;
  distance: string;
  feedback_token: string;
  latitude: string;
  longitude: string;
}

export interface Location {
  location_specialty: string[];
  street_address: string;
  city?: string;
  state?: string;
  location_name: string;
  location_reviews: string;
  _source_uri: string;
  is_spectrum_employed: string;
  _category: string;
  location_pic: string;
  location_hours: string;
  location_rating: string;
  zip_code: string;
  location_id: string;
  is_emergency: string;
  is_walkin: string;
  is_urgent: string;
  distance: string;
  feedback_token: string;
  latitude: string;
  longitude: string;
}

export interface Page {
  _category: string;
  source_uri: string;
  title: string;
  paragraph: string;
  image_url: string;
  feedback_token: string;
}

export enum KendraResponseCategory {
  Providers = 'providers',
  Locations = 'locations',
  Pages = 'pages',
  Articles = 'articles'
}

const getCategoryPathBy = (activeSearchCategory: NavTabs): string => {
  if (activeSearchCategory === NavTabs.doctors) {
    return 'providers';
  } else if (activeSearchCategory === NavTabs.locations) {
    return 'locations';
  } else if (activeSearchCategory === NavTabs.pages) {
    return 'pages';
  } else {
    return 'search';
  }
};

const getEmbeddedSearchCategory = (isLocationSearch: boolean): string => {
  return isLocationSearch ? 'locations' : 'providers';
};

const getProviderFilters = (filters: SearchProviderFilters): string => {
  let allFilters = '';

  if (filters.insurance) {
    allFilters += `&insurance_carrier=${filters.insurance.value?.toLowerCase()}`;
  }
  /* istanbul ignore else */
  if (filters.gender) {
    allFilters += `&provider_gender=${filters.gender.value?.toLowerCase()}`;
  }
  if (filters.language) {
    allFilters += `&provider_language=${filters.language.value?.toLowerCase()}`;
  }
  if (filters.zipcode) {
    allFilters += `&zip_code=${filters.zipcode.value}`;
  }
  if (filters.acceptingNewPatients) {
    allFilters += `&new_patients=${filters.acceptingNewPatients}`;
  }
  if (filters.onlineScheduling) {
    allFilters += `&open_scheduling=${filters.onlineScheduling}`;
  }
  return allFilters;
};

const getLocationFilters = (filters: SearchLocationFilters): string => {
  let allFilters = '';

  if (filters.zipcode) {
    allFilters += `&zip_code=${filters.zipcode.value}`;
  }

  if (filters.isEmergency) {
    allFilters += `&is_emergency=${filters.isEmergency}`;
  }

  if (filters.isWalkin) {
    allFilters += `&is_walkin=${filters.isWalkin}`;
  }

  if (filters.isUrgentCare) {
    allFilters += `&is_urgent=${filters.isUrgentCare}`;
  }

  return allFilters;
};

const KendraApiService = {
  getSearchResult(
    queryText: string,
    location?: { latitude: number; longitude: number },
    activeSearchCategory?: NavTabs,
    providerFilters?: SearchProviderFilters,
    locationFilters?: SearchLocationFilters,
    useByIdEndpoint?: boolean,
    providerSpecialtyIds?: string,
    locationSpecialtyIds?: string,
    shEmployedOnly?: boolean
  ) {
    activeSearchCategory = activeSearchCategory || NavTabs.all;
    const isEmbeddedSearch = providerSpecialtyIds || locationSpecialtyIds;

    // The category URL based on whether it's embedded or global search
    const category = isEmbeddedSearch ? getEmbeddedSearchCategory(!!locationSpecialtyIds) : getCategoryPathBy(activeSearchCategory);

    // The byId url
    const byIdUrl = "/byId";

    // whitespace in the string interpolation below is very important,
    // if you add any by formatting the lines below to be indented, you'll accidentally add whitespace and break the kendra query
    // and if you want to avoid new-lines, you can also end each line in the string-interpolation with a `\`: https://stackoverflow.com/questions/37321047/wrap-long-template-literal-line-to-multiline-without-creating-a-new-line-in-the
    const searchQueryParams = `\
${category ? category : ""}\
${useByIdEndpoint ? byIdUrl : ""}\
/?query=${queryText ? queryText : ""}\
${useByIdEndpoint && queryText ? `&id=${queryText}` : ""}\
${providerSpecialtyIds ? `&provider_specialty_ids=${providerSpecialtyIds}` : ""}\
${locationSpecialtyIds ? `&location_specialty_ids=${locationSpecialtyIds}` : ""}\
${shEmployedOnly ? `&is_spectrum_employed=${shEmployedOnly}` : ""}\
${providerFilters ? getProviderFilters(providerFilters) : ""}\
${locationFilters ? getLocationFilters(locationFilters) : ""}\
${location?.latitude && location?.longitude ? `&lat=${location?.latitude}&long=${location?.longitude}` : ""}\
&page_num=1\
`;

    const path = `${process.env.GATSBY_KENDRA_API_ENDPOINT}/${searchQueryParams}`;
    const key = process.env.GATSBY_KENDRA_API_KEY;
    const headers = { "x-api-key": key };

    return httpClient
      .get(path, headers)
      .then((response: any) => {
        return response.data as KendraApiResponse;
      })
      .catch((e) => {
        throw new Error(`Error when calling Kendra API: ${e}`);
      });
  },

  getKendraSearchResults(queryText: string, pageNum: string, pageSize: string, locationResults: boolean) {
    const searchQueryParams = `search/?query=${queryText}&page_num=${pageNum}&page_size=${pageSize}${locationResults ? "" : "&location_results=false"}`;
    const path = `${process.env.GATSBY_KENDRA_API_ENDPOINT}/${searchQueryParams}`;
    const key = process.env.GATSBY_KENDRA_API_KEY;
    const headers = { "x-api-key": key };

    return httpClient
      .get(path, headers)
      .then((response: any) => {
        return response.data as KendraApiResponse;
      })
      .catch((e) => {
        throw new Error(`Error when calling Kendra API: ${e}`);
      });
  }
};

export default KendraApiService;