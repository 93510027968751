import { NavTabs } from '../../../search/search-nav/search-nav';
import React from 'react';

import { SearchLocationFilters, SearchLocationSort } from '../../hooks/search-locations-sort/useSortLocationResults';
import { SearchProviderFilters, SearchProviderSort } from '../../hooks/search-providers-sort/useSortProviderResults';
import { ZipDistance } from '../../hooks/zip-distance/useZipDistance';
import { AlertsContent, RegionalLinksModalContent } from '../../models/app-data-model';

export type GlobalUiState = {
  menuOpen: boolean;
  setMenuOpen?: (menuOpen: boolean) => void;
  menuOpenIndex: number;
  setMenuOpenIndex?: (index: number) => void;
  subsiteMenuOpen: boolean;
  megaMenuOpen: boolean;
  setMegaMenuOpen?: (megaMenuOpen: boolean) => void;
  setSubsiteMenuOpen?: (menuOpen: boolean) => void;
  subsiteMenuOpenIndex: number;
  setSubsiteMenuOpenIndex?: (index: number) => void;
  auxNavOpen: boolean;
  setAuxNavOpen?: (menuOpen: boolean) => void;
  scrollPosition: number;
  setScrollPosition?: (scrollPosition: number) => void;
  pageAlerts: AlertsContent[];
  setPageAlerts: (alerts: AlertsContent[]) => void;
  subsiteAlerts: AlertsContent[];
  setSubsiteAlerts: (alerts: AlertsContent[]) => void;
  activeSearchTab: NavTabs;
  setActiveSearchTab?: (activeSearchTab: NavTabs) => void;
  backButtonNavItem: boolean;
  setBackButtonNavItem?: (backButtonNavItem: boolean) => void;
  pageHasScrolled: boolean;
  setPageHasScrolled?: (hasScrolled: boolean) => void;
  searchOpen: boolean;
  setSearchOpen?: (searchBarOpen: boolean) => void;
  embeddedSearchShown: boolean;
  setEmbeddedSearchShown?: (embeddedSearchShown: boolean) => void;
  searchQuery: string;
  setSearchQuery?: (searchQuery: string) => void;
  alertCount: number;
  setAlertCount?: (count: number) => void;
  showAlerts: boolean;
  setShowAlerts?: (showAlerts: boolean) => void;
  alertContainerHeight: number;
  setAlertContainerHeight?: (height: number) => void;
  selectedFooterItem: number;
  setSelectedFooterItem?: (index: number) => void;
  browserLocation: { latitude: number; longitude: number };
  setBrowserLocation?: (browserLocation: { latitude: number; longitude: number }) => void;
  zipLocation?: ZipDistance;
  setZipLocation: (zipLocation: ZipDistance) => void;
  userAcceptedLocationPrompt: boolean;
  setUserAcceptedLocationPrompt?: (accepted: boolean) => void;
  userDeniedLocationPrompt: boolean;
  setUserDeniedLocationPrompt?: (accepted: boolean) => void;
  providerFilters?: SearchProviderFilters;
  setProviderFilters: (providerFilters: SearchProviderFilters) => void;
  providerSort?: SearchProviderSort;
  setProviderSort: (providerSort: SearchProviderSort) => void;
  locationFilters?: SearchLocationFilters;
  setLocationFilters: (locationFilters: SearchLocationFilters) => void;
  locationSort?: SearchLocationSort;
  setLocationSort: (locationSort: SearchLocationSort) => void;
  userLocationText?: string;
  setUserLocationText?: (userLocationText: string) => void;
  userRegion: string;
  setUserRegion?: (userRegion: string) => void;
  allowHosts: string[];
  setAllowHosts?: (allowHosts: string[]) => void;
};
// instead of just using our AppDataModel as our full data context...
// let's wrap it in an object so it's flexible if we need to add things outside of our AppDataModel for application state
// eslint-disable-next-line max-len
const UiStateContext = React.createContext({
  menuOpen: false,
  backButtonNavItem: false,
  searchOpen: false,
  activeSearchTab: NavTabs?.all
} as unknown as GlobalUiState);
export { UiStateContext };
