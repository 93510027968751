import { useCallback, useEffect, useState } from 'react';
import LocationApiService, { LocationApiProps, LocationObject, SuggestedLocation, Place } from '../../services/location-api/location-api-service';

export type ApiServiceStatus = {
  response: Array<LocationObject | SuggestedLocation | Place>;
  isRunning: boolean;
  hasError: boolean;
  error?: any;
};

/**
 * This Location Api Hook is used to fetch data from the AWS Location service at RUNTIME
 * (instead of at buildtime like our graphql)
 * @param queries
 * @returns an array of locations
 */
const useLocationApi = (locationProps: LocationApiProps): [ApiServiceStatus] => {
  const [isRunning, setIsRunning] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(undefined as any);
  const [response, setResponse] = useState(new Array<LocationObject | SuggestedLocation | Place>());
  const [apiStatus, setApiStatus] = useState({ isRunning: false, response: response } as ApiServiceStatus);

  useEffect(() => {
    setApiStatus({
      isRunning: isRunning,
      hasError: hasError,
      response: response,
      error: error
    });

  }, [isRunning, hasError, response, error]);

  const apiCall = useCallback(async () => {
    setIsRunning(true);
    setHasError(false);

    if ((locationProps?.text && locationProps?.text?.length > 2)
      || (locationProps?.latitude && locationProps?.longitude)
      || locationProps?.placeId
    ) {
      try {
        const response = await LocationApiService.getLocationResults(locationProps);

        setIsRunning(false);
        setResponse(response);
      } catch (err) {
        setError(err);
        setHasError(true);
        setIsRunning(false);
      }
    }

  }, [locationProps.text, locationProps.latitude, locationProps.longitude, locationProps.placeId]);

  useEffect(() => {
    apiCall();
  }, [locationProps.text, locationProps.latitude, locationProps.longitude, locationProps.placeId]);

  return [apiStatus];
};

export default useLocationApi;
