export const heroType = {
  low: "sh_spectrumhealth_org_hero_low_impact_narrow",
  medium: "sh_spectrumhealth_org_hero_medium_impact_container",
  high: "sh_spectrumhealth_org_hero_high_impact_full_bleed_",
  media: "sh_spectrumhealth_org_hero_background_media",
  inset: "sh_inset_headline_hero_spectrumhealth_org",
  reducedHeight: "sh_hero_high_impact_reduced_height",
  kyruusSearch: "sh_kyruus_search_widget",
  transactional: "sh_transactional_hero"
};

export const layout = {
  pageWidth: [1, 1, 1, 1, 12 / 14, 12 / 14, 12 / 16],
  pagePaddingX: [4, 4, 4, 4, 5],
  dynamicContainerWidthStandard: [1, 1, 1, 1, 12 / 14, 12 / 14, 12 / 16],
  dynamicContainerMarginX: [4, 4, 4, 4, 5],
  dynamicContainerWidthPadding: [4, 4, 4, 6, 9],
  singleColWidth: [1, 1, 1, 1, 10 / 14, 10 / 14, 10 / 16],
  ctaWidth: [1, 1, 1, 1, 13 / 14, 13 / 14, 14 / 16],
  ctaPaddingX: [0, 0, 0, 0, 0, 0, 5],
  searchPaddingX: [3, 3, 3, 4, 5],
  toolbarPaddingX: [0, 0, 0, 0, 5]
};

export const inputBoxWidth = {
  desktop: "231px",
  mobile: "100%"
};

export const corewellCardGridOverrideClasses = {
  cardboxShadow: "cwh-card-box-shadow",
  cardborderRadius: "cwh-card-border-radius",
  cardboxBorder: "cwh-card-border",
  cardCaptionTypography: "cwh-card-caption-typography"
};

export const corewellAccordionItemOverrideClass = {
  accordionHeadingTextOverride: "cwh-accordion-heading-text-override",
  accordionBodyCopyOverride: "cwh-accordion-body-copy-override"
};

export const corewellHeroOverrideClasses = {
  mediumImpactHeroHeadingOverride: "ch-medium-hero-heading-override",
  mediumImpactHeroBodyOverride: "ch-medium-hero-body-override",
  heroMarginTopOverride: "ch-hero-margin-top-override",
};
