/* eslint-disable no-unused-expressions */
import React from "react";

import { Media } from "../../contexts/media/MediaContext";
import useGrid from "../../hooks/grid/useGrid";
import useFeatureToggle from "../../hooks/use-feature-toggle/useFeatureToggle";
import { ContentCardGridContent, FeatureToggle, IconPack } from "../../models/app-data-model";
import CardGridContainerDesktop from "../card-grid-container-desktop/card-grid-container-desktop";
import ContentCardGridMobile from "../content-card-grid-mobile/content-card-grid-mobile";
import ContentCard from "../content-card/content-card";

export type ContentCardGridProps = {
  content: ContentCardGridContent;
  featureToggles: FeatureToggle[];
  corewellStyleOverrides?: boolean;
  iconPack: IconPack[];
  modularBlock?: boolean;
};

const ContentCardGrid: React.FC<ContentCardGridProps> = ({
  content,
  featureToggles,
  corewellStyleOverrides,
  iconPack,
  modularBlock
}: ContentCardGridProps) => {
  const showGrid: boolean = useFeatureToggle("card grid", featureToggles);
  const cardGrid = useGrid(content.cards, content.max_columns);

  if (!showGrid) {
    return null;
  }

  const multiColumnRenderings = cardGrid?.map((cardRow, rowIndex) => {
    const cardRowClass = `card-row ${rowIndex === 0 ? "first-row" : ""} ${rowIndex + 1 === cardGrid.length ? "last-row" : ""}`;

    return (
      <div data-testid={`cardrow${rowIndex}`} key={rowIndex} className={cardRowClass}>
        {cardRow.items?.map((card, cardIndex) => (
          <ContentCard
            key={cardIndex}
            cardImage={
              content?.max_columns === 3 && !content?.square_mode ? card.content_card.image_landscape : card.content_card.image_square
            }
            cardWidth={content?.max_columns}
            includeImagePadding={content?.include_image_padding}
            squareMode={content?.square_mode}
            content={card}
            corewellStyleOverrides={corewellStyleOverrides}
            iconPack={iconPack}
          />
        ))}
      </div>
    );
  });

  const singleColumnRenderings = content?.cards?.map((card, index) => (
    <ContentCard
      key={index}
      cardImage={card.content_card.image_square}
      cardWidth={1}
      content={card}
      includeImagePadding={content?.include_image_padding}
      corewellStyleOverrides={corewellStyleOverrides}
      iconPack={iconPack}
    />
  ));

  return (
    <>
      <Media lessThan="tablet">
        <div data-testid="cardGridMobile">
          <ContentCardGridMobile
            iconPack={iconPack}
            content={content}
            corewellStyleOverrides={corewellStyleOverrides}
            tertiaryButton={content?.tertiary_button}
          />
        </div>
      </Media>

      <Media at="tablet" data-testid="cardGridTablet">
        <div data-testid="cardGridTablet">
          <CardGridContainerDesktop iconPack={iconPack} maxColumns={content?.max_columns} tertiaryButton={content?.tertiary_button} modularBlock={modularBlock}>
            {singleColumnRenderings}
          </CardGridContainerDesktop>
        </div>
      </Media>

      <Media greaterThan="tablet">
        <div data-testid="cardGridDesktop">
          <CardGridContainerDesktop iconPack={iconPack} maxColumns={content?.max_columns} tertiaryButton={content?.tertiary_button} modularBlock={modularBlock}>
            {content?.max_columns > 1 ? multiColumnRenderings : singleColumnRenderings}
          </CardGridContainerDesktop>
        </div>
      </Media>
    </>
  );
};

export default ContentCardGrid;
