import React, { useContext } from 'react';

import ContentCardGrid from '../../../shared/components/content-card-grid/content-card-grid';
import SingleColumnBlock from '../../../shared/components/single-column-block/single-column-block';
import { UiStateContext } from '../../../shared/contexts/ui-state/UiStateContext';
import { FeatureToggle, GlobalSearchNoResultsBody } from '../../../shared/models/app-data-model';
import { IconPack } from '../../../shared/models/app-data-model';
import { NavTabs } from '../../search-nav/search-nav';
import './no-results-modular-block.scss';

export type NoResultsModularBlockProps = {
  blockContent: GlobalSearchNoResultsBody;
  featureToggles: FeatureToggle[];
  iconPack: IconPack[];
  queryParamSearch?: boolean;
};

const NoResultsModularBlock: React.FC<NoResultsModularBlockProps> = ({
  blockContent,
  iconPack,
  featureToggles,
  queryParamSearch
}: NoResultsModularBlockProps) => {
  const uiStateContext = useContext(UiStateContext);

  const contentCards = <ContentCardGrid content={blockContent.content_card_grid} featureToggles={featureToggles} iconPack={iconPack} />;

  const contentCardBlock = queryParamSearch ? contentCards : uiStateContext.activeSearchTab === NavTabs.all && uiStateContext.searchOpen && contentCards;

  return (
    <div className="no-results-modular-block">
      {blockContent.single_column_content_block && (
        <SingleColumnBlock column={blockContent.single_column_content_block} iconPack={iconPack} featureToggles={featureToggles} />
      )}
      
      {blockContent.content_card_grid && contentCardBlock}
    </div>
  );
};

export default NoResultsModularBlock;
