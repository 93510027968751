import React from 'react';

import ShIcon from '../../shared/components/sh-icon/sh-icon';
import { IconPack } from '../../shared/models/app-data-model';
import './search-clear-filters.scss';

export type SearchClearFilterProps = {
  clearFilter: () => void;
  iconPack: IconPack[];
};

const SearchClearFilters: React.FC<SearchClearFilterProps> = ({ clearFilter, iconPack }: SearchClearFilterProps) => {
  return (
    <button className="search-clear-filters unstyled-btn" data-testid="clear-all-filter" onClick={clearFilter}>
      <span className="body-small clear-filters-text">Clear all filters</span>
      <ShIcon iconPack={iconPack} iconName="close" className="clear-filters-icon" />
    </button>
  );
};

export default SearchClearFilters;
