import { GlobalUiState } from '../shared/contexts/ui-state/UiStateContext';
import { Coordinates, Location, Provider } from '../shared/models/app-data-model';

const DEFAULT_LOCATION = { latitude: 42.9679817, longitude: -85.665579 };

interface NumCoordinates {
  latitude: number;
  longitude: number;
}

// From https://stackoverflow.com/questions/14560999/using-the-haversine-formula-in-javascript
const haversineDistance = (coords1: NumCoordinates, coords2: NumCoordinates, isMiles = true): string => {
  const toRad = (x: number): number => {
    return (x * Math.PI) / 180;
  };

  const lon1 = coords1.longitude;
  const lat1 = coords1.latitude;

  const lon2 = coords2.longitude;
  const lat2 = coords2.latitude;

  const R = 6371; // km

  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c;

  if (isMiles) d /= 1.60934;

  return d.toFixed(2);
};

const getDistanceFromCoordinates = (c: Coordinates, uiState: GlobalUiState): string => {
  return haversineDistance(
    {
      latitude: parseFloat(c.latitude),
      longitude: parseFloat(c.longitude)
    },
    uiState?.browserLocation?.latitude
      ? {
          latitude: uiState.browserLocation.latitude,
          longitude: uiState.browserLocation.longitude
        }
      : DEFAULT_LOCATION
  );
};

export const addProviderDistance = (p: Provider, uiState: GlobalUiState): Provider => {
  const { latitude = '0', longitude = '0' } = p?.location?.coordinates || {};
  return { ...p, distance: getDistanceFromCoordinates({ latitude, longitude }, uiState) };
};

export const addLocationDistance = (l: Location, uiState: GlobalUiState): Location => {
  const { latitude = '0', longitude = '0' } = l?.coordinates || {};
  return { ...l, distance: getDistanceFromCoordinates({ latitude, longitude }, uiState) };
};
