import React from "react";
import { Box, Flex } from "rebass/styled-components";

import { layout } from "../../../constants/constants";
import { ButtonContent, IconPack } from "../../models/app-data-model";
import ButtonWrapper from "../button-wrapper/button-wrapper";
import "./card-grid-container-desktop.scss";

export type CardGridContainerDesktopProps = {
  maxColumns: number;
  tertiaryButton?: ButtonContent;
  children: React.ReactNode;
  noBackgroundDisplay?: boolean;
  hideTertiaryButton?: boolean;
  iconPack: IconPack[];
  modularBlock?: boolean;
};

/**
 * This component is meant to be used as a container for the Content Card Grid, Location Card Grid, and Provider Card Grid on desktop
 * @param the card grid container component props
 * @returns the card grid container component for desktop
 */

const CardGridContainerDesktop: React.FC<CardGridContainerDesktopProps> = ({
  maxColumns,
  tertiaryButton,
  children,
  noBackgroundDisplay,
  hideTertiaryButton,
  iconPack,
  modularBlock
}: CardGridContainerDesktopProps) => {
  const button =
    tertiaryButton?.text !== "" && tertiaryButton?.reference?.length ? (
      <ButtonWrapper
        iconPack={iconPack}
        type="tertiary"
        label={tertiaryButton?.text}
        styleOverride="transparent-bg"
        reference={tertiaryButton?.reference}
      />
    ) : null;

  const modularBlockWidth = maxColumns > 1 ? layout.pageWidth : layout.singleColWidth;

  return (
    <Flex className="card-grid-container-desktop" mx="auto" width={1}>
      <Box
        width={modularBlock ? modularBlockWidth : 1}
        className={`${!modularBlock && "no-padding"} grid-content ${noBackgroundDisplay || !modularBlock ? "" : "show-background"}`}
      >
        {children}
        {button && !hideTertiaryButton && (
          <span data-testid="optional-tertiary-button" className="grid-bottom-button">
            {button}
          </span>
        )}
      </Box>
    </Flex>
  );
};

export default CardGridContainerDesktop;
