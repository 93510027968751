import React from 'react';
import { Flex } from 'rebass/styled-components';

import ProviderCardDesktop from '../../shared/components/provider-card-desktop/provider-card-desktop';
import { mapKendraProvider } from '../../shared/components/provider-card-grid/provider-card-grid';
import ProviderCardMobile from '../../shared/components/provider-card-mobile/provider-card-mobile';
import { DeviceType } from '../../shared/hooks/use-breakpoint/device-type';
import useBreakpoint from '../../shared/hooks/use-breakpoint/useBreakpoint';
import { IconPack } from '../../shared/models/app-data-model';
import { Provider } from '../../shared/services/kendra-api/kendra-api-service';
import './providers-list.scss';

export type FilteredProvidersProps = {
  providers: Array<Provider>;
  iconPack: IconPack[];
};

type ProviderItemProps = {
  provider: Provider;
  iconPack: IconPack[];
  elementId: string;
};

export const ProviderItem: React.FC<ProviderItemProps> = ({ provider, iconPack, elementId }: ProviderItemProps) => {
  const deviceType = useBreakpoint();
  return deviceType === DeviceType.Desktop ? (
    <ProviderCardDesktop
      iconPack={iconPack}
      key={`provider-card-desktop-${provider.provider_id}`}
      provider={provider}
      elementId={elementId}
    />
  ) : (
    <ProviderCardMobile
      iconPack={iconPack}
      key={`provider-card-mobile-${provider.provider_id}`}
      provider={mapKendraProvider(provider)}
      elementId={elementId}
    />
  );
};

const ProvidersList: React.FC<FilteredProvidersProps> = (props: FilteredProvidersProps) => {
  const { providers, iconPack } = props;

  const result = (
    <Flex mx="auto" flexDirection="column">
      {providers?.map((item, index) => (
        <div key={item.provider_id}>
          <ProviderItem provider={item} iconPack={iconPack} elementId={`results-card-${index}`} />
        </div>
      ))}
    </Flex>
  );

  return result;
};

export default ProvidersList;
