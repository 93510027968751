import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Flex } from 'rebass/styled-components';

import { UiStateContext } from '../../contexts/ui-state/UiStateContext';
import { addProviderDistance } from '../../distance';
import useGrid from '../../hooks/grid/useGrid';
import useKendraGetByIdApi from '../../hooks/kendra-api/useKendraGetByIdApi';
import useRandomize from '../../hooks/randomize/useRandomize';
import { DeviceType } from '../../hooks/use-breakpoint/device-type';
import useBreakpoint from '../../hooks/use-breakpoint/useBreakpoint';
import useFeatureToggle from '../../hooks/use-feature-toggle/useFeatureToggle';
import { FeatureToggle, IconPack, Provider, ProviderCardGridContent } from '../../models/app-data-model';
import { Provider as KendraProvider } from '../../services/kendra-api/kendra-api-service';
import CardGridContainerDesktop from '../card-grid-container-desktop/card-grid-container-desktop';
import ErrorBanner from '../error-banner/error-banner';
import LoadingSpinner from '../loading-spinner/loading-spinner';
import ProviderCardGridCardCompact from '../provider-card-grid-card-compact/provider-card-grid-card-compact';
import ProviderCardGridCard from '../provider-card-grid-card/provider-card-grid-card';
import ProviderCardGridMobile from '../provider-card-grid-mobile/provider-card-grid-mobile';

export type ProviderCardGridProps = {
  content: ProviderCardGridContent;
  featureToggles: FeatureToggle[];
  iconPack: IconPack[];
};

export type CardRow = {
  cards: Provider[];
};

export const mapKendraProvider = (kendra: KendraProvider): Provider => {
  const [city, stateZip] = (kendra.city_state_zip || '').split(', ');
  const [state, zip_code] = (stateZip || '').split(' ');
  return {
    provider_id: kendra.provider_id,
    title: `${kendra.provider_name}, ${kendra.provider_degree} `,
    website: {
      title: '',
      href: kendra._source_uri
    },
    photo: {
      title: '',
      href: kendra.provider_pic
    },
    primary_specialty: kendra.provider_specialty,
    spectrum_employed: kendra.is_spectrum_employed === 'true',
    new_patients: kendra.new_patients === 'yes',
    gender: kendra.provider_gender,
    // eslint-disable-next-line radix
    rating: kendra.provider_rating !== 'null' ? parseFloat(kendra.provider_rating) : 0,
    // eslint-disable-next-line radix
    reviews: kendra.provider_reviews !== 'null' ? parseInt(kendra.provider_reviews) : 0,
    status: 'active',
    location: {
      address: {
        street_address: kendra.street_address && kendra.street_address !== 'null' ? kendra.street_address : '',
        city,
        state,
        zip_code
      }
    },
    distance: kendra.distance,
    feedback_token: kendra.feedback_token
  } as Provider;
};

const ProviderCardGrid: React.FC<ProviderCardGridProps> = ({ content, featureToggles, iconPack }: ProviderCardGridProps) => {
  const showProviderCardGrid: boolean = useFeatureToggle('provider-card-grid', featureToggles);
  const uiStateContext = useContext(UiStateContext);
  const randomCards = useRandomize(content.cards);
  const csProviders = (content.randomize_card_order ? randomCards : content.cards)
    .filter((card) => card.provider_card.provider_reference?.length > 0 && card.provider_card.provider_reference[0].status === 'active')
    .map((card) => {
      const p = card.provider_card.provider_reference[0];
      if (p.location_reference.length > 0) {
        p.location = p.location_reference.find((l) => l.location_id === card.provider_card.location_id) || p.location_reference[0];
      }
      return addProviderDistance(p, uiStateContext);
    });
  const [providerIds] = useState(
    content.cards.filter((card) => card.provider_card.provider_reference?.length === 0).map((card) => card.provider_card.provider_id)
  );

  const deviceType = useBreakpoint();
  const [kendraApi, setProviderIds] = useKendraGetByIdApi(
    [],
    'providers',
    showProviderCardGrid,
    content.randomize_card_order,
    mapKendraProvider
  );

  const providers = [...(kendraApi?.responses || []), ...csProviders];
  const cardGrid = useGrid(providers, content?.max_columns);

  const refreshData = useCallback(() => {
    // We create a new array here using the spread operator so that the hook doesn't think it's the same reference
    // and the hooks inside useKendraGetByIdApi actually trigger.
    setProviderIds([...providerIds]);
  }, [providerIds, setProviderIds]);

  useEffect(() => {
    refreshData();
  }, [content.cards, setProviderIds, providerIds, refreshData, content.randomize_card_order]);

  const multiColumnRenderings = cardGrid?.map((cardRow, rowIndex) => {
    const cardRowClass = `card-row ${rowIndex === 0 ? 'first-row' : ''} ${rowIndex + 1 === cardGrid.length ? 'last-row' : ''}`;

    return content.max_columns === 3 ? (
      <div data-testid={`cardrow${rowIndex}`} key={rowIndex} className={cardRowClass}>
        {cardRow.items?.map((card, index) => (
          <ProviderCardGridCard iconPack={iconPack} key={index} cardWidth={content.max_columns} provider={card as Provider} />
        ))}
      </div>
    ) : (
      <div data-testid={`cardrow${rowIndex}`} key={rowIndex} className={cardRowClass}>
        {cardRow.items?.map((card, index) => (
          <ProviderCardGridCardCompact iconPack={iconPack} key={index} provider={card as Provider} />
        ))}
      </div>
    );
  });

  const singleColumnRenderings = providers?.map((card, index) => (
    <ProviderCardGridCard iconPack={iconPack} key={index} cardWidth={1} provider={card as unknown as Provider} />
  ));

  const desktopColumnRenderings = (
    <CardGridContainerDesktop iconPack={iconPack} maxColumns={content?.max_columns} tertiaryButton={content?.tertiary_button}>
      {content?.max_columns > 1 && deviceType > DeviceType.Tablet ? multiColumnRenderings : singleColumnRenderings}
    </CardGridContainerDesktop>
  );

  const cardGridRenderings =
    deviceType <= DeviceType.Tablet ? <ProviderCardGridMobile iconPack={iconPack} content={content} apiResponse={providers} /> : desktopColumnRenderings;

  return showProviderCardGrid ? (
    <>
      {kendraApi.isRunning ? (
        <LoadingSpinner />
      ) : kendraApi.hasError ? (
        <Flex justifyContent="center">
          <ErrorBanner onReload={refreshData} />
        </Flex>
      ) : (
        cardGridRenderings
      )}
    </>
  ) : null;
};

export default ProviderCardGrid;
