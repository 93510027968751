import { createMedia } from '@artsy/fresnel';

export const mediaBreakpoints = {
  mobile: 0,
  tablet: 641,
  desktop: 960,
  xl: 1280
};

const ShMedia = createMedia({
  breakpoints: mediaBreakpoints
});

// Make styles for injection into the header of the page
export const mediaStyles = ShMedia.createMediaStyle();

export const { Media, MediaContextProvider } = ShMedia;
