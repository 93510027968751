import React from 'react';

import './divider.scss';

export type DividerProps = {
  className?: string;
};

const Divider: React.FC<DividerProps> = ({ className }) => {
  return <div className={className ? className : 'divider'}></div>;
};

export default Divider;
