import httpClient from "../http-client/http-client";

export type LocationApiProps = {
  text?: string;
  latitude?: string;
  longitude?: string;
  placeId?: string;
};

export type LocationApiResponse = {
  data?: Place | Array<LocationObject | SuggestedLocation | Place>;
};

export type Place = {
  AddressNumber: string;
  Country: string;
  Geometry: {
    Point: Array<number>;
  };
  Interpolated: boolean;
  Label: string;
  Municipality: string;
  Neighborhood: string;
  PostalCode: string;
  Region: string;
  Street: string;
  SubRegion: string;
};

export type LocationObject = {
  Distance: Number;
  Place: Place;
};

export type SuggestedLocation = {
  Text: string;
  PlaceId: string;
};

const LocationApiService = {
  getLocationResults(locationProps: LocationApiProps) {
    let queryParams = "";
    const { text, latitude, longitude, placeId } = locationProps;
    if (placeId) {
      queryParams += `placeId=${placeId}`;
    } else if (latitude && longitude) {
      queryParams += `longitude=${locationProps.longitude}&latitude=${locationProps.latitude}`;
    } else if (text) {
      queryParams += `text=${text}`;
    }
    const baseUrl = process.env.GATSBY_LOCATION_API_ENDPOINT;

    const path = `${baseUrl}/location/{proxy+}?${queryParams}`;
    const key = process.env.GATSBY_LOCATION_APIKEY;

    const headers = { "x-api-key": key, "Content-Type": "application/json" };

    if (queryParams != "" && baseUrl != undefined && key != undefined) {
      return httpClient
        .get(path, headers)
        .then((response: LocationApiResponse) => {
          return response.data;
        })
        .catch((e) => {
          throw new Error(`Error when calling Location API: ${e}`);
        });
    }

    return [];
  }
};

export default LocationApiService;
