import { NavTabs } from '../../../search/search-nav/search-nav';
import React, { Dispatch, useCallback, useEffect, useState } from 'react';

import KendraApiService, { Location, Provider } from '../../services/kendra-api/kendra-api-service';

// eslint-disable-next-line
export type KendraApiResponse = {
  query_result: Array<Provider | Location>;
  query_suggestions: Array<string>;
};
export type ApiServiceStatus = {
  responses: Array<Provider | Location>;
  isRunning: boolean;
  hasError: boolean;
  error?: any;
};

/**
 * This is our Kendra Api Hook, and it is capable of fetching content from Kendra at RUNTIME
 * (instead of at buildtime like our graphql)
 * @param queries an array of queries you'd like to fetch from Kendra via RUNTIME http clients
 * @returns an array of responses
 */
const useKendraGetByIdApi = (
  ids: string[],
  category: 'providers' | 'locations',
  isFeatureEnabled: boolean,
  randomizedReturnOrder?: boolean,
  mapKendraResponse?: any
): [ApiServiceStatus, Dispatch<React.SetStateAction<string[]>>] => {
  const [idList, setIdList] = useState(ids);
  const [isRunning, setIsRunning] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(undefined as any);
  const [responses, setResponses] = useState(new Array<Provider | Location>());
  const [apiStatus, setApiStatus] = useState({ isRunning: false, responses: responses } as ApiServiceStatus);

  useEffect(() => {
    setApiStatus({
      isRunning: isRunning,
      hasError: hasError,
      responses: mapKendraResponse ? responses.map(mapKendraResponse) : responses,
      error: error
    });
  }, [isRunning, hasError, responses, error, mapKendraResponse]);

  const apiSearch = useCallback(async () => {
    if (!isFeatureEnabled || !idList || idList.length < 1) {
      setIsRunning(false);
      return;
    }

    setIsRunning(true);
    setHasError(false);

    try {
      const content = await KendraApiService.getSearchResult(
        idList.join(','),
        undefined,
        category === 'providers' ? NavTabs.doctors : category === 'locations' ? NavTabs.locations : undefined,
        undefined,
        undefined,
        true
      );
      setIsRunning(false);

      const searchResults = (): (Provider | Location)[] => {
        if (randomizedReturnOrder) {
          // Returns the providers or locations in a random order based off their index in the FindADoc API response
          const uniqueIds: string[] = [];
          const randomlyOrderedResponse = (content.query_result as (Provider | Location)[])?.filter((item) => {
            const providerId = category === 'providers' ? (item as Provider).provider_id : null;
            const locationId = category === 'locations' ? (item as Location).location_id : null;

            if (providerId && idList.includes(providerId) && item._category === 'providers' && !uniqueIds.includes(providerId)) {
              uniqueIds.push(providerId);
              return item;
            } else if (locationId && idList.includes(locationId) && item._category === 'locations' && !uniqueIds.includes(locationId)) {
              uniqueIds.push(locationId);
              return item;
            }
          });
          return randomlyOrderedResponse;
        } else {
          // Returns the providers or locations in the order that they were entered into Contentstack
          const orderedResponse: (Provider | Location)[] = [];
          idList.forEach((id) => {
            if (category === 'providers') {
              const result = (content.query_result as Provider[]).find((provider) => provider.provider_id === id);
              if (result) {
                orderedResponse.push(result);
              }
            } else if (category === 'locations') {
              const result = (content.query_result as Location[]).find((location) => location.location_id === id);
              if (result) {
                orderedResponse.push(result);
              }
            }
          });
          return orderedResponse;
        }
      };

      // Kendra API bug, warning until fixed.
      if (idList.length !== searchResults.length) {
        console.warn(`Missing ${category} in response body.`);
      }
      setResponses(searchResults);
    } catch (err) {
      setError(err);
      setHasError(true);
      setIsRunning(false);
    }
  }, [isFeatureEnabled, idList, category, randomizedReturnOrder]);

  useEffect(() => {
    apiSearch();
  }, [apiSearch]);

  return [apiStatus, setIdList];
};

export default useKendraGetByIdApi;
