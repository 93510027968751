import React from 'react';

import ButtonWrapper from '../button-wrapper/button-wrapper';
import './error-banner.scss';

export type ErrorBannerProps = {
  message?: string;
  subMessage?: string;
  tryAgainMessage?: string;
  onReload?: () => Promise<void> | void;
};

const ErrorBanner: React.FC<ErrorBannerProps> = ({
  message = 'Sorry, something went wrong.',
  subMessage = 'An error occurred. Click "Try Again" to retry the request.',
  tryAgainMessage = 'Try Again',
  onReload
}: ErrorBannerProps) => {
  return (
    <div className="error-banner">
      <h4 className="error-message">{message}</h4>
      <p className="error-sub-message body-small">{subMessage}</p>
      <ButtonWrapper type="primary" label={tryAgainMessage} onClick={onReload} />
    </div>
  );
};

export default ErrorBanner;
