import React from 'react';

import './search-total-results.scss';

export type SearchTotalResultsProps = {
  count: number;
  searchTerm?: string;
  dynamicSearch?: boolean;
};

const SearchTotalResults: React.FC<SearchTotalResultsProps> = ({ count, searchTerm, dynamicSearch }: SearchTotalResultsProps) => {
  const bodyText = searchTerm ? (
      <>{count} results for <span>{searchTerm}</span></>
    ) : `${count} results`
  return (
    <div className={`total-results-top ${dynamicSearch ? "dynamic-total" : ""}`}>
      <span className="body-small">{bodyText}</span>
    </div>
  );
};

export default SearchTotalResults;
