import { useState, useEffect, useContext } from 'react';

import { UiStateContext } from '../../contexts/ui-state/UiStateContext';
import { FormControl } from '../../models/app-data-model';
import { Location, Provider } from '../../services/kendra-api/kendra-api-service';
import zipCoords from './zipCoords.json';

export type ZipDistance = {
  zip: FormControl | undefined;
  radius: number | undefined;
};

const haversineDistance = ([lat1, lon1]: any, [lat2, lon2]: any) => {
  const toRadian = (angle: number) => (Math.PI / 180) * angle;
  const distance = (e: number, f: number) => (Math.PI / 180) * (e - f);
  const RADIUS_OF_EARTH_IN_KM = 6371;

  const dLat = distance(lat2, lat1);
  const dLon = distance(lon2, lon1);

  lat1 = toRadian(lat1);
  lat2 = toRadian(lat2);

  // Haversine Formula
  const a = Math.pow(Math.sin(dLat / 2), 2) + Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.asin(Math.sqrt(a));

  let finalDistance = RADIUS_OF_EARTH_IN_KM * c;

  // convert to miles
  finalDistance /= 1.60934;

  return finalDistance.toFixed(2);
};

const useZipDistance = (locations: Array<Location>): Array<Location> => {
  const [zipResults, setZipResults] = useState([] as Location[]);
  const uiStateContext = useContext(UiStateContext);

  useEffect(() => {
    let updatedLocations = [...locations];

    // We can update distance for zip on the client side because all data exists and just needs to be filtered.
    if (uiStateContext?.zipLocation?.zip?.value?.length === 5 && uiStateContext?.zipLocation?.zip?.valid) {
      const michZip = zipCoords?.find((searchZip) => searchZip.zip === uiStateContext?.zipLocation?.zip?.value);

      if (michZip) {
        // valid Michigan zip: calculate some distance
        const updatedDistance = updatedLocations.map((loc) => {
          if (loc.zip_code === uiStateContext?.zipLocation?.zip?.value) {
            return { ...loc, distance: '0.0' };
          } else {
            const calcD = haversineDistance([parseFloat(loc?.latitude), parseFloat(loc?.longitude)], [michZip.lat, michZip.lng]);
            return { ...loc, distance: calcD };
          }
        });

        // filter out prov with distance higher than radius limit
        const filterRadius = updatedDistance.filter((dis) => {
          if (uiStateContext?.zipLocation?.radius && parseFloat(dis.distance) <= uiStateContext?.zipLocation?.radius) {
            return dis;
          }
        });

        updatedLocations = filterRadius as Location[];
      } else {
        // not a valid Michigan zip update uistate to reflect it isnt valid
        const invalidResult = {
          zip: { value: uiStateContext?.zipLocation?.zip?.value, valid: false },
          radius: uiStateContext?.zipLocation?.radius
        };
        uiStateContext.setZipLocation(invalidResult);
      }
    }

    setZipResults([...updatedLocations]);
  }, [locations, uiStateContext]);

  return zipResults;
};

const useZipDistanceProvider = (provider: Array<Provider>): Array<Provider> => {
  const [zipResults, setZipResults] = useState([] as Provider[]);
  const uiStateContext = useContext(UiStateContext);

  useEffect(() => {
    let updatedProvider = [...provider];

    // We can update distance for zip on the client side because all data exists and just needs to be filtered.
    if (uiStateContext?.zipLocation?.zip?.value?.length === 5 && uiStateContext?.zipLocation?.zip?.valid) {
      const michZip = zipCoords?.find((searchZip) => searchZip.zip === uiStateContext?.zipLocation?.zip?.value);

      if (michZip) {
        // valid Michigan zip: calculate some distance
        const updatedDistance = updatedProvider.map((prov) => {
          if (prov.zip_code === uiStateContext?.zipLocation?.zip?.value) {
            return { ...prov, distance: '0.0' };
          } else {
            const calcD = haversineDistance([parseFloat(prov?.latitude), parseFloat(prov?.longitude)], [michZip.lat, michZip.lng]);
            return { ...prov, distance: calcD };
          }
        });

        // filter out prov with distance higher than radius limit
        const filterRadius = updatedDistance.filter((dis) => {
          if (uiStateContext?.zipLocation?.radius && parseFloat(dis.distance) <= uiStateContext?.zipLocation?.radius) {
            return dis;
          }
        });

        updatedProvider = filterRadius as Provider[];
      } else {
        // not a valid Michigan zip update uistate to reflect it isnt valid
        const invalidResult = {
          zip: { value: uiStateContext?.zipLocation?.zip?.value, valid: false },
          radius: uiStateContext?.zipLocation?.radius
        };
        uiStateContext.setZipLocation(invalidResult);
      }
    }

    setZipResults([...updatedProvider]);
  }, [provider, uiStateContext]);

  return zipResults;
};

export { useZipDistanceProvider };
export default useZipDistance;
