import React from 'react';
import { Box, Flex } from 'rebass/styled-components';

import { IconPack, Provider } from '../../models/app-data-model';
import CardImage from '../card-image/card-image';
import ShIcon from '../sh-icon/sh-icon';
import './provider-card-mobile.scss';

export type ProviderCardProps = {
  provider: Provider;
  iconPack: IconPack[];
  elementId?: string;
};

const ProviderCardMobile: React.FC<ProviderCardProps> = ({ provider, iconPack, elementId }: ProviderCardProps) => {
  let locationContent = <></>;
  if (provider?.location) {
    const { street_address: address, state, city, zip_code: zip } = provider.location.address;
    locationContent = (
      <Flex alignItems="center" justifyContent="space-between" width={1}>
        <Flex width={1} justifyContent="space-between">
          <Flex>
            <ShIcon iconPack={iconPack} iconName="map_pin" className="mobile-icon" />
            <span className="body-small secondary-text address">{`${address}, ${city}, ${state} ${zip}`}</span>
          </Flex>

          <Flex>{provider.distance !== 'null' && <span className="body-small secondary-text">{provider.distance} miles away</span>}</Flex>
        </Flex>
      </Flex>
    );
  }
  return (
    <>
      <a
        href={provider?.website?.href}
        className="no-decoration results-card"
        id={elementId}
        target="_blank"
        rel="noopener noreferrer"
        data-kendra-token={provider.feedback_token}
      >
        <Flex justifyContent="space-around" alignItems="center" marginY={2} className="provider-card" flexDirection="column">
          <Flex className="mobile-card-top" alignItems="center" py={2} px={3}>
            <Box className={provider?.spectrum_employed ? 'circular-border' : ''} my={1}>
              <CardImage url={provider?.photo?.href} alt={provider.title} className={'profile-pic'} type={'provider'} />
            </Box>
            <Flex alignItems="flex-start" flexDirection="column" justifyContent="center" ml="20px" mt={1}>
              <h6 className="provider-name-degree">{provider.title}</h6>
              {provider?.primary_specialty && <span className="secondary-text body-small">{provider.primary_specialty}</span>}
            </Flex>
          </Flex>
          <Flex className="mobile-card-bottom" justifyContent="space-between" alignItems="center" flexDirection="column" py={2} px={3}>
            <Flex justifyContent="space-between" width={1}>
              <Flex alignItems="center" justifyContent="space-between">
                <Box paddingRight={2}>
                  {provider.new_patients ? (
                    <ShIcon iconPack={iconPack} className="check-icon" iconName="check" />
                  ) : (
                    <ShIcon iconPack={iconPack} className="close-icon" iconName="bold_close" />
                  )}
                </Box>
                <span className="secondary-text body-small">
                  {provider.new_patients ? `Accepting new patients` : `Not accepting new patients`}
                </span>
              </Flex>
              {provider?.rating > 0 && (
                <Flex>
                  <Box paddingRight={2}>
                    <ShIcon iconPack={iconPack} iconName="star" className="star" />
                  </Box>
                  <span className="rating">{provider.rating}</span>
                  {provider?.reviews > 0 && <span className="reviews">({provider.reviews})</span>}
                </Flex>
              )}
            </Flex>
            <Flex justifyContent="space-between" width={1}>
              {locationContent}
            </Flex>
          </Flex>
        </Flex>
      </a>
    </>
  );
};

export default ProviderCardMobile;
