/* eslint-disable max-len */
import React from 'react';
import { createGlobalStyle } from 'styled-components';

import { mediaBreakpoints } from '../../contexts/media/MediaContext';
import { Typography } from '../../models/app-data-model';
import { number } from 'prop-types';

type StyleProps = {
  typography?: Typography;
  isMobile?: boolean;
  isTablet?: boolean;
};

export type TypographyStyleProps = {
  typographyData: Typography[];
};

/**
 * this is a helper function to help us return styles that need to be formated with `px` easily
 * @param getStyleFunc a function that takes a StyleProps and returns a number for a style value
 * @param dangerousProps the dangerousProps for the style
 */
const getSanitizedStyleInPixelsOrInherit = (
  getStyleFunc: (dangerousProps: StyleProps) => number | undefined,
  dangerousProps: StyleProps
): string => {
  const style = getStyleFunc(dangerousProps);
  return style ? `${style.toString()}px` : 'inherit'; // adding the px at the end of the dangerous Style helps break injection
};

const h1Size = (dangerousProps: StyleProps): number | undefined =>
  dangerousProps.isMobile ? dangerousProps.typography?.h1?.size :
  dangerousProps.isTablet ? dangerousProps.typography?.h1?.size_tablet || dangerousProps.typography?.h1?.size_desktop :
  dangerousProps.typography?.h1?.size_desktop;

const h1LineHeight = (dangerousProps: StyleProps): number | undefined =>
  dangerousProps.isMobile ? dangerousProps.typography?.h1?.line_height :
  dangerousProps.isTablet ? dangerousProps.typography?.h1?.line_height_tablet || dangerousProps.typography?.h1?.line_height_desktop :
  dangerousProps.typography?.h1?.line_height_desktop;

const h1Weight = (dangerousProps: StyleProps): number | string | undefined => 
  typeof dangerousProps.typography?.h1?.weight === 'number' ? dangerousProps.typography?.h1?.weight : CSS.escape(dangerousProps.typography?.h1?.weight || 'inherit');

const h2Size = (dangerousProps: StyleProps): number | undefined =>
  dangerousProps.isMobile ? dangerousProps.typography?.h2?.size :
  dangerousProps.isTablet ? dangerousProps.typography?.h2?.size_tablet || dangerousProps.typography?.h2?.size_desktop :
  dangerousProps.typography?.h2?.size_desktop;

const h2LineHeight = (dangerousProps: StyleProps): number | undefined =>
  dangerousProps.isMobile ? dangerousProps.typography?.h2?.line_height :
  dangerousProps.isTablet ? dangerousProps.typography?.h2?.line_height_tablet || dangerousProps.typography?.h2?.line_height_desktop :
  dangerousProps.typography?.h2?.line_height_desktop;

const h2Weight = (dangerousProps: StyleProps): number | string | undefined => 
  typeof dangerousProps.typography?.h2?.weight === 'number' ? dangerousProps.typography?.h2?.weight : CSS.escape(dangerousProps.typography?.h2?.weight || 'inherit');

const h3Size = (dangerousProps: StyleProps): number | undefined =>
  dangerousProps.isMobile ? dangerousProps.typography?.h3?.size :
  dangerousProps.isTablet ? dangerousProps.typography?.h3?.size_tablet || dangerousProps.typography?.h3?.size_desktop :
  dangerousProps.typography?.h3?.size_desktop;

const h3LineHeight = (dangerousProps: StyleProps): number | undefined =>
  dangerousProps.isMobile ? dangerousProps.typography?.h3?.line_height :
  dangerousProps.isTablet ? dangerousProps.typography?.h3?.line_height_tablet || dangerousProps.typography?.h3?.line_height_desktop :
  dangerousProps.typography?.h3?.line_height_desktop;

const h3Weight = (dangerousProps: StyleProps): number | string | undefined => 
  typeof dangerousProps.typography?.h3?.weight === 'number' ? dangerousProps.typography?.h3?.weight : CSS.escape(dangerousProps.typography?.h3?.weight || 'inherit');

const h4Size = (dangerousProps: StyleProps): number | undefined =>
  dangerousProps.isMobile ? dangerousProps.typography?.h4?.size :
  dangerousProps.isTablet ? dangerousProps.typography?.h4?.size_tablet || dangerousProps.typography?.h4?.size_desktop :
  dangerousProps.typography?.h4?.size_desktop;

const h4LineHeight = (dangerousProps: StyleProps): number | undefined =>
  dangerousProps.isMobile ? dangerousProps.typography?.h4?.line_height :
  dangerousProps.isTablet ? dangerousProps.typography?.h4?.line_height_tablet || dangerousProps.typography?.h4?.line_height_desktop :
  dangerousProps.typography?.h4?.line_height_desktop;

const h4Weight = (dangerousProps: StyleProps): number | string | undefined => 
  typeof dangerousProps.typography?.h4?.weight === 'number' ? dangerousProps.typography?.h4?.weight : CSS.escape(dangerousProps.typography?.h4?.weight || 'inherit');

const h5Size = (dangerousProps: StyleProps): number | undefined =>
  dangerousProps.isMobile ? dangerousProps.typography?.h5?.size :
  dangerousProps.isTablet ? dangerousProps.typography?.h5?.size_tablet || dangerousProps.typography?.h5?.size_desktop :
  dangerousProps.typography?.h5?.size_desktop;

const h5LineHeight = (dangerousProps: StyleProps): number | undefined =>
  dangerousProps.isMobile ? dangerousProps.typography?.h5?.line_height :
  dangerousProps.isTablet ? dangerousProps.typography?.h5?.line_height_tablet || dangerousProps.typography?.h5?.line_height_desktop :
  dangerousProps.typography?.h5?.line_height_desktop;

const h5Weight = (dangerousProps: StyleProps): number | string | undefined => 
  typeof dangerousProps.typography?.h5?.weight === 'number' ? dangerousProps.typography?.h5?.weight : CSS.escape(dangerousProps.typography?.h5?.weight || 'inherit');

const h6Size = (dangerousProps: StyleProps): number | undefined =>
  dangerousProps.isMobile ? dangerousProps.typography?.h6?.size :
  dangerousProps.isTablet ? dangerousProps.typography?.h6?.size_tablet || dangerousProps.typography?.h6?.size_desktop :
  dangerousProps.typography?.h6?.size_desktop;

const h6LineHeight = (dangerousProps: StyleProps): number | undefined =>
  dangerousProps.isMobile ? dangerousProps.typography?.h6?.line_height :
  dangerousProps.isTablet ? dangerousProps.typography?.h6?.line_height_tablet || dangerousProps.typography?.h6?.line_height_desktop :
  dangerousProps.typography?.h6?.line_height_desktop;

const h6Weight = (dangerousProps: StyleProps): number | string | undefined => 
  typeof dangerousProps.typography?.h6?.weight === 'number' ? dangerousProps.typography?.h6?.weight : CSS.escape(dangerousProps.typography?.h6?.weight || 'inherit');

const bodySmallSize = (dangerousProps: StyleProps): number | undefined =>
  dangerousProps.isMobile ? dangerousProps.typography?.body_small?.size :
  dangerousProps.isTablet ? dangerousProps.typography?.body_small?.size_tablet || dangerousProps.typography?.body_small?.size_desktop :
  dangerousProps.typography?.body_small?.size_desktop;

const bodySmallLineHeight = (dangerousProps: StyleProps): number | undefined =>
  dangerousProps.isMobile ? dangerousProps.typography?.body_small?.line_height :
  dangerousProps.isTablet ? dangerousProps.typography?.body_small?.line_height_tablet || dangerousProps.typography?.body_small?.line_height_desktop :
  dangerousProps.typography?.body_small?.line_height_desktop;

const bodySmallWeight = (dangerousProps: StyleProps): number | string | undefined => 
  typeof dangerousProps.typography?.body_small?.weight === 'number' ? dangerousProps.typography?.body_small?.weight : CSS.escape(dangerousProps.typography?.body_small?.weight || 'inherit');

const bodyRegularSize = (dangerousProps: StyleProps): number | undefined =>
  dangerousProps.isMobile ? dangerousProps.typography?.body_regular?.size :
  dangerousProps.isTablet ? dangerousProps.typography?.body_regular?.size_tablet || dangerousProps.typography?.body_regular?.size_desktop :
  dangerousProps.typography?.body_regular?.size_desktop;
  
const bodyRegularLineHeight = (dangerousProps: StyleProps): number | undefined =>
  dangerousProps.isMobile ? dangerousProps.typography?.body_regular?.line_height :
  dangerousProps.isTablet ? dangerousProps.typography?.body_regular?.line_height_tablet || dangerousProps.typography?.body_regular?.line_height_desktop :
  dangerousProps.typography?.body_regular?.line_height_desktop;

const bodyRegularWeight = (dangerousProps: StyleProps): number | string | undefined => 
  typeof dangerousProps.typography?.body_regular?.weight === 'number' ? dangerousProps.typography?.body_regular?.weight : CSS.escape(dangerousProps.typography?.body_regular?.weight || 'inherit');

const primaryNavSize = (dangerousProps: StyleProps): number | undefined =>
  dangerousProps.isMobile ? dangerousProps.typography?.prime_navigation?.size :
  dangerousProps.isTablet ? dangerousProps.typography?.prime_navigation?.size_tablet || dangerousProps.typography?.prime_navigation?.size_desktop :
  dangerousProps.typography?.prime_navigation?.size_desktop;

const primaryNavLineHeight = (dangerousProps: StyleProps): number | undefined =>
  dangerousProps.isMobile ? dangerousProps.typography?.prime_navigation?.line_height :
  dangerousProps.isTablet ? dangerousProps.typography?.prime_navigation?.line_height_tablet || dangerousProps.typography?.prime_navigation?.line_height_desktop :
  dangerousProps.typography?.prime_navigation?.line_height_desktop;

const primaryNavWeight = (dangerousProps: StyleProps): number | string | undefined => 
  typeof dangerousProps.typography?.prime_navigation?.weight === 'number' ? dangerousProps.typography?.prime_navigation?.weight : CSS.escape(dangerousProps.typography?.prime_navigation?.weight || 'inherit');

const overlineSize = (dangerousProps: StyleProps): number | undefined =>
  dangerousProps.isMobile ? dangerousProps.typography?.overline?.size :
  dangerousProps.isTablet ? dangerousProps.typography?.overline?.size_tablet || dangerousProps.typography?.overline?.size_desktop :
  dangerousProps.typography?.overline?.size_desktop;

const overlineLineHeight = (dangerousProps: StyleProps): number | undefined =>
  dangerousProps.isMobile ? dangerousProps.typography?.overline?.line_height :
  dangerousProps.isTablet ? dangerousProps.typography?.overline?.line_height_tablet || dangerousProps.typography?.overline?.line_height_desktop :
  dangerousProps.typography?.overline?.line_height_desktop;

const overlineWeight = (dangerousProps: StyleProps): number | string | undefined => 
  typeof dangerousProps.typography?.overline?.weight === 'number' ? dangerousProps.typography?.overline?.weight : CSS.escape(dangerousProps.typography?.overline?.weight || 'inherit');

const buttonSize = (dangerousProps: StyleProps): number | undefined =>
  dangerousProps.isMobile ? dangerousProps.typography?.button?.size :
  dangerousProps.isTablet ? dangerousProps.typography?.button?.size_tablet || dangerousProps.typography?.button?.size_desktop :
  dangerousProps.typography?.button?.size_desktop;

const buttonLineHeight = (dangerousProps: StyleProps): number | undefined =>
  dangerousProps.isMobile ? dangerousProps.typography?.button?.line_height :
  dangerousProps.isTablet ? dangerousProps.typography?.button?.line_height_tablet || dangerousProps.typography?.button?.line_height_desktop :
  dangerousProps.typography?.button?.line_height_desktop;

const buttonWeight = (dangerousProps: StyleProps): number | string | undefined => 
  typeof dangerousProps.typography?.button?.weight === 'number' ? dangerousProps.typography?.button?.weight : CSS.escape(dangerousProps.typography?.button?.weight || 'inherit');

// This is where we create our globalStyle jsx from styled-components
// ANYTHING we do here that is sourced from input (contentstack content for example) MUST be properly sanitized or protected!
// - CSS.escape() can be used to escape some values, which protects us
// - math operands on numbers will break if unsanitized input is not in the form we expect it, which protects us
// - adding units to the end of inputs will break unsanitized input that is not in the form we expect it, which protects us
// - conditional styles that have static hard-coded values in code protect us
const GlobalStyle = createGlobalStyle<StyleProps>`
  @media ${(dangerousProps) =>
    dangerousProps.isMobile ? `(max-width: ${mediaBreakpoints.tablet - 1}px)` :
    dangerousProps.isTablet ? `(min-width: ${mediaBreakpoints.tablet}px) and (max-width: ${mediaBreakpoints.desktop - 1}px)` :
    `(min-width: ${mediaBreakpoints.desktop}px)`} {
    h1 {
      color: ${(dangerousProps) =>
        '#' + CSS.escape(dangerousProps.typography?.h1?.color?.find((c) => c.color.color)?.color.color?.replace('#', '') || '000000')};
      font-size : ${(dangerousProps) => getSanitizedStyleInPixelsOrInherit(h1Size, dangerousProps)};
      font-weight : ${h1Weight};
      letter-spacing : ${(dangerousProps) =>
        dangerousProps.typography?.h1?.letter_spacing ? `${dangerousProps.typography?.h1?.letter_spacing}px` : 'inherit'};
      line-height : ${(dangerousProps) => getSanitizedStyleInPixelsOrInherit(h1LineHeight, dangerousProps)};
      margin-bottom: ${(dangerousProps) => (dangerousProps.isMobile || dangerousProps.isTablet ? '64px' : '48px')};
      margin-top: ${(dangerousProps) => (dangerousProps.isMobile || dangerousProps.isTablet ? '64px' : '48px')};
      text-transform : ${(dangerousProps) => CSS.escape(dangerousProps.typography?.h1?.case || 'inherit')};
    }
    h2, .h2 {
      color: ${(dangerousProps) =>
        '#' + CSS.escape(dangerousProps.typography?.h2?.color?.find((c) => c.color.color)?.color.color?.replace('#', '') || '000000')};
      font-size : ${(dangerousProps) => getSanitizedStyleInPixelsOrInherit(h2Size, dangerousProps)};
      font-weight : ${h2Weight};
      letter-spacing : ${(dangerousProps) =>
        dangerousProps.typography?.h2?.letter_spacing ? `${dangerousProps.typography?.h2?.letter_spacing}px` : 'inherit'};
      line-height : ${(dangerousProps) => getSanitizedStyleInPixelsOrInherit(h2LineHeight, dangerousProps)};
      margin-bottom: ${(dangerousProps) => (dangerousProps.isMobile ? '32px' : '32px')};
      margin-top: ${(dangerousProps) => (dangerousProps.isMobile ? '64px' : '48px')};
      text-transform : ${(dangerousProps) => CSS.escape(dangerousProps.typography?.h2?.case || 'inherit')};
    }
    h3 {
      color: ${(dangerousProps) =>
        '#' + CSS.escape(dangerousProps.typography?.h3?.color?.find((c) => c.color.color)?.color.color?.replace('#', '') || '000000')};
      font-size : ${(dangerousProps) => getSanitizedStyleInPixelsOrInherit(h3Size, dangerousProps)};
      font-weight : ${h3Weight};
      letter-spacing : ${(dangerousProps) =>
        dangerousProps.typography?.h3?.letter_spacing ? `${dangerousProps.typography?.h3?.letter_spacing}px` : 'inherit'};
      line-height : ${(dangerousProps) => getSanitizedStyleInPixelsOrInherit(h3LineHeight, dangerousProps)};
      margin-bottom: ${(dangerousProps) => (dangerousProps.isMobile ? '32px' : '24px')};
      margin-top: ${(dangerousProps) => (dangerousProps.isMobile ? '56px' : '48px')};
      text-transform : ${(dangerousProps) => CSS.escape(dangerousProps.typography?.h3?.case || 'inherit')};
    }
    h4 {
      color: ${(dangerousProps) =>
        '#' + CSS.escape(dangerousProps.typography?.h4?.color?.find((c) => c.color.color)?.color.color?.replace('#', '') || '000000')};
      font-size : ${(dangerousProps) => getSanitizedStyleInPixelsOrInherit(h4Size, dangerousProps)};
      font-weight : ${h4Weight};
      letter-spacing : ${(dangerousProps) =>
        dangerousProps.typography?.h4?.letter_spacing ? `${dangerousProps.typography?.h4?.letter_spacing}px` : 'inherit'};
      line-height : ${(dangerousProps) => getSanitizedStyleInPixelsOrInherit(h4LineHeight, dangerousProps)};
      margin-bottom: ${(dangerousProps) => (dangerousProps.isMobile ? '32px' : '24px')};
      margin-top: ${(dangerousProps) => (dangerousProps.isMobile ? '56px' : '32px')};
      text-transform : ${(dangerousProps) => CSS.escape(dangerousProps.typography?.h4?.case || 'inherit')};
    }
    h5 {
      color: ${(dangerousProps) =>
        '#' + CSS.escape(dangerousProps.typography?.h5?.color?.find((c) => c.color.color)?.color.color?.replace('#', '') || '000000')};
      font-size : ${(dangerousProps) => getSanitizedStyleInPixelsOrInherit(h5Size, dangerousProps)};
      font-weight : ${h5Weight};
      letter-spacing : ${(dangerousProps) =>
        dangerousProps.typography?.h5?.letter_spacing ? `${dangerousProps.typography?.h5?.letter_spacing}px` : 'inherit'};
      line-height : ${(dangerousProps) => getSanitizedStyleInPixelsOrInherit(h5LineHeight, dangerousProps)};
      margin-bottom: ${(dangerousProps) => (dangerousProps.isMobile ? '32px' : '24px')};
      margin-top: ${(dangerousProps) => (dangerousProps.isMobile ? '56px' : '32px')};
      text-transform : ${(dangerousProps) => CSS.escape(dangerousProps.typography?.h5?.case || 'inherit')};
    }
    h6, .h6 {
      color: ${(dangerousProps) =>
        '#' + CSS.escape(dangerousProps.typography?.h6?.color?.find((c) => c.color.color)?.color.color?.replace('#', '') || '000000')};
      font-size : ${(dangerousProps) => getSanitizedStyleInPixelsOrInherit(h6Size, dangerousProps)};
      font-weight : ${h6Weight};
      letter-spacing : ${(dangerousProps) =>
        dangerousProps.typography?.h6?.letter_spacing ? `${dangerousProps.typography?.h6?.letter_spacing}px` : 'inherit'};
      line-height : ${(dangerousProps) => getSanitizedStyleInPixelsOrInherit(h6LineHeight, dangerousProps)};
      margin-bottom: ${(dangerousProps) => (dangerousProps.isMobile ? '32px' : '24px')};
      margin-top: ${(dangerousProps) => (dangerousProps.isMobile ? '56px' : '32px')};
      text-transform : ${(dangerousProps) => CSS.escape(dangerousProps.typography?.h6?.case || 'inherit')};
    }
    small, .body-small {
      color: ${(dangerousProps) =>
        '#' +
        CSS.escape(dangerousProps.typography?.body_small?.color?.find((c) => c.color.color)?.color.color?.replace('#', '') || '000000')};
      font-size : ${(dangerousProps) => getSanitizedStyleInPixelsOrInherit(bodySmallSize, dangerousProps)};
      font-weight : ${bodySmallWeight};
      letter-spacing : ${(dangerousProps) =>
        dangerousProps.typography?.body_small?.letter_spacing
          ? `${dangerousProps.typography?.body_small?.letter_spacing}px`
          : 'inherit'};
      line-height : ${(dangerousProps) => getSanitizedStyleInPixelsOrInherit(bodySmallLineHeight, dangerousProps)};
      margin-bottom: ${(dangerousProps) => (dangerousProps.isMobile ? '32px' : '24px')};
      margin-top: ${(dangerousProps) => (dangerousProps.isMobile ? '32px' : '24px')};
      text-transform : ${(dangerousProps) => CSS.escape(dangerousProps.typography?.body_small?.case || 'inherit')};
    }
    p, .body-regular, ul {
      color: ${(dangerousProps) =>
        '#' +
        CSS.escape(dangerousProps.typography?.body_regular?.color?.find((c) => c.color.color)?.color.color?.replace('#', '') || '000000')};
      font-size : ${(dangerousProps) => getSanitizedStyleInPixelsOrInherit(bodyRegularSize, dangerousProps)};
      font-weight : ${bodyRegularWeight};
      letter-spacing : ${(dangerousProps) =>
        dangerousProps.typography?.body_regular?.letter_spacing
          ? `${dangerousProps.typography?.body_regular?.letter_spacing}px`
          : 'inherit'};
      line-height : ${(dangerousProps) => getSanitizedStyleInPixelsOrInherit(bodyRegularLineHeight, dangerousProps)};
      margin-bottom: 24px;
      margin-top: 24px;
      text-transform : ${(dangerousProps) => CSS.escape(dangerousProps.typography?.body_regular?.case || 'inherit')};
    }
    button {
      color: ${(dangerousProps) =>
        '#' + CSS.escape(dangerousProps.typography?.button?.color?.find((c) => c.color.color)?.color.color?.replace('#', '') || '000000')};
      font-size : ${(dangerousProps) => getSanitizedStyleInPixelsOrInherit(buttonSize, dangerousProps)};
      font-weight : ${buttonWeight};
      letter-spacing : ${(dangerousProps) =>
        dangerousProps.typography?.button?.letter_spacing ? `${dangerousProps.typography?.button?.letter_spacing}px` : 'inherit'};
      line-height : ${(dangerousProps) => getSanitizedStyleInPixelsOrInherit(buttonLineHeight, dangerousProps)};
      margin-bottom: ${(dangerousProps) => (dangerousProps.isMobile ? '64px' : '48px')};
      margin-top: ${(dangerousProps) => (dangerousProps.isMobile ? '64px' : '48px')};
      text-transform : ${(dangerousProps) => CSS.escape(dangerousProps.typography?.button?.case || 'inherit')};
    }
    .overline {
      color: ${(dangerousProps) =>
        '#' +
        CSS.escape(dangerousProps.typography?.overline?.color?.find((c) => c.color.color)?.color.color?.replace('#', '') || '000000')};
      font-size : ${(dangerousProps) => getSanitizedStyleInPixelsOrInherit(overlineSize, dangerousProps)};
      font-weight : ${overlineWeight};
      letter-spacing : ${(dangerousProps) =>
        dangerousProps.typography?.overline?.letter_spacing ? `${dangerousProps.typography?.overline?.letter_spacing}px` : 'inherit'};
      line-height : ${(dangerousProps) => getSanitizedStyleInPixelsOrInherit(overlineLineHeight, dangerousProps)};
      margin-bottom: ${(dangerousProps) => (dangerousProps.isMobile ? '64px' : '48px')};
      margin-top: ${(dangerousProps) => (dangerousProps.isMobile ? '64px' : '48px')};
      text-transform : ${(dangerousProps) => CSS.escape(dangerousProps.typography?.overline?.case || 'inherit')};
    }
    .primary-nav-item, .prime-navigation, .primary-navigation, .subsite-nav-title {
      color: ${(dangerousProps) =>
        '#' +
        CSS.escape(
          dangerousProps.typography?.prime_navigation?.color?.find((c) => c.color.color)?.color.color?.replace('#', '') || '000000'
        )};
      font-size : ${(dangerousProps) => getSanitizedStyleInPixelsOrInherit(primaryNavSize, dangerousProps)};
      font-weight : ${primaryNavWeight};
      letter-spacing : ${(dangerousProps) =>
        dangerousProps.typography?.prime_navigation?.letter_spacing
          ? `${dangerousProps.typography?.prime_navigation?.letter_spacing}px`
          : 'inherit'};
      line-height : ${(dangerousProps) => getSanitizedStyleInPixelsOrInherit(primaryNavLineHeight, dangerousProps)};
      margin-bottom: ${(dangerousProps) => (dangerousProps.isMobile ? '4px' : '0')};
      margin-top: ${(dangerousProps) => (dangerousProps.isMobile ? '4px' : '0')};
      text-transform : ${(dangerousProps) => CSS.escape(dangerousProps.typography?.prime_navigation?.case || 'inherit')};
    }
  }
`;

/**
 * Typography Style Component
 * Takes a typography style from our CMS and binds it globally to style our application
 * @param param0 typography: the typography theme content that we want to update our theme with
 */
const TypographyStyle: React.FC<TypographyStyleProps> = ({ typographyData }: TypographyStyleProps) => {
  return (
    <>
      <GlobalStyle typography={typographyData?.find((t) => t)} />
      <GlobalStyle isMobile={true} typography={typographyData?.find((t) => t)} />
      <GlobalStyle isTablet={true} typography={typographyData?.find((t) => t)} />
    </>
  );
};

export default TypographyStyle;
