import React, { useContext } from 'react';

import { UiStateContext } from '../shared/contexts/ui-state/UiStateContext';
import useTagManager from '../shared/hooks/tag-manager/useTagManager';
import { FeatureToggle, GlobalNavContent, IconPack } from '../shared/models/app-data-model';
import SearchBar from './search-bar/search-bar';
import SearchNav from './search-nav/search-nav';
import SearchResult from './search-result/search-result';
import './search.scss';

export type SearchProps = {
  featureToggles: FeatureToggle[];
  iconPack: IconPack[];
  globalNavContent: GlobalNavContent;
  noResultEntryUid: string;
};

const Search: React.FC<SearchProps> = ({ featureToggles, iconPack, globalNavContent, noResultEntryUid }: SearchProps) => {
  const uiStateContext = useContext(UiStateContext);

  useTagManager(
    'sh-site-search',
    {
      term: uiStateContext.searchQuery,
      type: uiStateContext.activeSearchTab,
      sortBy: uiStateContext.providerSort?.sortBy?.value,
      drFilters: uiStateContext.providerFilters,
      locFilters: uiStateContext.locationFilters
    },
    featureToggles
  );

  /* Trigger the Qualtrics Survey for Search */

  // We need to load the script every time we open the search so that we can trigger the survey when the users exit search
  window.QSI?.API.unload();
  window.QSI?.API.load();
  window.QSI?.API.run();

  /* end */

  return (
    <>
      <div className="search" id="search-container">
        <div className="search-header">
          <div className="search-bar-container">
            <SearchBar iconPack={iconPack} />
          </div>
          <SearchNav featureToggles={featureToggles} />
        </div>
        <div className="search-result-container">
          <SearchResult iconPack={iconPack} featureToggles={featureToggles} globalNavContent={globalNavContent} noResultEntryUid={noResultEntryUid} />
        </div>
      </div>
    </>
  );
};

export default Search;
