import React, { useCallback } from 'react';
import { useContext } from 'react';
import { Flex, Box } from 'rebass/styled-components';

import { layout } from '../../constants/constants';
import { UiStateContext } from '../../shared/contexts/ui-state/UiStateContext';
import { DeviceType } from '../../shared/hooks/use-breakpoint/device-type';
import useBreakpoint from '../../shared/hooks/use-breakpoint/useBreakpoint';
import useFeatureToggle from '../../shared/hooks/use-feature-toggle/useFeatureToggle';
import { FeatureToggle } from '../../shared/models/app-data-model';
import './search-nav.scss';

export enum NavTabs {
  all = 'All',
  doctors = 'Doctors',
  locations = 'Locations',
  pages = 'Pages'
}

export type SearchNavProps = {
  featureToggles: FeatureToggle[];
};

const SearchNav: React.FC<SearchNavProps> = ({ featureToggles }: SearchNavProps) => {
  const deviceType = useBreakpoint();
  const uiStateContext = useContext(UiStateContext);
  const showAllTab = useFeatureToggle('search-all-tab', featureToggles);
  const showLocations = useFeatureToggle('search-locations', featureToggles);
  const showPages = useFeatureToggle('search-pages', featureToggles);
  const showProviders = useFeatureToggle('search-providers', featureToggles);

  const handleActiveTab = useCallback(
    (tab: NavTabs): void => {
      uiStateContext.setActiveSearchTab?.(tab);
      uiStateContext.setProviderFilters(undefined);
      uiStateContext.setLocationFilters(undefined);
      uiStateContext.setZipLocation?.(undefined);
    },
    [uiStateContext]
  );

  const searchTabs = [];

  if (showAllTab) {
    searchTabs.push(NavTabs.all);
  }

  if (showProviders) {
    searchTabs.push(NavTabs.doctors);
  }

  if (showLocations) {
    searchTabs.push(NavTabs.locations);
  }

  if (showPages) {
    searchTabs.push(NavTabs.pages);
  }

  const navTabs = searchTabs.map((tab, index) => (
    <button
      key={index}
      className={`primary-nav-item search-nav-item unstyled-btn ${tab === uiStateContext.activeSearchTab ? 'open active-tab' : null}`}
      onClick={() => handleActiveTab(tab)}
    >
      {tab}
    </button>
  ));

  const desktop = (
    <Flex className="search-nav-container" width={1}>
      <Flex className="search-nav" width={layout.pageWidth} px={layout.searchPaddingX} flexWrap="no-wrap" mx="auto">
        <Box className="search-nav-title body-small" paddingRight={[3, 3, 5]}>
          <span>Search By Type</span>
        </Box>
        <nav className="search-nav-items">{navTabs}</nav>
      </Flex>
    </Flex>
  );

  const mobile = (
    <Flex className="search-nav-container" width={1}>
      <Flex className="search-nav" width={layout.pageWidth} px={layout.searchPaddingX} flexWrap="no-wrap" mx="auto">
        <Box className="search-nav-title body-small" paddingRight={[3, 3, 5]}>
          <span>Type:</span>
        </Box>
        <nav className="search-nav-items">{navTabs}</nav>
      </Flex>
    </Flex>
  );

  return deviceType === DeviceType.Desktop ? desktop : mobile;
};

export default SearchNav;
