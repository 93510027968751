import Contentstack from 'contentstack';

import { GlobalSettings, HomePageContent, PageContent } from '../../models/app-data-model';

// NOTE: exclude this from testing as it's just an 3rd party lib. test our code only.

export type ContentTypes =
  | 'spectrumhealth_org_homepage'
  | 'pages'
  | 'spectrumhealth_org_primary_header'
  | 'spectrumHealthOrgGlobalNav'
  | 'pages_migration_poc'
  | 'spectrumhealth_org_global_settings'
  | 'global_alerts_spectrumhealth_org'
  | 'global_search_no_results_spectrumhealth_org'
  | 'sh_global_toolbar';

const ContentStackApiService = {
  getContent(
    contentType: ContentTypes,
    entryUid?: string,
    referencedFields?: string[]
  ): Promise<HomePageContent | PageContent[] | GlobalSettings> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion

    // don't load the api client if we don't have what we need.
    if (!process.env.GATSBY_API_KEY || !process.env.GATSBY_DELIVERY_TOKEN || !process.env.GATSBY_ACTIVE_ENV) {
      return new Promise(() => undefined);
    }

    const stack = Contentstack.Stack(process.env.GATSBY_API_KEY, process.env.GATSBY_DELIVERY_TOKEN, process.env.GATSBY_ACTIVE_ENV);

    if (entryUid) {
      const query = stack.ContentType(contentType).Entry(entryUid);

      if (referencedFields) {
        query.includeReference(referencedFields);
      }

      return query.fetch().then(function success(entry) {
        return entry.toJSON() as HomePageContent;
      });
    } else {
      const query = stack.ContentType(contentType).Query();

      if (referencedFields) {
        query.includeReference(referencedFields);
      }

      return query
        .toJSON()
        .find()
        .then(function success(entries) {
          return entries[0] as PageContent[];
        });
    }
  }
};

export default ContentStackApiService;
