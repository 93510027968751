import React from "react";
import { Box, Flex } from "rebass/styled-components";

import { layout } from "../../../constants/constants";
import ContentColumn from "../../components/content-column/content-column";
import { FeatureToggle, IconPack, SingleColumnContent } from "../../models/app-data-model";
import "./single-column-block.scss";

export type ColumnContentProps = {
  column: SingleColumnContent;
  heroType?: string;
  iconPack: IconPack[];
  featureToggles: FeatureToggle[];
  campaignPageActive?: boolean;
  modularBlock?: boolean;
};

const SingleColumnBlock: React.FC<ColumnContentProps> = ({
  column,
  heroType,
  iconPack,
  featureToggles,
  campaignPageActive,
  modularBlock
}: ColumnContentProps) => {
  const backgroundColor = campaignPageActive && column?.campaign_background_color ? "campaign-" + column?.campaign_background_color : "";

  return (
    <div
      className={`${campaignPageActive ? "campaign-page-single-col" : ""} ${backgroundColor}`}
      data-testid={`${campaignPageActive ? "campaign-page-single-col_" : ""}${backgroundColor}`}
    >
      <Box
        marginX={"auto"}
        className={`single-col-block ${modularBlock && "modular-block-padding"}`}
        width={modularBlock ? layout.pageWidth : 1}
      >
        <ContentColumn column={column} heroType={heroType} iconPack={iconPack} featureToggles={featureToggles} />
      </Box>
    </div>
  );
};

export default SingleColumnBlock;
