import React from 'react';
import { Box, Flex } from 'rebass/styled-components';

import CardImage from '../../../shared/components/card-image/card-image';
import ShIcon from '../../../shared/components/sh-icon/sh-icon';
import { IconPack } from '../../../shared/models/app-data-model';
import { Location } from '../../../shared/services/kendra-api/kendra-api-service';
import './location-card-mobile.scss';

export type LocationCardProps = {
  location: Location;
  iconPack: IconPack[];
  elementId?: string;
  isKyruusData?: boolean;
  kyruusOriginCity?: string;
};

const LocationCardMobile: React.FC<LocationCardProps> = ({ location, iconPack, elementId, isKyruusData, kyruusOriginCity }: LocationCardProps) => {
  
  const defaultCardContent = (
    <>
      <Flex className="mobile-card-top" justifyContent="flex-start">
        <CardImage url={location.location_pic} alt={location.location_name} className={'location-image'} type={'location'} />
        <Flex className="info-container" flexDirection="column" ml={3} pt={2} pr={3}>
          <h6 className="location-name">{location.location_name}</h6>
          {location.location_hours !== 'null' && (
            <Flex>
              <Box mr={2}>
                <ShIcon iconPack={iconPack} iconName="clock" className="mobile-icon" />
              </Box>
              <span className="location-hours">{location.location_hours}</span>
            </Flex>
          )}
          <Flex className="location-ratings" alignItems="center" width={1}>
            {location.location_rating !== 'null' && (
              <>
                <ShIcon iconPack={iconPack} iconName="star" className="star" />
                <span className="rating">{location.location_rating}</span>
                <span className="reviews">({location.location_reviews})</span>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
      {location.street_address !== 'null' && (
        <Flex className="bottom-row" flexDirection="row" justifyContent="space-between" p={3} width={1}>
          <Flex className="location-address" flexDirection="row">
            <ShIcon iconPack={iconPack} iconName="map_pin" className="mobile-icon" />
            <span className="body-small secondary-text address">
              {location.street_address +
                (location.city !== 'null' && location.state !== 'null' && location.zip_code !== 'null'
                  ? `, ${location.city}, ${location.state} ${location.zip_code}`
                  : '')}
            </span>
          </Flex>
          <Flex>{location.distance !== 'null' && <span className="body-small secondary-text">{location.distance} miles away</span>}</Flex>
        </Flex>
      )}
    </>
  );

  const kyruusLocationConent = (
    <>
      <Flex className="mobile-card-top" justifyContent="flex-start">
        <CardImage url={location.location_pic} alt={location.location_name} className={'location-image'} type={'location'} />
        <Flex className="info-container kyruus-info-container" justifyContent="flex-center" ml={3} pt={2} pr={3}>
          <h6 className="location-name kyruus-loc-name">{location.location_name}</h6>
        </Flex>
      </Flex>

      {location.street_address !== 'null' && (
        <Flex className="bottom-row " flexDirection="column" justifyContent="space-between" p={3} width={1}>
          <Flex className="location-address" flexDirection="row">
            <ShIcon iconPack={iconPack} iconName="map_pin" className="mobile-icon" />
            <span className="body-small kyruus-mobile-secondary-text">
              {location.street_address +
                (location.city !== 'null' && location.state !== 'null' && location.zip_code !== 'null'
                  ? `, ${location.city}, ${location.state} ${location.zip_code}`
                  : '')}
            </span>
          </Flex>
          {location?.distance && location.distance !== 'null' && (
            <>
            <Flex flexDirection="row">
              <ShIcon iconPack={iconPack} iconName="direction" className="mobile-icon direction-icon" /> 
              <span className="body-small kyruus-mobile-secondary-text">
                {location.distance} miles from {kyruusOriginCity ? kyruusOriginCity : "Your Location"}
              </span>  
            </Flex>
            </>
          )}
          
        </Flex>
      )}
    </>
  );

  return (
    <a
      href={location._source_uri}
      className="no-decoration results-card"
      id={elementId}
      target="_blank"
      rel="noopener noreferrer"
      data-kendra-token={location.feedback_token}
    >
      <Flex alignItems="center" marginY={2} className={`location-card ${isKyruusData ? "kyruus-location-card" : ""}`} flexDirection="column">
        {isKyruusData ? kyruusLocationConent : defaultCardContent }
      </Flex>
    </a>
  );
};

export default LocationCardMobile;
