import React from 'react';

import './checkbox.scss';

export type CheckboxProps = {
  uniqueId: string;
  label: string;
  checked: boolean | undefined;
  onChangeFunction: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const Checkbox: React.FC<CheckboxProps> = (content: CheckboxProps) => {
  const { uniqueId, label, checked, onChangeFunction } = content;
  return (
    <div className="checkbox-container">
      <input
        id={uniqueId}
        className="checkbox-input"
        type="checkbox"
        checked={checked}
        onChange={onChangeFunction}
        data-testid={uniqueId}
      />
      <label className="cb-label" htmlFor={uniqueId}>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
