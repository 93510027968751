/* eslint-disable max-len */
import React from 'react';

import { MediaContent } from '../../models/app-data-model';
import './media.scss';

export type MediaContentProps = {
  media: MediaContent;
  index?: number;
};

const Media: React.FC<MediaContentProps> = ({ media, index }: MediaContentProps) => {
  const displayType = media.display_type?.toLowerCase().replace(' ', '-') ?? '';
  const horizontalAlignment = media.horizontal_alignment?.toLowerCase() ?? '';

  if (media.asset?.content_type?.includes('video')) {
    return (
      <video className={`media ${displayType} ${horizontalAlignment}`}>
        <source src={media.asset?.url} type={media.asset.content_type} title={media.alt_text} key={index} />
      </video>
    );
  }

  return <img src={media.asset?.url} alt={media.alt_text} className={`media ${displayType} ${horizontalAlignment}`} key={index} />;
};

export default Media;
