import { globalHistory } from "@gatsbyjs/reach-router";
import React, { useContext, useEffect, useState } from "react";

import { heroType as heroTypeConstant } from "../../../constants/constants";
import { AppDataContext } from "../../contexts/app-data/AppDataContext";
import { FeatureToggle, IconPack, SingleColumnContent } from "../../models/app-data-model";
import ButtonWrapper from "../button-wrapper/button-wrapper";
import Media from "../media/media";
import ShList from "../sh-list/sh-list";
import "./content-column.scss";

export type ColumnContentProps = {
  column: SingleColumnContent;
  isTwoColumn?: boolean;
  heroType?: string;
  iconPack: IconPack[];
  featureToggles: FeatureToggle[];
};

const ContentColumn: React.FC<ColumnContentProps> = ({ column, isTwoColumn, heroType, iconPack, featureToggles }: ColumnContentProps) => {
  /* istanbul ignore next */
  const columnClass = `column ${column?.text_alignment?.toLowerCase()}-align ${heroType === heroTypeConstant.inset ? "inset" : ""}`;
  const [linkId, setLinkId] = useState("");
  const extraMarginClass = !!useContext(AppDataContext).subsiteData?.subsite_nav ? "with-subsite-nav" : "";
  // SCRUMBLE-1117 removed unnecessary js scroll useeffect. originally implemented to prevent element scrolling out of view in safari and firefox.

  const element = column?.content?.map((el, index) => {
    if (el.heading?.heading_type) {
      if (el.heading.link_id && linkId !== el.heading.link_id) {
        setLinkId(el.heading.link_id);
      }
      return React.createElement(
        el.heading?.heading_type.toLowerCase(),
        { key: index, className: `col-title`, id: el.heading.link_id },
        el.heading?.text
      );
    } else if (el.rich_text?.content) {
      return <div className="rich-text" dangerouslySetInnerHTML={{ __html: el.rich_text?.content }} key={index}></div>;
    } else if (el.media) {
      return <Media media={el.media} index={index} key={index} />;
    } else if (el.button) {
      return (
        <ButtonWrapper
          key={index}
          type={el.button.button_type}
          label={el.button.text}
          noMargin={el.button.button_type === "tertiary" ? true : false}
          noPadding={el.button.button_type === "tertiary" ? true : false}
          fullWidth={column?.text_alignment?.toLowerCase() === "center" ? false : true}
          reference={el.button.reference}
          iconPack={iconPack}
        />
      );
    } else if (el.list) {
      return <ShList content={el.list} featureToggles={featureToggles} />;
    }
  });

  return <div className={`${columnClass} scroll-margin-top ${extraMarginClass}`}>{element}</div>;
};

export default ContentColumn;
