import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Flex } from 'rebass/styled-components';

import ShIcon from '../../shared/components/sh-icon/sh-icon';
import TextInputContainer from '../../shared/components/text-input-container/text-input-container';
import { UiStateContext } from '../../shared/contexts/ui-state/UiStateContext';
import useLocationApi from '../../shared/hooks/location-api/useLocationApi';
import { IconPack, LocationPrompt, ToolIcon } from '../../shared/models/app-data-model';
import { LocationApiProps, SuggestedLocation, Place, LocationObject } from '../../shared/services/location-api/location-api-service';
import './set-location.scss';
import stateAbbr from './state-abbreviations.json';

export type SetLocationProps = {
  content: LocationPrompt;
  icon: ToolIcon;
  iconPack: IconPack[];
};

export const getLocationDisplayTextFromObj = (locObj: Place) => {
  const { Municipality: mun, Region: reg, PostalCode: zip } = locObj;
  return `${mun ? mun + ',' : ''}${reg ? ' ' + reg : ''}${zip ? ' ' + zip : ''}`;
};

const SetLocation: React.FC<SetLocationProps> = ({ content, icon, iconPack }: SetLocationProps) => {
  const uiStateContext = useContext(UiStateContext);

  const [userInput, setuserInput] = useState<string>('');
  const [userLatLong, setUserLatLong] = useState({latitude: '', longitude: ''});
  const [userPlaceID, setUserPlaceID] = useState('');
  const [locationPlaceApi] = useLocationApi({ placeId: userPlaceID } as LocationApiProps);
  const [locationSuggestionsApi] = useLocationApi({text: userInput} as LocationApiProps)
  const [locationLatLongApi] = useLocationApi({ latitude: userLatLong.latitude, longitude: userLatLong.longitude } as LocationApiProps);
  const [suggestedResultsList, setSuggestedResultsList] = useState(new Array<SuggestedLocation>());
  const [useCurrentLocation, setUseCurrentLocation] = useState(true);
  const [hasError, setHasError] = useState(false); 
  const [noResults, setNoResults] = useState(false); 

  const closeSetLocation = () => {
    const toolItem = document?.getElementById('set-location-tool-item');
    // To close the entire dropdown, we have to shift the focus to the parent element first, then use blur to
    // close the global tool after the user has selected their location
    toolItem?.focus();
    toolItem?.blur();
  };

  useEffect(() => {
    // populate suggested locations list
    if (userInput.length > 2) {
      setSuggestedResultsList(locationSuggestionsApi.response as Array<SuggestedLocation>);
      locationSuggestionsApi.hasError ? setHasError(true) : setHasError(false);
      locationSuggestionsApi.response?.length > 0 ? setNoResults(false) : setSuggestedResultsList([]);
    } else {
      setSuggestedResultsList([]);
   }

  }, [userInput, locationSuggestionsApi]);

  useEffect(() => {
    // set user Region if it exists in local storage
    const localCurrentLocation = window?.localStorage?.getItem('userCurrentLocation');

    if (localCurrentLocation) {
      const localCurrentLocationObj = JSON.parse(localCurrentLocation);
      const userLocation = localCurrentLocationObj?.userRegion ? localCurrentLocationObj.userRegion : 'system';

      if(uiStateContext.userRegion !== userLocation) {
        uiStateContext.setUserRegion?.(userLocation);
      }
    }
  }, [uiStateContext.userRegion]);

  useEffect(() => {
    const placeObj = locationPlaceApi?.response as any;
    const locationObj = locationLatLongApi?.response[0] as LocationObject;

    locationPlaceApi.hasError || locationLatLongApi.hasError ? setHasError(true) : setHasError(false);

    // request the correct API response based on how user is setting location
    if(!locationPlaceApi?.isRunning && userPlaceID && placeObj?.Municipality){
      const truncatedPlace = truncateLocation(placeObj)
      locationDataSet(truncatedPlace);
    }

    if(userLatLong.latitude && userLatLong.longitude && locationObj?.Place?.Municipality){
      const truncatedLocation = truncateLocation(locationObj?.Place)
      locationDataSet(truncatedLocation);      
    }

  }, [userPlaceID, locationPlaceApi, userLatLong, locationLatLongApi]);

  useEffect(() => {
    // check if we can ask the user for their current location
    askUserForGeoLocationPermission();
  }, [setUseCurrentLocation]);

  const askUserForGeoLocationPermission = () => {
    if ('permissions' in navigator) {
      navigator.permissions.query({ name: 'geolocation' })?.then((permission) => {
        permission.state === 'denied' ? setUseCurrentLocation(false) : setUseCurrentLocation(true);
      });
    } else {
      // if we cannot read the permissions then don't ask users for their location
      setUseCurrentLocation(false);
    }
  };

  const truncateLocation = (loc: any) => {
    const locationData = loc;
    const states = stateAbbr;

    if (locationData?.PostalCode) {
      if (locationData?.PostalCode.includes(' ')) {
        const zipTruncate = locationData.PostalCode.split(' ');
        locationData.PostalCode = zipTruncate[0];
      }
    }

    if (locationData?.Region) {
      const normalizedStateName = locationData?.Region.trim().toLowerCase();
      locationData.Region = states[normalizedStateName] || locationData.Region;
    }

    return locationData;
  };

  const locationDataSet = (apiData: Place) => {
    // format display text
    const locationText = getLocationDisplayTextFromObj(apiData);
    // Set Local Storage
    window?.localStorage?.setItem('userCurrentLocation', JSON.stringify(apiData));

    // Check how we can set region
    if (apiData?.PostalCode) {
      // if there is a zip code try to set user region with zip
      setUserRegion(apiData.PostalCode);
    } else if(apiData?.SubRegion) {
      // if there isn't a zip use the county (subregion to set )
      setUserRegion(apiData.SubRegion)
    }

    // Set Display Text
    uiStateContext.setUserLocationText?.(locationText);  
    
    // if the location was set with a place ID reset input and close dropdown
    if (userPlaceID && !hasError) {
      setuserInput("");
      closeSetLocation();
    }
  }

  const setUserRegion = (regionKey: string) => {
    let userRegion = 'system';
    const userRegionKey = regionKey;

    // Check the zip list for the user's current zip
    for (let i = 0; i < content.reference.length; i++) {
      const region = content.reference[i];
      // if region key includes "county" check the county list
      if (regionKey.includes('County')) {
        if (region.county.includes(userRegionKey)) {
          userRegion = region.region_id;
        }
      } else {
        if (region.zip_postal_code.includes(userRegionKey)) {
          userRegion = region.region_id;
        }
      }
    }

    // Set the UIstate context for userRegion
    if (uiStateContext.userRegion != userRegion) {
      uiStateContext.setUserRegion?.(userRegion);
    }

    // Update local storage to contain userRegion
    let currentLocationObj = window?.localStorage?.getItem('userCurrentLocation');
    if (currentLocationObj) {
      currentLocationObj = JSON.parse(currentLocationObj);
      localStorage.setItem('userCurrentLocation', JSON.stringify({ ...currentLocationObj, userRegion: userRegion }));
    }
  };

  const handleSetLocationChange = useCallback(
    (input?) => {
      setuserInput(input);
    },
    [setuserInput]
  );

  const onInputClickFunction = useCallback(
    (event) => {
      // reset states so the text search can return a value
      if (userPlaceID) {
        setUserPlaceID('');
      }

      if(userLatLong.longitude || userLatLong.latitude){
        setUserLatLong({longitude: '', latitude: ''});
      }
    },
    [userPlaceID, setUserPlaceID, userLatLong, setUserLatLong]
  );

  const handleUnsetUserLocation = () => {
    // prevent window from closing before unsetting all the things
    const toolItem = document?.getElementById('set-location-input');
    toolItem?.focus();

    // clear local storage
    window.localStorage.removeItem('userCurrentLocation');

    if(userInput) {
      setuserInput("");
    }

    if(userLatLong.longitude || userLatLong.latitude){
      setUserLatLong({longitude: '', latitude: ''});
    }

    if (userPlaceID) {
      setUserPlaceID('');
    }

    uiStateContext.setUserRegion?.("system");
    uiStateContext.setUserLocationText?.("");
  };

  const getCurrentLocation = useCallback(() => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // User allowed access and position was successfully retrieved
          const { latitude, longitude } = position.coords;
          if (latitude && longitude) {
            if (userPlaceID) {
              setUserPlaceID('');
            }

            setUserLatLong({longitude: longitude.toString(), latitude: latitude.toString()});
          }
        },
        /* istanbul ignore next */
        (error) => {
          // User denied access or an error occurred while retrieving the position
          if (error.code === error.PERMISSION_DENIED) {
            console.error('User denied the geolocation permission.');
          } else {
            console.error('Error occurred while retrieving the position:', error.message);
          }
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
    if (!hasError) {      
      closeSetLocation();
      setuserInput('');
      setNoResults(false);     
    }
  }, [setUserLatLong, userPlaceID, setUserPlaceID]);

  // save user location based on user selection from the suggested results
  const setUserLocation = useCallback((item) => {
    setUserPlaceID(item.PlaceId);
    // get placeId
    if (!hasError) {
      closeSetLocation();
    }
  }, []);

  // when users click enter, set the user location to be the first location on the suggested results list
  const selectUserLocation = useCallback(
    (event) => {

      // event.key is required for Android
      const isEnterKey = event.code === 'Enter' || event.key === 'Enter';
      if (isEnterKey && suggestedResultsList.length > 0) {
        // Enter key pressed and suggested results are available
        setNoResults(false);
        setUserPlaceID(suggestedResultsList[0]?.PlaceId);
        setuserInput('');
        closeSetLocation();
      } else if (isEnterKey && userInput) {   
        // Enter key pressed while input has a value and suggestedResultsList is currently equal to 0
        setNoResults(true);
      } else {
        // Clear errors on keydown when not Enter key press (clears error when user types)
        setNoResults(false);
        setHasError(false);
      }
    },
    [suggestedResultsList]
  );

  const suggestedResults = !locationSuggestionsApi.hasError && suggestedResultsList.length > 0 && (
    <div data-testid="suggested-results" className="suggested-results">
      {suggestedResultsList.map((item, index) => (
        <button data-testid={index} key={index} className="suggested-result unstyled-btn" onClick={() => setUserLocation(item)}>
          <span className="suggested-result-text" data-testid={`suggested-result-${index}`}>{item.Text}</span>
        </button>
      ))}
    </div>
  );

  const noResultsMessage = noResults && (
    <div data-testid="no-suggested-results" className="suggested-results error">
      <span>No results found.</span>
    </div>
  );

  const hasErrorMessage = hasError && (
    <div data-testid="no-suggested-results" className="suggested-results error">
      <span>There has been an error with the API request.</span>
      <span>Please try again later.</span>
    </div>
  );

  return (
    <>
      <div id="set-location-container" className="set-location-container" data-testid="set-location-container">
        <div className="location-input-container dropdown-shadow">
          <Flex className="location-input-heading">
            <span className="instruction">{content.instruction_text}</span>
            <button className="unstyled-btn location-input-close-btn" onClick={() => closeSetLocation()}>
              <ShIcon iconPack={iconPack} className="location-input-close-icon" iconName="close" />
            </button>
          </Flex>
          <TextInputContainer
            iconPack={iconPack}
            showIcon={window?.localStorage?.getItem('userCurrentLocation') ? true : false}
            data-testid="set-location-input"
            id="set-location-input"
            label={content.placeholder_value}
            isRequired={false}
            type="text"
            errorMessage=""
            maxLength={50}
            formControl={{ value: uiStateContext?.userLocationText || userInput, valid: !hasError && !noResults }}
            onChangeFunction={(input) => {
              // preventing additional api calls for suggested results if the user has already selected the location
              // after the user clears the location, then the suggested results are displayed
              !uiStateContext?.userLocationText && handleSetLocationChange(input.value);
            }}
            onClickFunction={(event) => {
              onInputClickFunction(event);
            }}
            onKeyDownFunction={(event) => {
              selectUserLocation(event);
            }}
            onClearInputFunction={handleUnsetUserLocation}
          />
        </div>
        <div data-testid="set-location-input-dropdown" className="location-dropdown-container dropdown-shadow">
          {useCurrentLocation && (
            <button data-testid="use-current-location" className="unstyled-btn current-location" onClick={getCurrentLocation}>
              <img src={icon?.icon_source?.url} alt={icon?.alt_text} className="current-location-icon"></img>
              <span className="current-location-text">{content.current_location_verbiage}</span>
            </button>
          )}
          {!uiStateContext?.userLocationText && suggestedResults}
          {noResultsMessage}
          {hasErrorMessage}
        </div>
      </div>
    </>
  );
};

export default SetLocation;
