import React from "react";
import { Box } from "rebass/styled-components";

import { layout } from "../../../constants/constants";
import { ButtonContent, IconPack } from "../../models/app-data-model";
import ButtonWrapper from "../button-wrapper/button-wrapper";
import "./card-grid-container-mobile.scss";

export type CardGridContainerMobileProps = {
  tertiaryButton?: ButtonContent;
  showTertiaryButton?: boolean;
  children?: React.ReactNode;
  iconPack: IconPack[];
};

/**
 * This component is meant to be used as a container for the Content Card Grid, Location Card Grid, and Provider Card Grid on mobile
 * @param the card grid container component props
 * @returns the card grid container component for mobile.
 */

const CardGridContainerMobile: React.FC<CardGridContainerMobileProps> = ({
  tertiaryButton,
  showTertiaryButton,
  children,
  iconPack
}: CardGridContainerMobileProps) => {
  const button =
    tertiaryButton?.text !== "" && tertiaryButton?.reference?.length ? (
      <ButtonWrapper
        iconPack={iconPack}
        type="tertiary"
        label={tertiaryButton?.text}
        styleOverride="transparent-bg"
        reference={tertiaryButton?.reference}
      />
    ) : null;

  return (
    <Box className="card-grid-container-mobile" width={layout.singleColWidth} mb={96}>
      <Box className="grid-content" width={[1, 1, 1, 1, 10 / 12]} px={[4, 4, 4, 4, "auto"]}>
        {children}
        {tertiaryButton && showTertiaryButton && (
          <span data-testid="optional-tertiary-button" className="grid-bottom-button">
            {button}
          </span>
        )}
      </Box>
    </Box>
  );
};

export default CardGridContainerMobile;
