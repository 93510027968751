import React, { useContext } from 'react';
import { Box } from 'rebass/styled-components';

import { inputBoxWidth } from '../../../constants/constants';
import Checkbox from '../../../shared/components/checkbox/checkbox';
import SelectInputContainer from '../../../shared/components/select-input-container/select-input-container';
import TextInputContainer from '../../../shared/components/text-input-container/text-input-container';
import { UiStateContext } from '../../../shared/contexts/ui-state/UiStateContext';
import { SearchProviderFilters, SearchProviderSort } from '../../../shared/hooks/search-providers-sort/useSortProviderResults';
import { DeviceType } from '../../../shared/hooks/use-breakpoint/device-type';
import useBreakpoint from '../../../shared/hooks/use-breakpoint/useBreakpoint';
import { ZipDistance } from '../../../shared/hooks/zip-distance/useZipDistance';
import { SelectInput } from '../../../shared/models/app-data-model';
import { sortByOptions, insuranceCarrierOptions, genderOptions, languageOptions } from '../../constants/constants';
import './provider-filter.scss';

export type ProviderFilterProps = {
  embeddedSearch?: string;
  onFilterSelectChange: (searchFilter?: SearchProviderFilters) => void;
  onSortSelectChange: (searchSort?: SearchProviderSort) => void;
  onZipSelectChange: (searchZip?: ZipDistance) => void;
};

const ProviderFilter: React.FC<ProviderFilterProps> = ({
  embeddedSearch,
  onFilterSelectChange,
  onSortSelectChange,
  onZipSelectChange
}: ProviderFilterProps) => {
  const uiStateContext = useContext(UiStateContext);
  const providerSort = uiStateContext.providerSort;
  const filters = uiStateContext.providerFilters;
  const zip = uiStateContext.zipLocation;
  const deviceType = useBreakpoint();
  const embeddedSearchOptions = [{ value: embeddedSearch, label: embeddedSearch } as SelectInput];

  const embeddedSearchSpecialty = (): any => {
    if (embeddedSearch && embeddedSearch.length) {
      return (
        <Box className="specialty-box" width={deviceType === DeviceType.Desktop ? inputBoxWidth.desktop : inputBoxWidth.mobile} my={[1, 2]}>
          <SelectInputContainer
            options={embeddedSearchOptions}
            placeholder={'Specialty'}
            onChangeFunction={(selectedOption) => {
              onFilterSelectChange({ ...filters, specialty: selectedOption } as unknown as SearchProviderFilters);
            }}
            isDisabled={true}
            selectedValue={embeddedSearchOptions}
          />
        </Box>
      );
    }
  };

  return (
    <>
      {embeddedSearchSpecialty()}
      <Box width={deviceType === DeviceType.Desktop ? inputBoxWidth.desktop : inputBoxWidth.mobile} my={[1, 2]}>
        <SelectInputContainer
          options={sortByOptions}
          placeholder={'Sort results by'}
          onChangeFunction={(selectedOption) => {
            onSortSelectChange({ sortBy: selectedOption });
          }}
          selectedValue={providerSort?.sortBy}
          data-testid="sort-results-by"
        />
      </Box>
      <Box width={deviceType === DeviceType.Desktop ? inputBoxWidth.desktop : inputBoxWidth.mobile} my={[1, 2]}>
        <SelectInputContainer
          options={insuranceCarrierOptions}
          placeholder={'Insurance Carrier'}
          onChangeFunction={(selectedOption) => {
            onFilterSelectChange({ ...filters, insurance: selectedOption } as unknown as SearchProviderFilters);
          }}
          selectedValue={filters?.insurance}
        />
      </Box>
      <Box width={deviceType === DeviceType.Desktop ? inputBoxWidth.desktop : inputBoxWidth.mobile} my={[1, 2]}>
        <SelectInputContainer
          options={genderOptions}
          placeholder={'Gender'}
          onChangeFunction={(selectedOption) => {
            onFilterSelectChange({ ...filters, gender: selectedOption } as unknown as SearchProviderFilters);
          }}
          selectedValue={filters?.gender}
        />
      </Box>
      <Box width={deviceType === DeviceType.Desktop ? inputBoxWidth.desktop : inputBoxWidth.mobile} my={[1, 2]}>
        <SelectInputContainer
          options={languageOptions}
          placeholder={'Language'}
          onChangeFunction={(selectedOption) => {
            onFilterSelectChange({ ...filters, language: selectedOption } as unknown as SearchProviderFilters);
          }}
          selectedValue={filters?.language}
        />
      </Box>
      <Box
        className="zip-code-box"
        width={deviceType === DeviceType.Desktop ? inputBoxWidth.desktop : inputBoxWidth.mobile}
        my={[1, 2]}
        marginBottom={[1, 4]}
        height="60px"
      >
        <TextInputContainer
          id="zip-code-input"
          label="ZIP Code"
          isRequired={false}
          type="number"
          errorMessage="Invalid MI ZIP Code"
          maxLength={5}
          formControl={zip?.zip || { value: '', valid: true }}
          onChangeFunction={(input) => {
            onZipSelectChange({
              radius: 200,
              zip: input.value ? { value: input.value, valid: !input.validity.patternMismatch } : undefined
            } as ZipDistance);
          }}
        />
      </Box>

      <Box width={deviceType === DeviceType.Desktop ? inputBoxWidth.desktop : inputBoxWidth.mobile}>
        <Checkbox
          uniqueId="accepting-new-patients"
          label="Accepting new patients"
          checked={filters?.acceptingNewPatients === 'yes'}
          onChangeFunction={(isSelected) => {
            onFilterSelectChange({
              ...filters,
              acceptingNewPatients: isSelected?.target?.checked ? 'yes' : undefined
            } as unknown as SearchProviderFilters);
          }}
        />
      </Box>
      <Box width={deviceType === DeviceType.Desktop ? inputBoxWidth.desktop : inputBoxWidth.mobile}>
        <Checkbox
          uniqueId="online-scheduling"
          label="Offers online scheduling"
          checked={filters?.onlineScheduling}
          onChangeFunction={(isSelected) => {
            onFilterSelectChange({
              ...filters,
              onlineScheduling: isSelected?.target?.checked ? true : undefined
            } as unknown as SearchProviderFilters);
          }}
        />
      </Box>
    </>
  );
};

export default ProviderFilter;
