import React, { useContext, useEffect, useState } from 'react';
import { Box, Flex } from 'rebass/styled-components';

import { UiStateContext } from '../../contexts/ui-state/UiStateContext';
import { IconPack, Provider } from '../../models/app-data-model';
import CardImage from '../card-image/card-image';
import ShIcon from '../sh-icon/sh-icon';
import './provider-card-grid-card.scss';

export type ProviderCardProps = {
  provider: Provider;
  cardWidth: number;
  iconPack: IconPack[];
};

const ProviderCardGridCard: React.FC<ProviderCardProps> = ({ provider, cardWidth, iconPack }: ProviderCardProps) => {
  const [width, setWidth] = useState<'full-width' | 'half-width' | 'third-width'>();
  const uiStateContext = useContext(UiStateContext);

  useEffect(() => {
    setWidth(cardWidth === 3 ? 'third-width' : cardWidth === 2 ? 'half-width' : 'full-width');
  }, [cardWidth]);

  let locationContent = <></>;
  if (provider?.location) {
    const { street_address, state, city, zip_code } = provider.location.address;
    if (street_address) {
      locationContent = (
        <>
          <Box width={'100%'} className="location-box">
            <Flex>
              <Box paddingRight={2}>
                <ShIcon iconPack={iconPack} iconName="map_pin" />
              </Box>
              <span
                className={width + ' address body-small secondary-text ellipses-overflow'}
              >{`${street_address}, ${city}, ${state} ${zip_code}`}</span>
            </Flex>
          </Box>
          <Box width={'100%'} className="location-container ellipses-overflow" justifyContent={width === 'third-width' ? 'left' : 'end'}>
            <span className={width + ' location-info body-small'}>
              <strong>{provider.distance}</strong> miles away from{' '}
              <span className="address">{uiStateContext?.browserLocation?.latitude === 0 ? 'Grand Rapids' : 'Your Location'}</span>
            </span>
          </Box>
        </>
      );
    }
  }

  return (
    <a
      href={provider?.website?.href}
      target="_blank"
      rel="noopener noreferrer"
      className={`grid-card provider-grid-card ${width}`}
      data-testid={`card-width-${cardWidth}`}
    >
      <Flex justifyContent="flex-start" className={`${width} provider-card-grid-card`} alignItems="center">
        <Box margin={3}>
          <div className="provider-pic">
            <div
              className={provider?.spectrum_employed ? 'circular-border' : ''}
              data-testid={`spectrumEmployed-${provider?.spectrum_employed}`}
            >
              <CardImage url={provider?.photo?.href} alt={provider?.title} className={'profile-pic'} type={'provider'} />
            </div>
          </div>
        </Box>

        <Flex className={width + ' info-container'} flexDirection="column" ml={3} mr={2} flexGrow={1}>
          {width !== 'full-width' && (
            <Box className="provider-rating-review-box">
              {provider?.rating > 0 && (
                <Flex flexDirection="row" justifyContent={'center'}>
                  <Box paddingRight={2}>
                    <ShIcon iconPack={iconPack} className="star-icon" iconName="star" />
                  </Box>
                  <span className="overline rating secondary-text">{provider?.rating}</span>
                  {provider?.reviews && <span className="reviews">({provider?.reviews})</span>}
                </Flex>
              )}
            </Box>
          )}
          <Flex justifyContent="space-between" alignItems="left" flexDirection="row" className="provider-info">
            <Box m={cardWidth === 3 ? '0 auto' : ''} width={'100%'} className="provider-name-box">
              <span className="h6">{provider?.title}</span>
            </Box>
            {width === 'full-width' && (
              <Box className="provider-rating-review-box">
                {provider?.rating > 0 && (
                  <Flex flexDirection="row" data-testid="providerRating">
                    <Box paddingRight={3}>
                      <ShIcon iconPack={iconPack} className="star-icon" iconName="star" />
                    </Box>
                    <span className="overline rating secondary-text">{provider?.rating}</span>
                    {provider?.reviews && <span className="reviews">({provider?.reviews})</span>}
                  </Flex>
                )}
              </Box>
            )}
          </Flex>
          <Flex className={cardWidth ? `body ${width} provider-info` : 'body provider-info'} my={0} flexDirection="row">
            <Box className="provider-specialty secondary-text">
              {provider?.primary_specialty && provider?.primary_specialty !== 'null' && (
                <span className="body-small">{provider?.primary_specialty}</span>
              )}
            </Box>
            <Box width={cardWidth === 3 ? '100%' : 'auto'} className="new-patients-box">
              <Flex className={width ? 'left-align' : ''}>
                <Box paddingRight={2} data-testid={`newPatients-${provider?.new_patients}`}>
                  {provider?.new_patients ? (
                    <ShIcon iconPack={iconPack} className="check-icon" iconName="check" />
                  ) : (
                    <ShIcon iconPack={iconPack} className="close-icon" iconName="bold_close" />
                  )}
                </Box>
                <span className="body-small secondary-text ellipses-overflow">
                  {provider?.new_patients ? `Accepting new patients` : `Not accepting new patients`}
                </span>
              </Flex>
            </Box>
          </Flex>

          <Flex className={`bottom-area ${width} provider-info`}>{locationContent}</Flex>
        </Flex>
      </Flex>
    </a>
  );
};

export default ProviderCardGridCard;
