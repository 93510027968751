import React, { useCallback, useEffect, useState } from 'react';
import { useContext } from 'react';
import { Flex } from 'rebass/styled-components';

import { layout } from '../../constants/constants';
import ShIcon from '../../shared/components/sh-icon/sh-icon';
import { UiStateContext } from '../../shared/contexts/ui-state/UiStateContext';
import useIsMobile from '../../shared/hooks/is-mobile/useIsMobile';
import { IconPack } from '../../shared/models/app-data-model';
import { NavTabs } from '../search-nav/search-nav';
import './search-bar.scss';

export type SearchBarProps = {
  iconPack: IconPack[];
};

const SearchBar: React.FC<SearchBarProps> = ({ iconPack }: SearchBarProps) => {
  const uiStateContext = useContext(UiStateContext);
  const isMobile = useIsMobile();
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (searchTerm?.length > 2) {
      const delayDebounceFn = setTimeout(() => {
        uiStateContext.setSearchQuery?.(searchTerm);
      }, 500);

      /* istanbul ignore next */
      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchTerm, uiStateContext, uiStateContext.setSearchQuery]);

  useEffect(() => {
    setSearchTerm(uiStateContext.searchQuery);
  }, [uiStateContext.searchQuery]);

  const closeSearch = useCallback(() => {
    uiStateContext.setSearchOpen?.(false);
    uiStateContext.setSearchQuery?.('');
    uiStateContext.setActiveSearchTab?.(NavTabs.all);
    window?.scrollTo(0, 0);
  }, [uiStateContext.setActiveSearchTab, uiStateContext.setSearchOpen, uiStateContext.setSearchQuery]);

  return (
    <Flex width={layout.pageWidth} px={layout.searchPaddingX} alignItems="center" className="search-bar" mx="auto">
      <Flex className="search-input-container" data-testid="search-input" alignItems="center" flexGrow="1">
        <ShIcon iconPack={iconPack} iconName="search" className="icon search-icon" />
        <input
          autoFocus
          /* istanbul ignore else */
          onChange={(event) => setSearchTerm(event.target.value)}
          value={searchTerm}
          placeholder={isMobile ? 'Enter search terms here' : 'Search by specialty, procedure, or doctor name...'}
        />
      </Flex>

      <button
        id="search-close-icon"
        className="unstyled-btn outline-on-focus"
        data-testid="close-icon"
        aria-label="Close search"
        onClick={() => closeSearch()}
      >
        <ShIcon iconPack={iconPack} iconName="close" className="icon search-close-icon" />
      </button>
    </Flex>
  );
};

export default SearchBar;
