import { useState, useEffect, useContext } from 'react';

import { UiStateContext } from '../../contexts/ui-state/UiStateContext';
import { FormControl, SelectInput } from '../../models/app-data-model';
import { Location } from '../../services/kendra-api/kendra-api-service';
import useZipDistance from '../zip-distance/useZipDistance';

export type SearchLocationFilters = {
  zipcode: FormControl | undefined;
  isEmergency: boolean | undefined;
  isWalkin: boolean | undefined;
  isUrgentCare: boolean | undefined;
};

export type SearchLocationSort = {
  sortBy: SelectInput | undefined;
};

const useSortLocationResults = (locations: Array<Location>): Array<Location> => {
  const [sortedResults, setSortedResults] = useState([] as Location[]);
  const uiStateContext = useContext(UiStateContext);
  const zipDistance = useZipDistance(locations);

  useEffect(() => {
    let sortedLocations = [...locations];

    const sortByProximity = (): void => {
      sortedLocations = sortedLocations
        .filter((x) => x.distance && x.distance !== 'null')
        .sort((a, b) => {
          // equal items sort equally
          if (+a.distance === +b.distance) {
            return 0;
          }
          return +a.distance < +b.distance ? -1 : 1;
        });
    };

    // We need to do sorting on the client side because the total nr of results doesn't change on sorting, hence no need for an api call
    if (uiStateContext?.zipLocation?.zip?.value?.length === 5 && uiStateContext?.zipLocation?.zip?.valid) {
      sortedLocations = zipDistance;
      sortByProximity();
    }

    if (uiStateContext.locationSort?.sortBy?.value === 'Proximity') {
      sortByProximity();
    } else if (uiStateContext.locationSort?.sortBy?.value === 'Alphabetically A-Z') {
      sortedLocations = sortedLocations.sort((a, b) => (a.location_name < b.location_name ? -1 : 1));
    } else if (uiStateContext.locationSort?.sortBy?.value === 'Alphabetically Z-A') {
      sortedLocations = sortedLocations.sort((a, b) => (a.location_name > b.location_name ? -1 : 1));
    }

    setSortedResults([...sortedLocations]);
  }, [locations, uiStateContext, zipDistance]);

  return sortedResults;
};

export default useSortLocationResults;
