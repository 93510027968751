/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable @typescript-eslint/ban-types */
import axios from 'axios';

// NOTE: exclude this from testing as it's just an 3rd party lib. test our code only.
// Only implementing get, add others as needed

const httpClient = {
  get(path: string, headers: {}): Promise<any> {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );

    return axios.get(path, { headers: headers });
  }
};

export default httpClient;
