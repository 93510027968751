import * as DialogPrimitive from '@radix-ui/react-dialog';
import React, { ReactNode } from 'react';
import { Flex } from 'rebass/styled-components';

import { Media } from '../../contexts/media/MediaContext';
import { IconPack } from '../../models/app-data-model';
import ShIcon from '../sh-icon/sh-icon';
import './modal.scss';

export type Ref = HTMLDivElement;

interface Props {
  children?: ReactNode;
  type?: 'DialogPrimitive';
  headerText?: string;
  iconPack: IconPack[];
  videoModal?: boolean;
}

export const ModalContent = React.forwardRef<Ref, Props>(({ children, ...props }, forwardedRef) => (
  <DialogPrimitive.Portal>
    <DialogPrimitive.Overlay className={`dialog-overlay ${props.videoModal ? 'video-dialog-overlay' : ''}`} />
    <DialogPrimitive.Content ref={forwardedRef} className={`dialog-container ${props.videoModal ? 'video-dialog-container' : ''}`}>
      <Flex className="dialog-header">
        <div className="dialog-header-text">
          <Media lessThan="tablet">{props.headerText}</Media>
        </div>
        <DialogPrimitive.Close asChild>
          <button className="icon-button" aria-label="Close">
            <ShIcon iconPack={props.iconPack} iconName="close" className="compact-icon" />
          </button>
        </DialogPrimitive.Close>
      </Flex>
      <div className="dialog-content" data-testid="modal-content">{children}</div>
    </DialogPrimitive.Content>
  </DialogPrimitive.Portal>
));

export const Modal = DialogPrimitive.Root;
export const ModalTrigger = DialogPrimitive.Trigger;
