/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { Box, Flex } from 'rebass/styled-components';

import ButtonWrapper from '../../shared/components/button-wrapper/button-wrapper';
import { DeviceType } from '../../shared/hooks/use-breakpoint/device-type';
import useBreakpoint from '../../shared/hooks/use-breakpoint/useBreakpoint';
import { PaginationDetails, IconPack} from '../../shared/models/app-data-model';
import './paginator.scss';

export type PaginatorProps = {
  paginationDetails: PaginationDetails;
  onPageChange: (currentPage: number) => void;
  iconPack: IconPack[];
  dynamicSearch?: boolean;
};

const Paginator: React.FC<PaginatorProps> = (props: PaginatorProps) => {
  const deviceType = useBreakpoint();

  const { paginationDetails, onPageChange } = props;
  const isMobile = deviceType <= DeviceType.Mobile;
  const isDesktop = deviceType > DeviceType.Mobile;

  return (
    <>
      <Flex className="paginator" justifyContent={isMobile ? 'center' : 'left'}>
        <ButtonWrapper
          type="tertiary"
          label="Previous"
          reverseTertiary={true}
          styleOverride={isMobile && !props?.dynamicSearch ? 'underlined-text' : undefined}
          iconPack={props.iconPack}
          isDisabled={paginationDetails.currentPage === 1}
          onClick={paginationDetails.currentPage > 1 ? () => onPageChange(paginationDetails.currentPage - 1) : () => {}}
        />
        <ButtonWrapper
          type="tertiary"
          label="Next"
          styleOverride={isMobile && !props?.dynamicSearch ? 'underlined-text' : undefined}
          iconPack={props.iconPack}
          isDisabled={paginationDetails.currentPage === paginationDetails.totalPages}
          onClick={
            paginationDetails.currentPage !== paginationDetails.totalPages
              ? () => onPageChange(paginationDetails.currentPage + 1)
              : () => {}
          }
        />

        {isDesktop && (
          <Box className="page-count">
            <span>
              Page {paginationDetails.currentPage} of {paginationDetails.totalPages}
            </span>
          </Box>
        )}
      </Flex>
      {isMobile && (
        <Flex justifyContent="center" className={`${props?.dynamicSearch && "mobile-padding-bottom"}`}>
          <Box className="page-count">
            <span>
              Page {paginationDetails.currentPage} of {paginationDetails.totalPages}
            </span>
          </Box>
        </Flex>
      )}
    </>
  );
};

Paginator.defaultProps = {
  paginationDetails: {
    currentPage: 1,
    totalPages: 1
  }
};

export default Paginator;
