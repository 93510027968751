import React, { useContext, useEffect, useState } from 'react';

import { AppDataContext } from '../../../shared/contexts/app-data/AppDataContext';
import { Icon, IconName, IconPack } from '../../models/app-data-model';
import { addTitleToInlineSvg } from '../../services/string-manipulation/string-manipulation-service';
import './sh-icon.scss';

/**
 *  Render a custom SVG icon (from the global settings icon pack) inline or as an image
 */
export type ShIconProps = {
  iconPack?: IconPack[];
  altText?: string;
  icon?: Icon;
  iconName?: IconName;
  className?: string;
  elemId?: string;
};

const ShIcon: React.FC<ShIconProps> = ({ iconPack, altText, icon, iconName, className, elemId }: ShIconProps) => {
  const [iconSrc, setIconSrc] = useState(icon ? icon?.svg?.url : iconPack && iconName ? iconPack[0]?.[iconName]?.svg?.url : '');
  const [svgData, setSvgData] = useState(icon ? icon?.svg_data : iconPack && iconName ? iconPack[0]?.[iconName]?.svg_data : '');

  const iconTitle = altText ? altText : icon ? `${icon?.svg?.title} icon` : `${iconName} icon`;
  const nonStaticIconPack = useContext(AppDataContext).appData.globalSettings?.site_theme?.find((x) => x.icon_pack)?.icon_pack;

  useEffect(() => {
    if (iconName && nonStaticIconPack?.length) {
      setIconSrc(nonStaticIconPack[0]?.[iconName]?.svg?.url);
      setSvgData(nonStaticIconPack[0]?.[iconName]?.svg_data);
    }
    
    if (iconPack && iconName) {
      setIconSrc(iconPack[0]?.[iconName]?.svg?.url);
      setSvgData(iconPack[0]?.[iconName]?.svg_data);
    }
  }, [iconName, nonStaticIconPack]);

  return svgData ? (
    <span
      className={`sh-icon ${className ?? ''}`}
      id={elemId}
      data-testid="svg-inline"
      dangerouslySetInnerHTML={{ __html: addTitleToInlineSvg(svgData, iconTitle) }}
    ></span>
  ) : iconSrc ? (
    <img data-testid="svg-img" className={`sh-icon ${className ?? ''}`} id={elemId} src={iconSrc} alt={iconTitle} />
  ) : null;
};

export default ShIcon;
