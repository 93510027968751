import React from "react";

import { corewellCardGridOverrideClasses } from "../../../constants/constants";
import { CardContent, IconPack, ImageContent } from "../../models/app-data-model";
import { sanitizeRichText } from "../../services/string-manipulation/string-manipulation-service";
import ShLink from "../sh-link/sh-link";
import "./content-card.scss";

export type ContentCardProps = {
  content: CardContent;
  cardWidth: number;
  cardImage?: ImageContent;
  fadedStyles?: boolean;
  includeImagePadding?: boolean;
  squareMode?: boolean;
  naturalWidthImage?: boolean;
  corewellStyleOverrides?: boolean;
  iconPack: IconPack[];
};

const ContentCard: React.FC<ContentCardProps> = ({
  content,
  cardWidth,
  cardImage,
  fadedStyles,
  includeImagePadding,
  squareMode,
  naturalWidthImage,
  corewellStyleOverrides,
  iconPack
}: ContentCardProps) => {
  const includeSquareMode = squareMode && cardWidth >= 3;

  const backgroundImage = (
    <div
      className={`card-image-container ${includeImagePadding ? "include-padding" : ""} ${includeSquareMode ? "square-mode" : ""}`}
      style={{ backgroundImage: `url(${cardImage.file?.url})` }}
      data-testid={includeImagePadding ? "includePadding" : "noPadding"}
    >
      <span role="img" aria-label={cardImage.alt_text} data-testid="backgroundImage" />
    </div>
  );

  const naturalWidthImageDisplay = (
    <div
      className={`card-image-container-natural ${includeImagePadding ? "include-padding" : ""}`}
      data-testid={includeImagePadding ? "includePadding" : "noPadding"}
    >
      <img className="" src={cardImage.file?.url} alt={cardImage.alt_text} data-testid="naturalWidthImage" />
    </div>
  );

  const corewellTypographyOverrides = corewellStyleOverrides ? `${corewellCardGridOverrideClasses?.cardCaptionTypography}` : "";
  const subTitleClasses = `body-regular subtitle-text ${corewellTypographyOverrides}`;

  const cardContent = (
    <>
      {cardImage.file?.url && (naturalWidthImage ? naturalWidthImageDisplay : backgroundImage)}
      <div className={`text-container`}>
        <div className={`h6 title-text ${!cardImage.file?.url && "no-title-margin"}`}>{content?.content_card.card_title}</div>
        <div className={subTitleClasses} dangerouslySetInnerHTML={{ __html: sanitizeRichText(content?.content_card?.card_subtitle) }} />
      </div>
      {fadedStyles && <div data-testid="overlay" className="card-grid-overlay"></div>}
    </>
  );

  const corewellClassOverrides = corewellStyleOverrides
    ? `${corewellCardGridOverrideClasses?.cardboxShadow} ${corewellCardGridOverrideClasses?.cardborderRadius} ${corewellCardGridOverrideClasses?.cardboxBorder}`
    : "";
  const cardClasses = `content-card grid-card ${corewellClassOverrides} ${
    cardWidth === 4 ? "quarter-width" : cardWidth === 3 ? "third-width" : cardWidth === 2 ? "half-width" : "full-width"
  }`;

  if (content?.content_card?.reference) {
    return (
      <ShLink iconPack={iconPack} reference={content?.content_card.reference} className={cardClasses}>
        {cardContent}
      </ShLink>
    );
  } else {
    return (
      <div className={`unclickable ${cardClasses}`} data-testid="unClickable">
        {cardContent}
      </div>
    );
  }
};

export default ContentCard;
