import React, { useContext } from 'react';
import { Box, Flex } from 'rebass/styled-components';

import { UiStateContext } from '../../../shared/contexts/ui-state/UiStateContext';
import { DeviceType } from '../../hooks/use-breakpoint/device-type';
import useBreakpoint from '../../hooks/use-breakpoint/useBreakpoint';
import { IconPack, Provider } from '../../models/app-data-model';
import CardImage from '../card-image/card-image';
import ShIcon from '../sh-icon/sh-icon';
import './provider-card-grid-card-compact.scss';

export type ProviderCardProps = {
  provider: Provider;
  fadedStyles?: boolean;
  iconPack: IconPack[];
};

const ProviderCardGridCardCompact: React.FC<ProviderCardProps> = ({ provider, fadedStyles, iconPack }: ProviderCardProps) => {
  const deviceType = useBreakpoint();
  const uiStateContext = useContext(UiStateContext);
  let locationContent = <></>;
  if (provider?.location) {
    const { street_address: address, state, city, zip_code: zip } = provider.location.address;
    locationContent = (
      <Flex alignItems="center" justifyContent="space-between" width={1} data-testid="providerAddress">
        <Flex width={1} justifyContent="space-between">
          <Flex width={1 / 2} marginRight={2}>
            <ShIcon iconPack={iconPack} iconName="map_pin" className="compact-icon" />
            <span className="body-small secondary-text location-text ellipses-overflow">{`${address}, ${city}, ${state} ${zip}`}</span>
          </Flex>
          {provider?.distance && provider?.distance !== 'null' && (
            <span className="body-small secondary-text">
              <strong>{provider?.distance}</strong> miles away from{' '}
              <span className="address" data-testid="browserLocation">
                {uiStateContext?.browserLocation?.latitude === 0 ? 'Grand Rapids' : 'Your Location'}
              </span>
            </span>
          )}
        </Flex>
      </Flex>
    );
  }
  return (
    <>
      {fadedStyles && <div data-testid="overlay" className="card-grid-overlay"></div>}
      <a href={provider.website?.href} className="provider-card-compact" target="_blank" rel="noopener noreferrer">
        <Flex
          data-testid="marginBottom"
          justifyContent="space-around"
          alignItems="center"
          className={`provider-card-grid-card-compact grid-card ${fadedStyles ? 'faded' : ''}`}
          flexDirection="column"
        >
          <Flex className="compact-card-top" alignItems="center" py={2}>
            <Box paddingRight={3}>
              <Box
                className={`${deviceType > DeviceType.Mobile ? 'provider-pic' : 'provider-pic-mobile'}
                ${provider?.spectrum_employed && deviceType > DeviceType.Mobile ? 'spectrum-employed' : ''}
                ${provider?.spectrum_employed && deviceType <= DeviceType.Mobile ? 'spectrum-employed-mobile' : ''}`}
                paddingRight={3}
                data-testid={`spectrumEmployed-${provider?.spectrum_employed}-${deviceType > DeviceType.Mobile ? '!mobile' : 'mobile'}`}
              >
                <CardImage
                  url={provider?.photo?.href}
                  alt={provider.title}
                  className={deviceType > DeviceType.Mobile ? 'profile-pic' : 'profile-pic-mobile'}
                  type={'provider'}
                />
              </Box>
            </Box>
            <Flex alignItems="flex-start" flexDirection="column" justifyContent="center">
              <Box className="provider-info">
                <span className="h6">{provider.title}</span>
              </Box>
              {provider?.primary_specialty && (
                <Box className="secondary-text provider-info">
                  <span className="body-small">{provider.primary_specialty}</span>
                </Box>
              )}
              <Box className="secondary-text provider-info">
                <Flex justifyContent="space-between" width={1}>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Box paddingRight={2} data-testid={`newPatients-${provider?.new_patients}`}>
                      {provider.new_patients ? (
                        <ShIcon iconPack={iconPack} className="check-icon" iconName="check" />
                      ) : (
                        <ShIcon iconPack={iconPack} className="close-icon" iconName="bold_close" />
                      )}
                    </Box>
                    <span className="secondary-text body-small">
                      {provider.new_patients ? `Accepting new patients` : `Not accepting new patients`}
                    </span>
                  </Flex>
                </Flex>
              </Box>
              {provider?.rating > 0 && (
                <Flex className="provider-info" data-testid="providerRating">
                  <Box paddingRight={2}>
                    <ShIcon iconPack={iconPack} iconName="star" className="star-icon" />
                  </Box>
                  <span className="rating">{provider.rating}</span>
                  {provider?.reviews && <span className="reviews">({provider?.reviews})</span>}
                </Flex>
              )}
            </Flex>
          </Flex>
          <Flex className="compact-card-bottom" justifyContent="space-between" alignItems="center" flexDirection="column" py={2}>
            {locationContent}
          </Flex>
        </Flex>
      </a>
    </>
  );
};

export default ProviderCardGridCardCompact;
