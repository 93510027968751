import { useContext } from 'react';

import { AppDataContext } from '../../contexts/app-data/AppDataContext';
import { FeatureToggle } from '../../models/app-data-model';

/**
 * A hook for wrapping a feature around a toggle
 * It will check to see if your feature toggle exists and if it is enabled
 * If it does not exist, it will assume the feature is disabled.
 * *NOTE* This means that when we retire feature toggles, we need to remove them from code **first**.
 */
function useFeatureToggle(featureName: string, staticToggles: FeatureToggle[]): boolean {
  const appDataContext = useContext(AppDataContext);
  const staticFeatureToggle = staticToggles?.find((feature) => feature.feature_title === featureName)?.enabled as boolean;
  const runtimeFeatureToggle = appDataContext?.appData?.globalSettings?.feature_toggles?.find(
    (feature) => feature.feature_title === featureName
  );

  return runtimeFeatureToggle ? runtimeFeatureToggle.enabled : staticFeatureToggle;
}

export default useFeatureToggle;
