import React, { useState } from "react";

import { IconPack, Provider, ProviderCardGridContent } from "../../models/app-data-model";
import ButtonWrapper from "../button-wrapper/button-wrapper";
import CardGridContainerMobile from "../card-grid-container-mobile/card-grid-container-mobile";
import ProviderCardGridCardCompact from "../provider-card-grid-card-compact/provider-card-grid-card-compact";

export type ProviderCardGridMobileProps = {
  content: ProviderCardGridContent;
  iconPack: IconPack[];
  apiResponse: Provider[];
};

const ProviderCardGridMobile: React.FC<ProviderCardGridMobileProps> = ({ content, apiResponse, iconPack }: ProviderCardGridMobileProps) => {
  const [isShowAllToggled, toggleShowAll] = useState(false);
  const fullCardList = apiResponse;
  const reducedCardList = apiResponse?.slice(0, 3);

  const reducedCardListRenderings = reducedCardList?.map((card, index) =>
    index === 2 ? (
      <ProviderCardGridCardCompact iconPack={iconPack} key={index} fadedStyles={true} provider={card as unknown as Provider} />
    ) : (
      <ProviderCardGridCardCompact iconPack={iconPack} key={index} provider={card as unknown as Provider} />
    )
  );

  const fullCardListRenderings = fullCardList?.map((card, index) => (
    <ProviderCardGridCardCompact iconPack={iconPack} key={index} provider={card as unknown as Provider} />
  ));

  return (
    <CardGridContainerMobile iconPack={iconPack} tertiaryButton={content?.tertiary_button} showTertiaryButton={isShowAllToggled}>
      {!isShowAllToggled && fullCardList?.length > 3 ? reducedCardListRenderings : fullCardListRenderings}
      {fullCardList?.length > 3 && !isShowAllToggled && (
        <span className="grid-bottom-button">
          <ButtonWrapper
            iconPack={iconPack}
            type="tertiary"
            label={"Show All"}
            styleOverride="transparent-bg"
            isShowMore={true}
            onClick={() => toggleShowAll(true)}
          ></ButtonWrapper>
        </span>
      )}
    </CardGridContainerMobile>
  );
};

export default ProviderCardGridMobile;
