export const sanitizeRichText = (richText: string): string => {
  return richText?.replace(/<[^/>][^>]*><\/[^>]+>/g, '');
};

export const addTitleToInlineSvg = (svgData: string, title: string): string => {
  if (svgData && !svgData.includes('<title>')) {
    svgData = svgData?.replace('>', `><title>${title}</title>`);
  }
  return svgData;
};
