export type Row<T> = {
  items: T[];
};

/**
 * This hook is a generic solution to taking an array of content and generating it into a grid that has columns and rows.
 * Based on the max # of columns you set, and the content array you provide,
 * it will return an array of Rows, where each Row contains items. Each row may contain up to a limit of your maxColumns #.
 * So for grid with maxColumns of 3 and a content array of 10 things, you'd have 4 rows.
 * The top 3 would be full of 3 items each, and the 4th (last) row would only contain the 10th (last) item.
 * @param content The content you wish to generate your grid data with
 * @param maxColumns The maximum number of columns your grid should have
 * @returns a grid
 */
function useGrid<T1>(content?: T1[], maxColumns?: number): Row<T1>[] {
  const grid: Row<T1>[] = [];

  // early abort if inputs are undefined
  if (!maxColumns || !content) {
    return grid;
  }

  // Generates our 2-dimensional grid array
  // Jake Jones (jacob.jones@spectrumhealth.org) worked really hard on this algorithm to get it to be On1 (linear).
  // The code below may look complex and abstract at first glance, but the pattern ensures we only scan the array of content once!
  let rows: Row<T1> = { items: [] };

  content?.forEach((card, index) => {
    const pos = index + 1;
    // If the current card is at the end of a row, or is the last card in the array,
    // we push the current card to the row, push the current row to the grid, then reset the row array
    if (pos % maxColumns === 0 || pos === content.length) {
      rows.items.push(card);
      grid.push(rows);
      rows = { items: [] };
    } else {
      rows.items.push(card);
    }
  });

  return grid;
}

export default useGrid;
