import { useState, useEffect } from 'react';

import useWindowSize from '../window-size/useWindowSize';
import { DeviceType } from './device-type';

/**
 * Hook that will tell you easily if you are likely on a mobile device or not
 * Does not factor in things like OS, merely checks the window width.
 */

const useBreakpoint = (): DeviceType => {
  const windowSize = useWindowSize();

  const [deviceType, setDeviceType] = useState(DeviceType.Mobile);

  useEffect(() => {
    if (windowSize?.width) {
      if (windowSize?.width <= 640) {
        setDeviceType(DeviceType.Mobile);
      } else if (windowSize?.width > 640 && windowSize?.width < 960) {
        setDeviceType(DeviceType.Tablet);
      } else if (windowSize?.width >= 960) {
        setDeviceType(DeviceType.Desktop);
      }
    }
  }, [windowSize]);

  return deviceType;
};

export default useBreakpoint;
