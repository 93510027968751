import React from 'react';
import { createGlobalStyle } from 'styled-components';

import { SiteTheme } from '../../models/app-data-model';

export type GoogleFontProps = {
  theme: SiteTheme;
  className?: string;
};

type StyleProps = {
  theme: SiteTheme;
  className?: string;
};

/**
 * gets the typeface reference from a SiteTheme
 * @param theme the theme
 */
export const getTypeFace = (theme: SiteTheme | undefined): string => {
  return theme?.typeface?.find((t) => t.title)?.title || 'Montserrat';
};

const GlobalStyle = createGlobalStyle<StyleProps>`
  ${(props) => props.className || 'body'} {
    font-family: '${(props) => CSS.escape(getTypeFace(props.theme))}', sans-serif;
  }
  `;

/**
 * Creates a Style sheet and global style for the provided font the provided class name,
 * or "body" by default.
 * Retrieves our typeface from our ThemeContext
 *
 * @param className an optional classname to scope your font to, or defaults to "body"
 * @returns a global style jsx element
 */
const GoogleFont: React.FC<GoogleFontProps> = ({ theme, className }: GoogleFontProps) => {
  return (
    <>
      <GlobalStyle className={className} theme={theme} />
    </>
  );
};

export default GoogleFont;
