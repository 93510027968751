import React from 'react';

import './loading-spinner.scss';

const LoadingSpinner: React.FC = () => {
  return (
    <div className="spinner-wrapper">
      <svg viewBox="-1950 -975 3900 1950">
        <linearGradient id="g">
          <stop stopColor="#39E1D1"></stop>
          <stop stopColor="#75FD9B" offset="1"></stop>
        </linearGradient>
        <mask id="m">
          <circle r="1200" fill="#000"></circle>
          <use xlinkHref="#c"></use>
        </mask>
        <circle id="c" r="600"></circle>
        <g>
          <use xlinkHref="#c"></use>
        </g>
      </svg>
    </div>
  );
};

export default LoadingSpinner;
