import { DeviceType } from '../../hooks/use-breakpoint/device-type';

/*
  The customStyles function allows to use emotion to style the select-input-container component.
  Each property can make use of two parameters base and state.

  Base is the object in which you set styles. State allows you to plugin to things like isFocused, isDiabled, etc.

  Provided style and state: https://react-select.com/styles#provided-styles-and-state
*/
export const customStyles = (deviceType: DeviceType, isValid: boolean): any => {
  return {
    container: (base: any) => {
      return {
        ...base,
        width: deviceType < DeviceType.Desktop ? '100%' : '99%',
        boxShadow: 'none',
        border: '0',
        '&:hover': {
          border: '0'
        }
      };
    },
    menu: (base: any) => {
      return {
        ...base,
        backgroundColor: '#FFF'
      };
    },
    control: (base: any, state: any) => {
      return {
        ...base,
        border:
          !isValid && state.isFocused
            ? '2px solid #ad0000'
            : !isValid
            ? '1px solid #ad0000'
            : state.isDisabled
            ? '1px solid #A7AFBA'
            : state.isFocused
            ? '0'
            : '1px solid #606e80',
        height: '50px',
        width: deviceType < DeviceType.Desktop ? '99.5%' : '100%',
        paddingRight: '27px',
        top: '1px',
        left: '1px',
        borderRadius: '5px',
        paddingLeft: '10px',
        boxShadow: 'none',
        '&:hover': {
          borderColor: !isValid ? '#ad0000' : '#002855'
        }
      };
    },
    placeholder: (base: any, state: any) => {
      return {
        ...base,
        paddingLeft: '8px',
        color: !isValid ? '#ad0000' : '#606E80',
        position: 'absolute',
        left: '0',
        top: state.hasValue ? '20%' : '50%',
        fontSize: state.hasValue ? '14px' : '16px'
      };
    },
    indicatorSeparator: (base: any) => ({
      ...base,
      display: 'none'
    }),
    dropdownIndicator: (base: any, state: any) => {
      return {
        ...base,
        color: '#606e80',
        transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
        '&:hover': {
          color: '#606e80'
        },
        paddingRight: 0
      };
    },
    option: (base: any, state: any) => {
      return {
        ...base,
        paddingLeft: '30px',
        borderBottom: '0.5px solid #A7AFBA',
        fontSize: deviceType < DeviceType.Desktop ? '14px' : '16px',
        color: state.isDisabled ? '' : state.isSelected ? '#002855' : state.isFocused ? '#002855' : '#606E80',
        backgroundColor: state.isDisabled ? '' : state.isSelected ? '#f1f1f1' : state.isFocused ? '#f1f1f1' : '#fff'
      };
    },
    menuList: (base: any) => {
      return {
        ...base,
        borderRadius: '5px',
        borderStyle: 'none',
        paddingTop: '0',
        paddingBottom: '0',
        maxHeight: '135px',
        boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.15)'
      };
    },
    singleValue: (base: any) => {
      return {
        ...base,
        color: '#002855',
        fontSize: deviceType < DeviceType.Desktop ? '14px' : '16px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        paddingTop: deviceType < DeviceType.Desktop ? '16px' : '20px'
      };
    },
    valueContainer: (base: any) => {
      return {
        ...base,
        paddingBottom: '10px'
      };
    }
  };
};
