import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Flex, Box } from 'rebass/styled-components';

import Divider from '../../shared/components/divider/divider';
import { UiStateContext } from '../../shared/contexts/ui-state/UiStateContext';
import useSortProviderResults, {
  SearchProviderFilters,
  SearchProviderSort
} from '../../shared/hooks/search-providers-sort/useSortProviderResults';
import { DeviceType } from '../../shared/hooks/use-breakpoint/device-type';
import useBreakpoint from '../../shared/hooks/use-breakpoint/useBreakpoint';
import usePagination from '../../shared/hooks/use-pagination/usePagination';
import { ZipDistance } from '../../shared/hooks/zip-distance/useZipDistance';
import { IconPack, FeatureToggle, GlobalSearchNoResultsContent } from '../../shared/models/app-data-model';
import { Provider } from '../../shared/services/kendra-api/kendra-api-service';
import NoResults from '../no-results/no-results';
import Paginator from '../paginator/paginator';
import ProvidersList from '../providers-list/providers-list';
import SearchClearFilters from '../search-clear-filters/search-clear-filters';
import SearchMobileHeader from '../search-mobile-header/search-mobile-header';
import SearchTotalResults from '../search-total-results/search-total-results';
import ProviderFilter from './provider-filter/provider-filter';
import './providers.scss';

export type ProviderSearchResultProps = {
  providers: Array<Provider>;
  iconPack: IconPack[];
  featureToggles: FeatureToggle[];
  searchSuggestions?: string[];
  noResultsFoundContent?: GlobalSearchNoResultsContent;
};

const Providers: React.FC<ProviderSearchResultProps> = ({
  providers,
  iconPack,
  featureToggles,
  searchSuggestions,
  noResultsFoundContent
}: ProviderSearchResultProps) => {
  const deviceType = useBreakpoint();
  const filteredResults = useSortProviderResults(providers);
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const [showClearFilters, setShowClearFilter] = useState(false);
  const [currentPageData, paginationDetails, setCurrentPage] = usePagination(filteredResults);
  const uiStateContext = useContext(UiStateContext);

  const onPageChange = useCallback(
    (currentPage: number): void => {
      setCurrentPage(currentPage);
      window?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    },
    [setCurrentPage]
  );

  const handleFilterSelectChange = useCallback(
    (newFilter?: SearchProviderFilters): void => {
      uiStateContext.setProviderFilters(newFilter);
      setCurrentPage(1);
    },
    [uiStateContext, setCurrentPage]
  );

  const handleZipChange = useCallback(
    (newZip?: ZipDistance): void => {
      uiStateContext.setZipLocation(newZip);
      setCurrentPage(1);
    },
    [uiStateContext, setCurrentPage]
  );

  const handleSortSelectChange = useCallback(
    (newSort?: SearchProviderSort): void => {
      uiStateContext.setProviderSort(newSort);
      setCurrentPage(1);
    },
    [uiStateContext, setCurrentPage]
  );

  const handleClearFilter = useCallback((): void => {
    uiStateContext.setProviderFilters(undefined);
    uiStateContext.setProviderSort(undefined);
    uiStateContext.setZipLocation?.(undefined);
    setCurrentPage(1);
  }, [uiStateContext, setCurrentPage]);

  useEffect(() => {
    if (!uiStateContext.providerFilters || !uiStateContext.providerSort) return;

    setShowClearFilter(
      Object.values(uiStateContext.providerFilters).some((x) => x !== undefined) ||
        Object.values(uiStateContext.providerSort).some((x) => x !== undefined) ||
        Object.values(uiStateContext.zipLocation || {}).some((x) => x !== undefined)
    );
  }, [uiStateContext.providerFilters, uiStateContext.providerSort, uiStateContext.zipLocation]);

  useEffect(() => {
    window?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [deviceType]);

  useEffect(() => {
    if (deviceType > DeviceType.Mobile) {
      window?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [deviceType, uiStateContext.providerFilters, showClearFilters]);

  const providersList = (
    <Box marginY={0} className="provider-list-container">
      <ProvidersList iconPack={iconPack} providers={currentPageData as Provider[]} />
    </Box>
  );

  const mobileView = (
    <>
      {
        <Flex flexDirection="column" width={1} className="mobile-view-container">
          <SearchMobileHeader
            showMobileFilters={showMobileFilters}
            setShowMobileFilters={setShowMobileFilters}
            showClearFilters={showClearFilters}
            clearFilter={handleClearFilter}
            iconPack={iconPack}
          />
          {showMobileFilters ? (
            <Box marginTop="60px">
              <ProviderFilter
                onSortSelectChange={handleSortSelectChange}
                onFilterSelectChange={handleFilterSelectChange}
                onZipSelectChange={handleZipChange}
              />
            </Box>
          ) : filteredResults?.length > 0 ? (
            <Box marginTop="54px">
              <SearchTotalResults count={filteredResults.length} />
              {providersList}
              <Paginator paginationDetails={paginationDetails} onPageChange={onPageChange} />
            </Box>
          ) : (
            <Box marginTop="54px">
              <NoResults
                content={noResultsFoundContent}
                searchSuggestions={searchSuggestions}
                iconPack={iconPack}
                featureToggles={featureToggles}
              />
            </Box>
          )}
        </Flex>
      }
    </>
  );

  const desktopView = (
    <>
      {filteredResults?.length > 0 ? (
        <Flex flexDirection="column" marginTop={2}>
          <SearchTotalResults count={filteredResults.length} />
          {providersList}
          <Paginator paginationDetails={paginationDetails} onPageChange={onPageChange} />
        </Flex>
      ) : (
        <NoResults
          content={noResultsFoundContent}
          searchSuggestions={searchSuggestions}
          iconPack={iconPack}
          featureToggles={featureToggles}
        />
      )}
      <Flex flexDirection="column" alignItems="flex-start" className="search-filters-wrapper" width={1 / 3}>
        <Box className="search-filter-option" py={2}>
          <span className="body-small">Refine your search</span>
        </Box>
        <ProviderFilter
          onSortSelectChange={handleSortSelectChange}
          onFilterSelectChange={handleFilterSelectChange}
          onZipSelectChange={handleZipChange}
        />
        {showClearFilters && (
          <>
            <Divider className="search-divider" />
            <SearchClearFilters iconPack={iconPack} clearFilter={handleClearFilter} />
          </>
        )}
      </Flex>
    </>
  );

  return <>{deviceType === DeviceType.Desktop ? desktopView : mobileView}</>;
};

export default Providers;
