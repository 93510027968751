/* eslint-disable max-len */

/* istanbul ignore file */
import { AlertsContent } from '../../models/app-data-model';
import React, { useState, ReactNode } from 'react';

import { getLocationDisplayTextFromObj } from '../../../global-toolbar/set-location/set-location';
import { NavTabs } from '../../../search/search-nav/search-nav';
import { SearchLocationFilters, SearchLocationSort } from '../../hooks/search-locations-sort/useSortLocationResults';
import { SearchProviderFilters, SearchProviderSort } from '../../hooks/search-providers-sort/useSortProviderResults';
import { ZipDistance } from '../../hooks/zip-distance/useZipDistance';
import { UiStateContext } from './UiStateContext';

export type AppDataContextProviderProps = {
  children: ReactNode;
};

/**
 * Providers our Global Ui State to all children
 *
 * @param {AppDataContextProviderProps} { children }
 * @returns
 */
const UiStateContextProvider: React.FC<AppDataContextProviderProps> = ({ children }: AppDataContextProviderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuOpenIndex, setMenuOpenIndex] = useState(0);
  const [megaMenuOpen, setMegaMenuOpen] = useState(false);
  const [subsiteMenuOpen, setSubsiteMenuOpen] = useState(false);
  const [subsiteMenuOpenIndex, setSubsiteMenuOpenIndex] = useState(0);
  const [auxNavOpen, setAuxNavOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [backButtonNavItem, setBackButtonNavItem] = useState(false);
  const [pageHasScrolled, setPageHasScrolled] = useState(false);
  const [alertCount, setAlertCount] = useState<number>(0);
  const [showAlerts, setShowAlerts] = useState(false);
  const [alertContainerHeight, setAlertContainerHeight] = useState(0);
  const [selectedFooterItem, setSelectedFooterItem] = useState(0);
  const [userAcceptedLocationPrompt, setUserAcceptedLocationPrompt] = useState(false);
  const [userDeniedLocationPrompt, setUserDeniedLocationPrompt] = useState(false);
  const [browserLocation, setBrowserLocation] = useState({ latitude: 0, longitude: 0 });
  const [pageAlerts, setPageAlerts] = useState([] as AlertsContent[]);
  const [subsiteAlerts, setSubsiteAlerts] = useState([] as AlertsContent[]);
  const [searchOpen, setSearchOpen] = useState(false);
  const [embeddedSearchShown, setEmbeddedSearchShown] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeSearchTab, setActiveSearchTab] = useState(NavTabs.all);
  const [zipLocation, setZipLocation] = useState<ZipDistance | undefined>(undefined);
  const [providerFilters, setProviderFilters] = useState<SearchProviderFilters | undefined>(undefined);
  const [providerSort, setProviderSort] = useState<SearchProviderSort | undefined>(undefined);
  const [locationFilters, setLocationFilters] = useState<SearchLocationFilters | undefined>(undefined);
  const [locationSort, setLocationSort] = useState<SearchLocationSort | undefined>(undefined);
  const [userRegion, setUserRegion] = useState('system');
  const [userLocationText, setUserLocationText] = useState(() => {
    const saved = window?.localStorage?.getItem('userCurrentLocation');

    if (!saved) {
      return undefined;
    }
    const locObj = JSON.parse(saved);
    const locationText = getLocationDisplayTextFromObj(locObj);

    return locationText;
  });
  const [allowHosts, setAllowHosts] = useState<string[]>([]);

  return (
    <UiStateContext.Provider
      value={{
        menuOpen,
        setMenuOpen,
        megaMenuOpen,
        setMegaMenuOpen,
        menuOpenIndex,
        setMenuOpenIndex,
        subsiteMenuOpen,
        setSubsiteMenuOpen,
        subsiteMenuOpenIndex,
        setSubsiteMenuOpenIndex,
        auxNavOpen,
        scrollPosition,
        setScrollPosition,
        setAuxNavOpen,
        backButtonNavItem,
        setBackButtonNavItem,
        pageHasScrolled,
        setPageHasScrolled,
        searchOpen,
        setSearchOpen,
        embeddedSearchShown,
        setEmbeddedSearchShown,
        searchQuery,
        setSearchQuery,
        activeSearchTab,
        setActiveSearchTab,
        setAlertCount,
        alertCount,
        setShowAlerts,
        showAlerts,
        alertContainerHeight,
        setAlertContainerHeight,
        setSelectedFooterItem,
        selectedFooterItem,
        browserLocation,
        setBrowserLocation,
        zipLocation,
        setZipLocation,
        userAcceptedLocationPrompt,
        setUserAcceptedLocationPrompt,
        userDeniedLocationPrompt,
        setUserDeniedLocationPrompt,
        providerFilters,
        setProviderFilters,
        providerSort,
        setProviderSort,
        locationFilters,
        setLocationFilters,
        locationSort,
        setLocationSort,
        pageAlerts,
        setPageAlerts,
        subsiteAlerts,
        setSubsiteAlerts,
        userRegion,
        setUserRegion,
        userLocationText,
        setUserLocationText,
        allowHosts,
        setAllowHosts
      }}
    >
      {children}
    </UiStateContext.Provider>
  );
};

export default UiStateContextProvider;
