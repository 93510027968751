import { useEffect } from 'react';

import { FeatureToggle } from '../../models/app-data-model';
import useFeatureToggle from '../use-feature-toggle/useFeatureToggle';

/**
 * Hook that will push events to the window for tag manager to capture
 */

declare global {
  interface Window {
    dataLayer?: any;
    QSI?: any; // used for the Qualtrics API
  }
}

function useTagManager(tagName: string, tagValue: string | any, featureToggles: FeatureToggle[]): void {
  const isTaggingEnabled = useFeatureToggle('analytics-custom-tagging', featureToggles);

  useEffect(() => {
    if (isTaggingEnabled && tagValue && typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({ event: tagName, data: tagValue });
    }
  }, [isTaggingEnabled, tagName, tagValue]);
}

export default useTagManager;
