import React from 'react';

import { ButtonType, IconPack, ReferenceLinkContent } from '../../models/app-data-model';
import ShIcon from '../sh-icon/sh-icon';
import ShLink from '../sh-link/sh-link';
import './button-wrapper.scss';

export type ButtonProps = {
  type?: ButtonType;
  label?: string;
  reference?: ReferenceLinkContent[];
  fullWidth?: boolean;
  noMargin?: boolean;
  noPadding?: boolean;
  iconPack?: IconPack[];
  // Wide button makes the button container 100%
  styleOverride?: 'gray-bg' | 'blue-bg' | 'wide-button' | 'underlined-text' | 'transparent-bg';
  reverseTertiary?: boolean;
  isShowMore?: boolean;
  isDisabled?: boolean;
  buttonId?: string;
  onClick?: () => Promise<void> | void;
};

const ButtonWrapper: React.FC<ButtonProps> = ({
  fullWidth,
  reverseTertiary,
  isShowMore,
  isDisabled,
  label,
  noMargin,
  noPadding,
  iconPack,
  onClick,
  reference,
  styleOverride,
  type,
  buttonId
}: ButtonProps) => {
  const disabled = isDisabled ? 'disabled' : '';
  const iconName = isShowMore ? 'caret_down' : 'button_arrow';
  let className = 'primary-btn';
  let shouldDisplayLabel = true;
  let shouldDisplayIcon = false;

  switch (type) {
    case 'primary':
      className = 'primary-btn';
      break;
    case 'secondary':
      className = 'secondary-btn';
      break;
    case 'tertiary':
      className = 'tertiary-btn';
      shouldDisplayIcon = true;
      break;
    case 'gradient':
      className = 'gradient-btn';
      break;
    case 'small-arrow':
      className = 'small-arrow-btn';
      shouldDisplayLabel = false;
      shouldDisplayIcon = true;
      break;
    case 'large-arrow':
      className = 'large-arrow-btn';
      shouldDisplayLabel = false;
      shouldDisplayIcon = true;
      break;
    default:
      break;
  }

  return (
    <div
      id={buttonId}
      className={`button-wrapper ${className}-wrapper ${fullWidth ? ' button-wrapper-full' : ''}
      ${isShowMore ? 'show-more' : ''}`}
    >
      <ShLink
        className={`button-container
          ${styleOverride === 'wide-button' ? 'full-width' : ''}
          ${className}-container ${disabled}
          ${reverseTertiary ? 'previous-button' : ''}
          ${noMargin ? 'no-margin' : ''}`}
        reference={reference || []}
        onClick={onClick}
        iconPack={iconPack}
      >
        <div
          className={`
            ${className}
            ${disabled}
            ${styleOverride}
            ${isShowMore ? 'show-more' : ''}
            ${reverseTertiary ? 'previous-button' : ''}
            ${noPadding ? 'no-padding' : ''}`}
          style={{ backgroundColor: styleOverride === 'gray-bg' ? '#f1f1f1' : '' }}
        >
          {shouldDisplayIcon && reverseTertiary && (
            <ShIcon iconPack={iconPack} iconName={iconName} className={`${className}-icon previous-button ${disabled}`} />
          )}

          {shouldDisplayLabel ? label : null}

          {shouldDisplayIcon && !reverseTertiary && (
            <ShIcon iconPack={iconPack} iconName={iconName} className={`${className}-icon ${disabled}`} />
          )}
        </div>
      </ShLink>
    </div>
  );
};

export default ButtonWrapper;
