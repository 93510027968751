import React from 'react';
import { Box, Flex } from 'rebass/styled-components';

import { layout } from '../../../constants/constants';
import { Media } from '../../contexts/media/MediaContext';
import './skeleton-state.scss';

export type SkeletonStateProps = {
  displayFilters: boolean;
  corewellSkeleton?: boolean;
};

const SkeletonState: React.FC<SkeletonStateProps> = ({ displayFilters, corewellSkeleton }: SkeletonStateProps) => {
  return (
    <>
      <Media lessThan="desktop" className="skeleton-state-container">
        <Flex flexDirection="column" width={1}>
          {displayFilters && (
            <Box
              alignItems="center"
              justifyContent="space-between"
              flexDirection="row"
              className={`mobile-filters skeleton-shimmer-animation ${corewellSkeleton ? "ch-mobile-filters" : "fixed"}`}
              width={1}
              paddingX={layout.searchPaddingX}
            ></Box>
          )}

          <div className={`mobile-counter ${displayFilters ? 'filter-offset' : ''} skeleton-shimmer-animation`}></div>

          <Box className="mobile-cards">
            <div className="card skeleton-shimmer-animation"></div>
            <div className="card skeleton-shimmer-animation"></div>
            <div className="card skeleton-shimmer-animation"></div>
            <div className="card skeleton-shimmer-animation"></div>
            <div className="card skeleton-shimmer-animation"></div>
            <div className="card skeleton-shimmer-animation"></div>
          </Box>
        </Flex>
      </Media>

      <Media greaterThanOrEqual="desktop" className="skeleton-state-container">
        <Flex className="desktop-cards" flexDirection="column" marginTop={[2]} flex={1}>
          <Box className="desktop-counter skeleton-shimmer-animation"></Box>
          <div className="card skeleton-shimmer-animation"></div>
          <div className="card skeleton-shimmer-animation"></div>
          <div className="card skeleton-shimmer-animation"></div>
          <div className="card skeleton-shimmer-animation"></div>
          <div className="card skeleton-shimmer-animation"></div>
        </Flex>

        {displayFilters && (
          <Flex className={`desktop-filters filter-offset ${corewellSkeleton && "ch-desktop-filters"}`} flexDirection="column" width={1 / 3}>
            <div className="filter skeleton-shimmer-animation"></div>
          </Flex>
        )}
      </Media>
    </>
  );
};

export default SkeletonState;
