import React, { useCallback, useEffect } from 'react';
import { Flex } from 'rebass/styled-components';

import usePagination from '../../shared/hooks/use-pagination/usePagination';
import { FeatureToggle, GlobalSearchNoResultsContent, IconPack } from '../../shared/models/app-data-model';
import { Page } from '../../shared/services/kendra-api/kendra-api-service';
import NoResults from '../no-results/no-results';
import Paginator from '../paginator/paginator';
import SearchTotalResults from '../search-total-results/search-total-results';
import PageContent from './page-content/page-content';
import './pages.scss';

export type PagesProps = {
  pages: Array<Page>;
  featureToggles: FeatureToggle[];
  iconPack: IconPack[];
  searchSuggestions?: string[];
  noResultsFoundContent?: GlobalSearchNoResultsContent;
};

const Pages: React.FC<PagesProps> = (props: PagesProps) => {
  const { pages, featureToggles, searchSuggestions, noResultsFoundContent } = props;
  const [currentPageData, paginationDetails, setCurrentPage] = usePagination(pages);

  const onPageChange = useCallback(
    (currentPage: number): void => {
      setCurrentPage(currentPage);
    },
    [setCurrentPage]
  );

  useEffect(() => {
    window?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [currentPageData]);

  const pageList = (
    <Flex flexDirection="column">
      {currentPageData?.map((item, index) => (
        <div key={index}>
          <PageContent page={item as Page} elementId={`results-card-${index}`} />
        </div>
      ))}
    </Flex>
  );

  return (
    <>
      {pages?.length > 0 ? (
        <Flex flexDirection="column" width={1}>
          <SearchTotalResults count={pages?.length} />
          {pageList}
          <Paginator paginationDetails={paginationDetails} onPageChange={onPageChange} />
        </Flex>
      ) : (
        <NoResults
          content={noResultsFoundContent}
          iconPack={props.iconPack}
          searchSuggestions={searchSuggestions}
          featureToggles={featureToggles}
        />
      )}
    </>
  );
};

export default Pages;
