/* eslint-disable max-len */

/* istanbul ignore file */
import GoogleFont from '../../components/google-font/google-font';
import TypographyStyle from '../../components/typography/typography-style';
import { SiteTheme, Typography } from '../../models/app-data-model';
import React, { useState, ReactNode, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';

import { ThemeContext } from './ThemeContext';

const GridProvider = ThemeProvider; // this helps with clarity, the theme provider from theme-ui is specific to our grid
require('css.escape');

export type ThemeContextProps = {
  children: ReactNode;
  siteTheme: SiteTheme[];
};

const globalGridSystemTheme = {
  breakpoints: ['320px', '480px', '640px', '960px', '1280px', '1920px'],
  space: [0, 8, 12, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88]
};

/**
 * Provides our Global Theme Context throughout the app!
 *
 * @param {ThemeContextProps} { children }
 * @returns
 */
const ThemeContextProvider: React.FC<ThemeContextProps> = ({ children, siteTheme }: ThemeContextProps) => {
  const [theme, setTheme] = useState(siteTheme?.find((t) => t));

  /**
   * Checks whenever our AppData context updates and makes sure that the we update our theme
   */
  useEffect(() => {
    const appTheme = siteTheme?.find((t) => t);
    setTheme(appTheme);
  }, [siteTheme]);

  return (
    <GridProvider theme={globalGridSystemTheme}>
      <ThemeContext.Provider value={{ theme, setTheme }}>
        <TypographyStyle typographyData={theme?.typography as Typography[]} />
        <GoogleFont theme={theme as SiteTheme} />
        {children}
      </ThemeContext.Provider>
    </GridProvider>
  );
};

export default ThemeContextProvider;
