import React from 'react';
import { components } from 'react-select';

const { ValueContainer, Placeholder } = components;

/*
  CustomValueContainer allows the select-input-container placeholder to persist
  when value is selected.

  Custom Components: https://react-select.com/props#components
*/
export const CustomValueContainer = ({ children, ...props }: any): JSX.Element => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props}>{props.selectProps.placeholder}</Placeholder>
      {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
    </ValueContainer>
  );
};
