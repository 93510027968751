/* eslint-disable max-len */

/* istanbul ignore file */
import { AppDataModel, SubsiteContent, EmbedScriptBlock, RegionalLinksModalContent } from '../../models/app-data-model';
import React, { useState, ReactNode } from 'react';

import { AppDataContext } from './AppDataContext';

export type AppDataContextProviderProps = {
  children: ReactNode;
};

/**
 * Provides our Global Application Data State throughout our application!
 *
 * @param {AppDataContextProviderProps} { children }
 * @returns
 */
const AppDataContextProvider: React.FC<AppDataContextProviderProps> = ({ children }: AppDataContextProviderProps) => {
  const initAppData = {} as AppDataModel;
  const initSubsiteData = {} as SubsiteContent | undefined;
  const [appData, setAppData] = useState(initAppData);
  const [subsiteData, setSubsiteData] = useState(initSubsiteData);
  const [embeddedScripts, setEmbeddedScripts] = useState([] as EmbedScriptBlock[]);
  const [regionalLinksModalData, setRegionalLinksModalData] = useState<RegionalLinksModalContent>({} as RegionalLinksModalContent);

  return (
    <AppDataContext.Provider
      value={{
        appData,
        setAppData,
        subsiteData,
        setSubsiteData,
        embeddedScripts,
        setEmbeddedScripts,
        regionalLinksModalData,
        setRegionalLinksModalData
      }}
    >
      {children}
    </AppDataContext.Provider>
  );
};

export default AppDataContextProvider;
