import { SiteTheme } from '../../models/app-data-model';
import React from 'react';

export type GlobalTheme = {
  theme?: SiteTheme;
  setTheme: (theme?: SiteTheme) => void;
};

/**
 * This is the context for our global theme
 */
const ThemeContext = React.createContext({} as GlobalTheme);

export { ThemeContext };
