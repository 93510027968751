import React from 'react';
import { Flex, Box } from 'rebass/styled-components';

import { layout } from '../../constants/constants';
import ShIcon from '../../shared/components/sh-icon/sh-icon';
import { IconPack } from '../../shared/models/app-data-model';
import SearchClearFilters from '../search-clear-filters/search-clear-filters';
import './search-mobile-header.scss';

export type SearchMobileHeadersProps = {
  showMobileFilters: boolean;
  showClearFilters: boolean;
  setShowMobileFilters: (showMobileFilters: boolean) => void;
  clearFilter: () => void;
  iconPack: IconPack[];
};

const SearchMobileHeader: React.FC<SearchMobileHeadersProps> = ({
  showMobileFilters,
  showClearFilters,
  setShowMobileFilters,
  clearFilter,
  iconPack
}: SearchMobileHeadersProps) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      flexDirection="row"
      className={'search-mobile-header fixed'}
      width={1}
      paddingX={layout.searchPaddingX}
    >
      <Box
        data-testid="mobile-search-header"
        className="mobile-filter-element"
        py={2}
        onClick={() => setShowMobileFilters(!showMobileFilters)}
      >
        {showMobileFilters ? (
          <>
            <ShIcon iconPack={iconPack} iconName="caret_up" className="icon mobile-filter-caret-up" />
            <span className="body-small filter-text">Back to results</span>
          </>
        ) : (
          <>
            <ShIcon iconPack={iconPack} iconName="caret_down" className="icon mobile-filter-caret-down" />
            <span className="body-small filter-text">Refine your search</span>
          </>
        )}
      </Box>
      {showClearFilters && <SearchClearFilters iconPack={iconPack} clearFilter={clearFilter} />}
    </Flex>
  );
};

export default SearchMobileHeader;
