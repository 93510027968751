import { useEffect, useState } from 'react';

export default function <T>(array: T[]): T[] {
  const [randomResults, setRandomResults] = useState([] as T[]);
  useEffect(() => {
    setRandomResults(
      array
        .map((el) => ({ el, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ el }) => el)
    );
  }, [array]);
  return randomResults;
}
