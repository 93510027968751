import { useMemo } from 'react';

export const preserveAllQueryParams = [
    "query",
    "tab",
    "page",
    "per_page",
    "origin",
    "distance",
    "filter",
    "orFilter",
    "notFilter"
];

const useFilteredQueryParams = (
    searchParams: URLSearchParams,
    keysToRetain: string[],
    keysToModify: Record<string, string>
) => {
    return useMemo(() => {
        const params = new URLSearchParams();

        keysToRetain.forEach((key) => {
            const values = searchParams.getAll(key);
            if (values.length) {
                values.forEach(value => {
                    if (key in keysToModify) {
                        params.append(key, keysToModify[key]);
                    } else {
                        params.append(key, value);
                    }
                });
            } 
        });

        // handle modified not present in existing params
        for (const key in keysToModify) {
            if (!params.has(key)) {
                params.set(key, keysToModify[key]);
            }
        }
        return params.toString();
    }, [searchParams, keysToRetain, keysToModify]);
};

export default useFilteredQueryParams;