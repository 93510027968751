import { useEffect, useState } from 'react';

export type WindowSize = { width: number; height: number };

function useWindowSize(): WindowSize | null {
  const getSize = (): WindowSize | null => {
    // Adding the check to avoid errors during html-build time.
    if (typeof window !== 'undefined' && window?.innerHeight && window?.innerWidth) {
      return {
        width: window.innerWidth,
        height: window.innerHeight
      };
    }
    return null;
  };

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    function handleResize(): void {
      setWindowSize(getSize());
    }

    // initial call of handleResize
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export default useWindowSize;
