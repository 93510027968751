import { useLayoutEffect, useState, useCallback, useContext } from 'react';

import { UiStateContext } from '../../contexts/ui-state/UiStateContext';
import KendraApiService, { Provider, Location, Page } from '../../services/kendra-api/kendra-api-service';

// eslint-disable-next-line
export type KendraApiResponse = {
  query_result: Array<Provider | Location | Page>;
  query_suggestions: Array<string>;
};

export type UseKendraApiProps = {
  providerSpecialtyIds?: string;
  locationSpecialtyIds?: string;
  shEmployedOnly?: boolean;
  enableQuery?: boolean;
};

export type ApiServiceStatus = {
  response: KendraApiResponse;
  isRunning: boolean;
  hasError: boolean;
  error?: any;
};

// TODO: refactor to not use global context
const useKendraApi = (props?: UseKendraApiProps): [ApiServiceStatus, () => Promise<void>] => {
  const [isRunning, setIsRunning] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(undefined as any);
  const [response, setResponse] = useState({} as KendraApiResponse);
  const [apiStatus, setApiStatus] = useState({ isRunning: false, response: response } as ApiServiceStatus);
  const uiStateContext = useContext(UiStateContext);

  useLayoutEffect(() => {
    let shouldCallKendra = !props ? true : props?.enableQuery; // use enableQuery if it's provided, else always true to support legacy behavior

    if (uiStateContext?.providerFilters?.zipcode) {
      shouldCallKendra = uiStateContext.providerFilters.zipcode?.value.length === 5 && uiStateContext.providerFilters.zipcode.valid;
    }

    if (uiStateContext?.locationFilters?.zipcode) {
      shouldCallKendra = uiStateContext.locationFilters.zipcode?.value.length === 5 && uiStateContext.locationFilters.zipcode.valid;
    }

    if (uiStateContext.searchQuery?.length > 2 && shouldCallKendra) {
      apiCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uiStateContext.providerFilters, uiStateContext.searchQuery, uiStateContext.activeSearchTab, uiStateContext.locationFilters]);

  useLayoutEffect(() => {
    setApiStatus({ isRunning: isRunning, hasError: hasError, response: response, error: error });
  }, [isRunning, hasError, response, error]);

  const apiCall = useCallback(async () => {
    setIsRunning(true);
    setHasError(false);
    try {
      const content = await KendraApiService.getSearchResult(
        uiStateContext.searchQuery,
        uiStateContext.browserLocation,
        uiStateContext.activeSearchTab,
        uiStateContext.providerFilters,
        uiStateContext.locationFilters,
        false,
        props?.providerSpecialtyIds,
        props?.locationSpecialtyIds,
        props?.shEmployedOnly
      );
      setHasError(false);
      setIsRunning(false);
      setResponse(content);
    } catch (err) {
      setResponse({} as KendraApiResponse);
      setError(err);
      setHasError(true);
      setIsRunning(false);
    }
  }, [
    uiStateContext.searchQuery,
    uiStateContext.browserLocation,
    uiStateContext.activeSearchTab,
    uiStateContext.providerFilters,
    uiStateContext.locationFilters,
    props
  ]);

  return [apiStatus, apiCall];
};

export default useKendraApi;
