import React, { useContext, useEffect, useState } from 'react';
import { Box, Flex } from 'rebass/styled-components';

import { UiStateContext } from '../../contexts/ui-state/UiStateContext';
import { IconPack } from '../../models/app-data-model';
import { Provider } from '../../services/kendra-api/kendra-api-service';
import CardImage from '../card-image/card-image';
import ShIcon from '../sh-icon/sh-icon';
import './provider-card-desktop.scss';

export type ProviderCardProps = {
  provider: Provider;
  iconPack: IconPack[];
  elementId?: string;
};

const ProviderCardDesktop: React.FC<ProviderCardProps> = ({ provider, iconPack, elementId }: ProviderCardProps) => {
  const uiStateContext = useContext(UiStateContext);
  const [cardWidth, setCardWidth] = useState<string | string[]>('696px');

  useEffect(() => {
    setCardWidth(
      uiStateContext.searchOpen || uiStateContext.embeddedSearchShown ? ['560px', '560px', '560px', '560px', '696px', '696px'] : '696px'
    );
  }, [uiStateContext.embeddedSearchShown, uiStateContext.searchOpen]);

  return (
    <a
      href={provider._source_uri}
      className="no-decoration provider-card-desktop-link results-card"
      id={elementId}
      target="_blank"
      rel="noopener noreferrer"
      data-kendra-token={provider.feedback_token}
    >
      <Flex
        className="provider-card-desktop"
        justifyContent="flex-start"
        alignItems="center"
        py={3}
        pr={3}
        pl={4}
        marginY={3}
        width={cardWidth}
      >
        <div className={provider?.is_spectrum_employed === 'true' ? 'circular-border' : ''}>
          <CardImage url={provider?.provider_pic} alt={provider.provider_name} className={'profile-pic'} type={'provider'} />
        </div>

        <Flex className="info-container" flexDirection="column" width={1} height="100%" ml="32px" pr="8px">
          <Flex justifyContent="space-between" alignItems="left" flexDirection="row" height="28px">
            <div>
              <span className="h6">
                {provider.provider_name}, {provider.provider_degree}{' '}
              </span>
            </div>
            <div>
              {provider.provider_rating && provider.provider_rating !== 'null' && (
                <Flex alignItems="center" justifyContent="space-between" flexDirection="row">
                  <Box paddingRight={1}>
                    <ShIcon iconPack={iconPack} className="star-icon" iconName="star" />
                  </Box>
                  <span className="overline rating secondary-text">{provider.provider_rating}</span>
                  {provider.provider_reviews && provider.provider_reviews !== 'null' && (
                    <span className="reviews">({provider.provider_reviews})</span>
                  )}
                </Flex>
              )}
            </div>
          </Flex>
          <Flex justifyContent="space-between" alignItems="center" my={0} flexDirection="row" height={'24px'} mt="12px">
            <div className="secondary-text">
              {provider.provider_specialty && provider.provider_specialty !== 'null' && (
                <span className="body-small">{provider.provider_specialty}</span>
              )}
            </div>
            <div>
              <Flex alignItems="center">
                <Box paddingRight={2}>
                  {provider.new_patients === 'yes' ? (
                    <ShIcon iconPack={iconPack} className="check-icon" iconName="check" />
                  ) : (
                    <ShIcon iconPack={iconPack} className="close-icon" iconName="bold_close" />
                  )}
                </Box>
                <span className="body-small secondary-text">
                  {provider.new_patients === 'yes' ? `Accepting new patients` : `Not accepting new patients`}
                </span>
              </Flex>
            </div>
          </Flex>

          <Flex alignItems="center" justifyContent="space-between" mt="8px">
            {provider.street_address && provider.street_address !== 'null' && (
              <>
                <Flex alignItems="center">
                  <ShIcon iconPack={iconPack} iconName="map_pin" className="map-pin" />
                  <span className="body-small secondary-text address">
                    {provider.street_address + (provider.city_state_zip !== 'null' ? `, ${provider.city_state_zip}` : '')}
                  </span>
                </Flex>

                {provider.distance && provider.distance !== 'null' && (
                  <span className="body-small ellipses-overflow distance">
                    <span>
                      <strong>{provider.distance}</strong> miles from
                    </span>
                    <span className="user-location">
                      {uiStateContext?.zipLocation?.zip?.value?.length === 5
                        ? uiStateContext?.zipLocation?.zip.value
                        : uiStateContext?.browserLocation?.latitude === 0
                        ? 'Grand Rapids'
                        : 'Your Location'}
                    </span>
                  </span>
                )}
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    </a>
  );
};

export default ProviderCardDesktop;
