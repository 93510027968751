import { useState, useEffect, useContext } from 'react';

import { UiStateContext } from '../../contexts/ui-state/UiStateContext';
import { FormControl, SelectInput } from '../../models/app-data-model';
import { Provider } from '../../services/kendra-api/kendra-api-service';
import { useZipDistanceProvider } from '../zip-distance/useZipDistance';

export type SearchProviderFilters = {
  insurance: SelectInput;
  zipcode: FormControl | undefined;
  gender: SelectInput;
  language: SelectInput;
  acceptingNewPatients: 'yes' | undefined;
  onlineScheduling: boolean | undefined;
  specialty: SelectInput;
};

export type SearchProviderSort = {
  sortBy: SelectInput | undefined;
};

const useSortProviderResults = (providers: Array<Provider>): Array<Provider> => {
  const [sortedResults, setSortedResults] = useState([] as Provider[]);
  const uiStateContext = useContext(UiStateContext);
  const zipDistance = useZipDistanceProvider(providers);

  useEffect(() => {
    let sortedProviders = [...providers];

    const sortByProximity = (): void => {
      sortedProviders = sortedProviders
        .filter((x) => x.street_address && x.street_address !== 'null')
        .sort((a, b) => {
          // equal items sort equally
          if (+a.distance === +b.distance) {
            return 0;
          }
          return +a.distance < +b.distance ? -1 : 1;
        });
    };

    if (uiStateContext?.zipLocation?.zip?.value?.length === 5 && uiStateContext?.zipLocation?.zip?.valid) {
      sortedProviders = zipDistance;
      sortByProximity();
    }

    // We need to do sorting on the client side because the total nr of results doesn't change on sorting, hence no need for an api call
    if (uiStateContext.providerSort?.sortBy && uiStateContext.providerSort.sortBy.value !== '') {
      if (uiStateContext.providerSort.sortBy.value === 'Best Reviews') {
        sortedProviders = sortedProviders
          .filter((x) => x.provider_rating !== 'null')
          .sort((a, b) => {
            // equal items sort equally
            if (a.provider_rating === b.provider_rating) {
              return 0;
            }

            return a.provider_rating < b.provider_rating ? 1 : -1;
          });
      } else if (uiStateContext.providerSort.sortBy.value === 'Alphabetically A-Z') {
        sortedProviders = sortedProviders.sort((a, b) => (a.provider_lastname.toLowerCase() < b.provider_lastname.toLowerCase() ? -1 : 1));
      } else if (uiStateContext.providerSort.sortBy.value === 'Alphabetically Z-A') {
        sortedProviders = sortedProviders.sort((a, b) => (a.provider_lastname.toLowerCase() > b.provider_lastname.toLowerCase() ? -1 : 1));
      } else if (uiStateContext.providerSort.sortBy?.value === 'Distance') {
        sortByProximity();
      }
    }

    setSortedResults([...sortedProviders]);
  }, [providers, uiStateContext, zipDistance]);

  return sortedResults;
};

export default useSortProviderResults;
