import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { Box, Flex } from 'rebass/styled-components';

import { layout } from '../../constants/constants';
import ErrorBanner from '../../shared/components/error-banner/error-banner';
import LoadingSpinner from '../../shared/components/loading-spinner/loading-spinner';
import SkeletonState from '../../shared/components/skeleton-state/skeleton-state';
import { UiStateContext } from '../../shared/contexts/ui-state/UiStateContext';
import useContentStack from '../../shared/hooks/contentstack-api/useContentStack';
import useKendraApi from '../../shared/hooks/kendra-api/useKendraApi';
import { DeviceType } from '../../shared/hooks/use-breakpoint/device-type';
import useBreakpoint from '../../shared/hooks/use-breakpoint/useBreakpoint';
import useFeatureToggle from '../../shared/hooks/use-feature-toggle/useFeatureToggle';
import { FeatureToggle, GlobalNavContent, GlobalSearchNoResultsContent, IconPack } from '../../shared/models/app-data-model';
import { Page, Provider } from '../../shared/services/kendra-api/kendra-api-service';
import { Location } from '../../shared/services/kendra-api/kendra-api-service';
import AllResults from '../all-results/all-results';
import Locations from '../locations/locations';
import NoResults from '../no-results/no-results';
import Pages from '../pages/pages';
import Providers from '../providers/providers';
import { NavTabs } from '../search-nav/search-nav';
import './search-result.scss';

export type SearchResultsProps = {
  featureToggles: FeatureToggle[];
  iconPack: IconPack[];
  globalNavContent: GlobalNavContent;
  noResultEntryUid: string;
};

const SearchResult: React.FC<SearchResultsProps> = ({ featureToggles, iconPack, globalNavContent, noResultEntryUid }: SearchResultsProps) => {
  const uiStateContext = useContext(UiStateContext);
  const [kendraApi, retry] = useKendraApi();
  const [searchResultData, setSearchResultData] = useState(new Array<Provider | Location | Page>());
  const [searchSuggestions, setSearchSuggestions] = useState(new Array<string>());
  const deviceType = useBreakpoint();
  const useSearchSkeletonStateLoader = useFeatureToggle('search-skeleton-state-loader', featureToggles);

  const noResultsFoundContent = useContentStack([
    {
      contentType: 'global_search_no_results_spectrumhealth_org',
      // entryUid: 'bltf982f7c25b9da053',
      entryUid: noResultEntryUid,
      referencedFields: ['modular_blocks.content_card_grid.cards.content_card.reference']
    }
  ])?.responses[0]?.content as GlobalSearchNoResultsContent;

  useLayoutEffect(() => {
    /* istanbul ignore else */
    if (kendraApi?.response && kendraApi.response?.query_result) {
      setSearchResultData(kendraApi.response.query_result);
    }
    /* istanbul ignore else */
    if (kendraApi?.response && kendraApi.response?.query_suggestions) {
      setSearchSuggestions(kendraApi.response.query_suggestions);
    }
  }, [kendraApi]);

  useEffect(() => {
    window?.scrollTo(0, 0);
  }, [uiStateContext.activeSearchTab]);

  const searchResultsView = (): any => {
    switch (uiStateContext.activeSearchTab) {
      case NavTabs.doctors:
        return (
          <Providers
            searchSuggestions={searchSuggestions}
            iconPack={iconPack}
            providers={searchResultData as Provider[]}
            featureToggles={featureToggles}
            noResultsFoundContent={noResultsFoundContent}
          />
        );
      case NavTabs.locations:
        return (
          <Locations
            searchSuggestions={searchSuggestions}
            iconPack={iconPack}
            locations={searchResultData as Location[]}
            featureToggles={featureToggles}
            noResultsFoundContent={noResultsFoundContent}
          />
        );
      case NavTabs.pages:
        return (
          <Pages
            searchSuggestions={searchSuggestions}
            pages={searchResultData as Page[]}
            featureToggles={featureToggles}
            iconPack={iconPack}
            noResultsFoundContent={noResultsFoundContent}
          />
        );
      default:
        return (
          <AllResults
            searchSuggestions={searchSuggestions}
            iconPack={iconPack}
            results={searchResultData}
            featureToggles={featureToggles}
            noResultsFoundContent={noResultsFoundContent}
          />
        );
    }
  };

  const errorState = (
    <Box width={layout.pageWidth} pr={layout.searchPaddingX} margin="0">
      <ErrorBanner
        subMessage={
          'An error occurred while trying to connect you to your search results. You may enter a new search query, or click "Try Again" to retry your search.'
        }
        onReload={retry}
      />
    </Box>
  );

  return (
    <Flex
      className="search-result"
      justifyContent="space-between"
      width={layout.pageWidth}
      mx="auto"
      px={deviceType === DeviceType.Mobile ? 2 : layout.searchPaddingX}
    >
      {uiStateContext.searchQuery?.length === 0 ? (
        <NoResults
          globalNavContent={globalNavContent}
          content={noResultsFoundContent}
          searchSuggestions={searchSuggestions}
          featureToggles={featureToggles}
          iconPack={iconPack}
        />
      ) : kendraApi.hasError ? (
        errorState
      ) : kendraApi.isRunning && uiStateContext.searchQuery?.length > 2 ? (
        useSearchSkeletonStateLoader ? (
          <SkeletonState
            displayFilters={uiStateContext.activeSearchTab === NavTabs.doctors || uiStateContext.activeSearchTab === NavTabs.locations}
          />
        ) : (
          <LoadingSpinner />
        )
      ) : (
        searchResultsView()
      )}
    </Flex>
  );
};

export default SearchResult;
