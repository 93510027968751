import { globalHistory } from '@gatsbyjs/reach-router';
import '../../design-system/app.scss';
import './styles/typography.scss';
import Search from '../../design-system/search/search';
import { NavTabs } from '../../design-system/search/search-nav/search-nav';
import { AppDataContext } from '../../design-system/shared/contexts/app-data/AppDataContext';
import { mediaStyles } from '../../design-system/shared/contexts/media/MediaContext';
import ThemeContextProvider from '../../design-system/shared/contexts/theme/ThemeContextProvider';
import { UiStateContext } from '../../design-system/shared/contexts/ui-state/UiStateContext';
import useContentStack from '../../design-system/shared/hooks/contentstack-api/useContentStack';
import { AppDataModel, FeatureToggle, GlobalQueryResponse, GlobalSettings, IconPack } from '../../design-system/shared/models/app-data-model';
import React, { ReactNode, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Flex } from 'rebass/styled-components';

export type AppProps = {
  children: ReactNode;
  data: GlobalQueryResponse;
};

/**
 * This component controls our entire App Layout for SH.org
 *
 * @param {AppProps} { children }
 * @returns
 */
const App: React.FC<AppProps> = ({ children, data }: AppProps) => {
  const context = useContext(AppDataContext);
  const uiStateContext = useContext(UiStateContext);
  const latestFeatureToggles: FeatureToggle[] = (
    useContentStack([{ contentType: 'spectrumhealth_org_global_settings', entryUid: 'blt6851e553e5977b47' }])?.responses?.find(
      (r) => r.content
    )?.content as GlobalSettings
  )?.feature_toggles;
  const staticIcons = data.shSpectrumhealthOrgGlobalSettings.site_theme.find((x) => x.icon_pack)?.icon_pack as IconPack[];

  /**
   * This useEffect updates our featureToggles if they have changed during runtime
   * but haven't been updated in our static builds yet
   */
  useEffect(() => {
    if (context.setAppData && latestFeatureToggles !== context.appData?.globalSettings?.feature_toggles) {
      const globalSettings: GlobalSettings = {
        title: context.appData?.globalSettings?.title,
        site_theme: context.appData?.globalSettings?.site_theme,
        copyright_subtext: context.appData?.globalSettings?.copyright_subtext,
        feature_toggles: latestFeatureToggles,
        development_url: context.appData?.globalSettings?.development_url,
        test_url: context.appData?.globalSettings?.test_url,
        live_preview_url: context.appData?.globalSettings?.live_preview_url,
        prod_url: context.appData?.globalSettings?.prod_url,
        twitter_creator: context.appData?.globalSettings?.twitter_creator,
        twitter_site: context.appData?.globalSettings?.twitter_site
      };

      const appData: AppDataModel = {
        primaryHeaderContent: context.appData?.primaryHeaderContent,
        megaMenuContent: context.appData?.megaMenuContent,
        globalNavContent: context.appData?.globalNavContent,
        globalAlertsContent: context.appData?.globalAlertsContent,
        globalFooterContent: context.appData?.globalFooterContent,
        globalSettings: globalSettings,
        globalToolbarContent: context.appData?.globalToolbarContent,
        regionalLinksModal: context.appData?.regionalLinksModal
      };
      context.setAppData(appData);
    }
    // we can't depend on context or we endless loop!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestFeatureToggles]);

  /**
   * This effect resets the menu state (to close it) when navigation to a new route is detected
   */
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      // istanbul ignore else
      if (action) {
        uiStateContext.setAuxNavOpen?.(false);
        uiStateContext.setMenuOpen?.(false);
        uiStateContext.setSubsiteMenuOpen?.(false);
        uiStateContext.setBackButtonNavItem?.(false);
        uiStateContext.setSearchOpen?.(false);
        uiStateContext.setSearchQuery?.('');
        uiStateContext.setActiveSearchTab?.(NavTabs.all);
        uiStateContext.setAllowHosts?.([]);
      }
    });
  });

  useEffect(() => {
    // update AppDataContext with embedded script data
    if (data.shGlobalEmbedScriptsSpectrumhealthOrg) {
      context.setEmbeddedScripts(data.shGlobalEmbedScriptsSpectrumhealthOrg.modular_blocks);
    }
  }, [data.shGlobalEmbedScriptsSpectrumhealthOrg, context]);

  const search = (
    <Search
      iconPack={staticIcons}
      featureToggles={data.shSpectrumhealthOrgGlobalSettings.feature_toggles}
      globalNavContent={data.shGlobalHeaderNav}
      noResultEntryUid="blt7994a7d481380f19"
    />
  );

  return (
    <ThemeContextProvider siteTheme={data.shSpectrumhealthOrgGlobalSettings.site_theme}>
      <Helmet>
        <style>{mediaStyles}</style>
      </Helmet>
      {uiStateContext.searchOpen && search}
      <div hidden={uiStateContext.searchOpen}>
        <Flex flexDirection="column">{children}</Flex>
      </div>
    </ThemeContextProvider>
  );
};

export default App;
