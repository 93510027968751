import React from 'react';
import Select from 'react-select';

import useBreakpoint from '../../hooks/use-breakpoint/useBreakpoint';
import { SelectInput } from '../../models/app-data-model';
import { customStyles } from './custom-styles';
import { CustomValueContainer } from './custom-value-container/custom-value-container';
import './select-input-container.scss';

export type SelectInputProps = {
  id?: string;
  placeholder: string;
  options?: Array<SelectInput>;
  onChangeFunction: (option: SelectInput) => void;
  selectedValue?: any;
  isDisabled?: boolean;
};

const SelectInputContainer: React.FC<SelectInputProps> = (props: SelectInputProps) => {
  const { options, placeholder, onChangeFunction, selectedValue, isDisabled } = props;
  const isValid = true;
  const deviceType = useBreakpoint();

  const styles = customStyles(deviceType, isValid);

  return (
    <div className={`select-input-container`}>
      <Select
        components={{ ValueContainer: CustomValueContainer }}
        options={options}
        styles={styles}
        placeholder={placeholder}
        isSearchable={false}
        isDisabled={isDisabled}
        onChange={onChangeFunction}
        value={selectedValue ?? ''}
        classNamePrefix="select-input"
      />
      {/* NOTE: isValid hardcoded to true this error will never be displayed and the error is not useful. [RS-1279]: Removing branch for unit test file coverage
      {!isValid && <p className="error-message">Error message</p>} */}
    </div>
  );
};

SelectInputContainer.defaultProps = {
  options: [{ value: 'value', label: 'label' }],
  placeholder: 'Select Label',
  isDisabled: false
};

export default SelectInputContainer;
